import {HISTORY} from '../types';

const INITIAL_STATE = [];

const historyReducer= (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case HISTORY :
            return  action.payload;
        default: return state
    }
};

export default historyReducer;