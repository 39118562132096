import React, { useEffect, useState } from 'react'
import { SearchOutlined,FileExcelOutlined,PlusOutlined,ArrowLeftOutlined, UserAddOutlined} from '@ant-design/icons';
import { Button, Space,Tooltip ,Typography,Divider } from 'antd';
import {Col,Row} from 'antd';
// import TopNavigate from '../components/TopNavigate';
import { getRequestAll,patchRequestById } from '../helpers/Api';
import { useNavigate, useParams } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { Excel } from "antd-table-saveas-excel";
import openNotificationWithIcon from '../helpers/Notification';
import MyDataTable from '../helpers/MyDataTable';
import HeaderTitle from '../components/HeaderTitle';


export const HogarListCanalizacion = () => {
   const params = useParams();
  const [dataTable,setDataTable]= useState([]);
  const navigate = useNavigate();
  const {usuario_id} = params;

    const[Datacolumns] = useState([
    {title:"N°",dataIndex:"numero",key:"numero"},
    {title:"Acciones",dataIndex:"acciones",key:"acciones"},
    {title:"Subregión",dataIndex:"subregion",key:"subregion"},
    {title:"Municipio",dataIndex:"ciudad",key:"ciudad"},
    {title:"Ficha",dataIndex:"ficha",key:"ficha"},
    {title:"Dirección",dataIndex:"direccion",key:"direccion"},
    {title:"Dormitorios",dataIndex:"dormitorios",key:"dormitorios"},
    {title:"Personas",dataIndex:"personas",key:"personas"},
    {title:"Fecha de visita",dataIndex:"fecha",key:"fecha"}])

   
    const handleClick = () => {
      const excel = new Excel();
      excel
        .addSheet("test")
        .addColumns(Datacolumns)
        .addDataSource(dataTable, {
          str2Percent: true
        })
        .saveAs("Excel.xlsx");
    };

    useEffect(()=>{
      let data = [];
        getRequestAll(`hogar/alluserById/?userId=${usuario_id}`).then(res=>{
            if(res['success']){
              let i =1;
                res['Hogar'].forEach(d => {
                    data.push({
                      key: i,
                      numero:i, 
                      acciones:<>
                      <Tooltip title='Ver integrantes del hogar' color="orange">
                       <Button className='btn-detalle'  icon={<SearchOutlined />} onClick={()=>navigate(`/panel/vivienda/integrantes/${d.hogar_id}`, { replace: true })}></Button>
                     </Tooltip>  
                      </>   ,
                      subregion:d.subregion,
                      ciudad:d.ciudad,
                      ficha:d.numero_ficha,
                      direccion:d.direccion,
                      dormitorios:d.dormitorios,
                      personas:d.personas,
                      fecha:d.fecha_crea
                          
                    })
                    i++;
                });

                setDataTable(data)
            }
        })
    },[])

  return (
   <>
     <HeaderTitle title={"Hogares registrados por el usuario"}/>
     <Row>
        <Col lg={24}>
       <Space style={{ marginBottom: 16 }}>
       {/* <Tooltip title='Crear nuevo hogar' color="orange">
          <Button  icon={<PlusOutlined />} onClick={()=>navigate('/panel/vivienda/registro', { replace: true })}></Button>
        </Tooltip>   */}
          {/* <Button  icon={<FilePdfOutlined  />} danger></Button> */}
          <Tooltip title='Exportar Hogar' color="orange">
             <Button  icon={<FileExcelOutlined  />} className="btn-excel" onClick={handleClick}></Button>
          </Tooltip>
      </Space>
         <MyDataTable Datacolumns={Datacolumns} dataTable={dataTable} />
        </Col>
    </Row>
    {/**
    
    <div className='content-regresar'>
                <Button  type="link" className='btn-back'  icon={<ArrowLeftOutlined   />} onClick={(e)=>navigate('/panel/canalizacion', { replace: true })}>Regresar</Button> 
      </div>
     */}
   </>
  )
}
