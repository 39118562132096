import React, {useState} from 'react';
import { useQuery } from '@tanstack/react-query'
import { Skeleton, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { geTipoVivienda } from '../../../Api/vivienda';
const { Panel } = Collapse;

function TipoVivienda({region, cargo, funciones_id}) {
    const [info, setInfo] = useState([]);
    const [totalInfo, seTotalInfo] = useState([]);

    const {data, status} = useQuery({
        queryKey: [`geTipoVivienda`, region], 
        queryFn: geTipoVivienda,
        onSuccess: (info => {
            let myArray = [];
            let totals = {};
            info.data.tipo.forEach(e => {
                totals[e.vivienda_tipo_descripcion] =  totals[e.vivienda_tipo_descripcion] ? (totals[e.vivienda_tipo_descripcion] + e.cantidad) :  e.cantidad;
                let filt = myArray.find((x) => e.ciudad == x.ciudad);
                if(filt){
                    filt.data.push(e);
                }else{
                    myArray.push({
                    ciudad: e.ciudad,
                    data: [e]
                    });
                }
            });
            setInfo(myArray);
            seTotalInfo(totals);
        })
    });
    
    if(status === "loading") return <Skeleton active />
    if(status === "error")  return <p>Ups...</p>

    return (
        <div className='shadow-md p-4 rounded-md'>
           <h3  className='text-xl font-bold mb-3 uppercase'>Tipo de vivienda</h3>
           { cargo === 8  || cargo === 3  || funciones_id === 2 ?
            <ul>
                <li className='text-[16px]'>Casas: <b className='text-orange ml-3'>{ totalInfo.Casa }</b></li>
                <li className='text-[16px]'>Apartamentos: <b className='text-orange ml-3'>{ totalInfo.Apartamento }</b></li>
                <li className='text-[16px]'>Albergues: <b className='text-orange ml-3'>{ totalInfo['Albergue'] }</b></li>
                <li className='text-[16px]'>Carpas: <b className='text-orange ml-3'>{ totalInfo.Carpa }</b></li>
                <li className='text-[16px]'>Hogar de paso:  <b className='text-orange ml-3'>{ totalInfo['Hogar de paso'] }</b></li>
                <li className='text-[16px]'>Otro/cual:  <b className='text-orange ml-3'>{ totalInfo['Otro/cual'] }</b></li>
                <li className='text-[16px]'>Paga diario: <b className='text-orange ml-3'>{ totalInfo['Paga diario'] }</b></li>
                <li className='text-[16px]'>Pieza: <b className='text-orange ml-3'>{ totalInfo['Pieza'] }</b></li>
                <li className='text-[16px]'>Puente: <b className='text-orange ml-3'>{ totalInfo['Puente'] }</b></li>
                <li className='text-[16px]'>Refugio: <b className='text-orange ml-3'>{ totalInfo['Refugio'] }</b></li>
                <li className='text-[16px]'>Tienda: <b className='text-orange ml-3'>{ totalInfo['Tienda'] }</b></li>
                <li className='text-[16px]'>Vagón: <b className='text-orange ml-3'>{ totalInfo['Vagón'] }</b></li>
            </ul>
            : 
            <Collapse
                bordered={false}
                ghost
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            > 
            {info.map( (i, index) => {
                return <Panel header={i.ciudad} key={index} className="site-collapse-custom-panel">
                <ul>
                    {
                    i.data.map((e, key) => (
                        <li key={key}>{e.vivienda_tipo_descripcion}: {e.cantidad}</li>
                    ))
                    }
                </ul>
                
                </Panel>
            } )}
            </Collapse>
        }
        </div>
    )
}

export default TipoVivienda