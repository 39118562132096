import React from 'react'
import { useQuery } from '@tanstack/react-query';
import { usuariosCaracterizacionByMunicipio, coordinadorBySubregion} from '../../Api/seguimiento';
import { Skeleton, Table  } from 'antd';
import { connect } from 'react-redux';
import TableUser from '../../components/TableUser';

function TableDrawe({region, user}) {

  const { data, status } = useQuery({ 
    queryKey: [`usuariosCaracterizacionByMunicipio`, region], 
    queryFn: user.user_type_id === 8 ||  user.user_type_id === 3 ? coordinadorBySubregion : usuariosCaracterizacionByMunicipio 
  } );

  if(status === "loading") return (<><Skeleton active /> <Skeleton active /> <Skeleton active /></>)
  if(status === "error")  return <p>Ups... tenemos un error</p>

  return (
    <div>
      <TableUser user_type_id={user.user_type_id} list={data.data.usuarios} funcion={user.funciones_id} />
    </div>
  )
}

const mapStateToProps = state => state;

export default  connect(mapStateToProps, {})(TableDrawe)