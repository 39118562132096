import React, { useEffect, useState } from 'react';
import { DatePicker, Select } from 'antd';
import { ArrowLeftOutlined ,SendOutlined} from '@ant-design/icons';
import {Col,Row,Typography } from 'antd';
import { Divider,Form,Input,Button,Radio} from 'antd';
import { getRequestAll,postRequest,getRequestById } from '../helpers/Api';
import { useNavigate, useParams } from 'react-router-dom';
import openNotificationWithIcon from '../helpers/Notification';
import HeaderTitle from '../components/HeaderTitle';
import { getintegranteById } from '../Api/integrantes';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { getLastRouter } from '../Api/history';

export const IdentificacionIntegrantes = ({title,isUpdate}) => {
    
const {Item} = Form;
const {Title} = Typography;
const { Option } = Select;
const [form] = Form.useForm();

const [cursovida,setCursoVida] = useState([])
const [vinculo,setVinculo] = useState([])
const [sexo,setSexo] = useState([])
const [orientacion,setOrientacion] = useState([])
const [genero,setGenero] = useState([])
const [ocupacion,setOcupacion] = useState([])
const [etnia,setGrupoEtnico] = useState([])
const [estadocivil,setEstadoCivil] = useState([])
const [optionDocumento,setDocumento] = useState([])
const [afiliacion,setAfiliacion] = useState([])
const [grupoPoblacion,setGrupoPoblacion] = useState([])
const [eps,setEps] = useState([])
const [regimen,setRegimen] = useState([])
const [gestante,setGestante] = useState([])
const [is_regimen,setisRegimen] = useState(false)
const [is_nacionalidad,setIsNacionalidad] = useState(false)
const [is_abandono_e,setIsAbandonoE] = useState(false)
const [is_mujer,setIsMujer] = useState(false)
const [is_gestante,setIsGestante] = useState(false)
const [is_riesgopsico,setIsRiesgoPsico] = useState(false)
const [is_familiaIndigena,setIsFamiliaIndigena] = useState(false)
const [is_infecciosas,setIsRiesgoInfecciosas] = useState(false)
const [is_otro_grupo,setIsOtroGrupo] = useState(false)
const [is_seguridad_a,setIsSeguridadA] = useState(false)
const [is_salud_o,setIsSaludOral] = useState(false)
const [is_conflicto,setIsConflicto] = useState(false)
const [is_discapacidad,setIsDiscapacidad] = useState(false)
const [integrante,setIntegrante] = useState({})
const [edad,setEdad] = useState();
const [rangoVida,setRangoVida] = useState();

const [discapacidad,setDiscapacidad] = useState([])
const [conflicto,setConflicto] = useState([])
const [escolaridad,setEscolariad] = useState([])
const [abandonoEscolar,setAbandonoEscolar] = useState([])
const [psicosocial,setPsicosocial] = useState([])
const [saludInfantil,setSaludInfantil] = useState([])
const [atencionMujer,setAtencionMujer] = useState([])
const [adulto,setAdulto] = useState([])
const [infeccionas,setInfeccionas] = useState([])
const [saludOral,setSaludOral] = useState([])
const [seguridadAlimentaria,setSeguridadAlimentaria] = useState([])
const [familiaIndigena,SetFamiliaIndigena] = useState([])
const [loading,setLoading] = useState(false)
const [is_document,setIs_document] = useState(false)
const [is_otro_document,setIsOtroDocument] = useState(false)
const [is_update,setUpdate] = useState(true)
const [showEsalud,setShowSalud] = useState(0)
const [hogar_id,setShowHogar] = useState()
const [intialValue] = useState()
const navigate = useNavigate();
const params = useParams();


const { data, status } = useQuery({ 
    queryKey: [`itegrantes`, params.id], 
    queryFn: getintegranteById, 
    onSuccess: (info => {
      
      const integranteArray =info.data.integrante;
        if(isUpdate === true) {
            const integrante = integranteArray[integranteArray.length -1];
            const listRiegos = integranteArray.filter( i => {
     
                if(i.descripcion_riesgo != 'Ninguno') return {
                        descripcion_riesgo: i.descripcion_riesgo, 
                        riesgo_prioritario_id: i.riesgo_prioritario_id 
                    }
                }
            )
            
     
            form.setFieldsValue(
            {
                nombre_completo:integrante.nombre_completo,
                tipo_documento_id:integrante.tipo_documento_id,
                documento:integrante.documento,
                fecha_nacimiento: moment(integrante.fecha_nacimiento),
                edad:integrante.edad,
                curso_vida_id:integrante.curso_vida_id,
                vinculo_jefe_id:integrante.vinculo_jefe_id,
                recibio_visita:integrante.recibio_visita,
                grupo_etnico_id:integrante.grupo_etnico_id,
                habla_castellano:integrante.habla_castellano,
                sexo_id:integrante.sexo_id,
                genero_id:integrante.genero_id,
                orientacion_id:integrante.orientacion_id,
                ocupacion_id:integrante.ocupacion_id,
                estado_civil_id:integrante.estado_civil_id,
                telefono:integrante.telefono,
                email:integrante.email,
                vinculacion:integrante.is_sgsss,
                regimen:integrante.regimen_id,
                eapb:integrante.eps_id,
                afiliacion_tipo:integrante.integrante_id,
                is_riesgopsico:integrante.is_riesgo_psico,
                is_infecciosas:integrante.is_riesgo_infecciosas,
                is_seguridad_a:integrante.is_salud_alimentaria,
                is_salud_o:integrante.is_salud_oral,
                is_discapacidad:integrante.is_discapacidad,
                is_discapacidad_intervencion:integrante.is_discapacidad_intervencion,
                is_victimizante:integrante.is_victimizante ? parseInt(integrante.is_victimizante) : 0 ,
                vinculo_id: integrante.vinculo_jefe_id,
                is_indigena: integrante.familia_indigena_id ? integrante.familia_indigena_id : 0,
                grupo_poblacional_id: integrante.grupo_poblacional_id
            }
            )
            if( listRiegos.length >= 1 ){
                setRangoVida(5)
                setAdulto(listRiegos)
            }
        }
    })
})


useEffect(()=>{
    
    if(isUpdate)   setLoading(true)
    getRequestAll('generics/cursodevida').then(res=>{
        if(res['success'])
        setCursoVida(res['data']['cursovida'])
    })
    getRequestAll('generics/vinculo').then(res=>{
        if(res['success'])
        setVinculo(res['data']['vinculo'])
    })
    getRequestAll('generics/sexo').then(res=>{
        if(res['success'])
        setSexo(res['data']['sexo'])
    })
    getRequestAll('generics/orientacion').then(res=>{
        if(res['success'])
        setOrientacion(res['data']['orientacion'])
    })
    getRequestAll('generics/genero').then(res=>{
        if(res['success'])
        setGenero(res['data']['genero'])
    })
    getRequestAll('generics/ocupacion').then(res=>{
        if(res['success'])
        setOcupacion(res['data']['ocupacion'])
    })
    getRequestAll('generics/estadocivil').then(res=>{
        if(res['success'])
        setEstadoCivil(res['data']['estadocivil'])
    })
    getRequestAll('generics/etnia').then(res=>{
        if(res['success'])
        setGrupoEtnico(res['data']['etnia'])
    })
    getRequestAll('generics/documentotipo').then(res=>{
        const {documento_tipo} = res['data'];
         if(res['success']) setDocumento(documento_tipo);
    });
    getRequestAll('generics/afiliacion').then(res=>{
        if(res['success'])
        setAfiliacion(res['data']['afiliacion'])
    })
    getRequestAll('generics/eps').then(res=>{
        if(res['success'])
        setEps(res['data']['eps'])
    })
    getRequestAll('generics/regimen').then(res=>{
        if(res['success'])
        setRegimen(res['data']['regimen'])

    })
    getRequestAll('generics/grupopoblacion').then(res=>{
        if(res['success'])
        setGrupoPoblacion(res['data']['grupo'])

    })

    getRequestAll('generics/discapacidad').then(res=>{
        const {discapacidad} = res['data'];
         if(res['success']) setDiscapacidad(discapacidad);
    });
    getRequestAll('generics/familiaindigena').then(res=>{
        if(res['success'])
        SetFamiliaIndigena(res['data']['familia'])
    })
       
    getRequestById(1,"generics/riesgoprioritario").then(res=>{
        if(res['success'])
        setEscolariad(res['data']['riesgo'])
      })
    getRequestById(2,"generics/riesgoprioritario").then(res=>{
        if(res['success'])
        setAbandonoEscolar(res['data']['riesgo'])
      })
    getRequestById(3,"generics/riesgoprioritario").then(res=>{
        if(res['success'])
        setPsicosocial(res['data']['riesgo'])
      })
    getRequestById(4,"generics/riesgoprioritario").then(res=>{
        if(res['success'])
        setSaludInfantil(res['data']['riesgo'])
      })
    getRequestById(5,"generics/riesgoprioritario").then(res=>{
        if(res['success'])
        setAtencionMujer(res['data']['riesgo'])
      })
    getRequestById(6,"generics/riesgoprioritario").then(res=>{
        if(res['success'])
        setAdulto(res['data']['riesgo'])
      })
    getRequestById(7,"generics/riesgoprioritario").then(res=>{
        if(res['success'])
        setInfeccionas(res['data']['riesgo'])
      })
    getRequestById(8,"generics/riesgoprioritario").then(res=>{
        if(res['success'])
        setSaludOral(res['data']['riesgo'])
      })
    getRequestById(9,"generics/riesgoprioritario").then(res=>{
        if(res['success'])
        setSeguridadAlimentaria(res['data']['riesgo'])
      })
    getRequestById(10,"generics/riesgoprioritario").then(res=>{
        if(res['success'])
        setGestante(res['data']['riesgo'])
      })

    getRequestById(11,'generics/riesgoprioritario').then(res=>{
        if(res['success'])
        setConflicto(res['data']['riesgo'])
    })
    setUpdate(isUpdate)
},[])

useEffect(()=>{
    
    form.setFieldsValue(
        {
          edad:edad,
          curso_vida_id:rangoVida
        }
      )
      
},[edad])


const onFinish  = (p) => {
    if(p.is_discapacidad === 1) p.discapacidad = JSON.stringify(p.discapacidad)
    if(p.is_victimizante === 1) p.hechovictimizante = JSON.stringify(p.hechovictimizante)
    if(p.is_gestante === 1) p.gestante = JSON.stringify(p.gestante)
    if(p.is_riesgopsico === 1) p.psicosocial = JSON.stringify(p.psicosocial)
    if(rangoVida < 3) p.saludinfantil = JSON.stringify(p.saludinfantil)
    if(rangoVida === 5) p.adulto = JSON.stringify(p.adulto)
    if(p.is_salud_o === 1) p.saludoral = JSON.stringify(p.saludoral)
    if(p.is_seguridad_a === 1) p.segalimentaria = JSON.stringify(p.segalimentaria)
    if(p.is_infecciosas === 1) p.infecciosas = JSON.stringify(p.infecciosas)
    if(p.sexo_id === 1 )p.atencionmujer = JSON.stringify(p.atencionmujer)
    if(is_abandono_e === true )p.abandonoescolar = JSON.stringify(p.abandonoescolar)

    
    if(!isUpdate){
    const {id, uiid} = params;
   
    p.uiid = uiid;
    p.hogar_id = uiid;
    setLoading(true)
    setTimeout(() => {
        

        postRequest("hogar/integrante",p).then(res=>{
            if(res['success']){
                openNotificationWithIcon("success","Exito al crear el integrante!!","Se registró un nuevo integrante");
                form.resetFields();
                getRequestAll('generics/cursodevida').then(res=>{
                    if(res['success'])
                    setCursoVida(res['data']['cursovida'])
                })
            }else{

                openNotificationWithIcon("error","Error al crear el integrante!!","No se pudo pudo registrar un nuevo integrante")
            }
          }).catch(err=>{
            openNotificationWithIcon("error","Error al crear el integrante!!","No se pudo pudo registrar un nuevo integrante")
          })
          setLoading(false)
    }, 500);
    
    }else{
        //update code   
    }
};

const onFormLayoutChange = ({ disabled }) => {

};

const showSlud = (v) => {
  setShowSalud(v.target.value)
};
const disableDocument = (v) => {
    if(v === 2 || v === 4)
      setIs_document(true)
    else
     setIs_document(false)
     if(v===3 || v===10 || v === 11)
     setIsNacionalidad(true)
     else
     setIsNacionalidad(false)
     if(v===12)
     setIsOtroDocument(true)
     else setIsOtroDocument(false)
};

const calculateEdad = (year_integrante)=>{
    // let year = new Date(year_integrante.target.value).getFullYear();
    let year = new Date(year_integrante).getFullYear();
 

    const moonLanding = new Date();
    let year_actual = moonLanding.getFullYear();
    let edad = year_actual - year;
   
    setEdad(edad);
    if(edad <= 5)
    setRangoVida(1)
    if(edad>5 && edad<=11)
    setRangoVida(2)
    if(edad>11 && edad<=17)
    setRangoVida(3)
    if(edad>17 && edad<=28)
    setRangoVida(4)
    if(edad>28 && edad<=59)
    setRangoVida(5)
    if(edad>60)
    setRangoVida(6)
}


  return (
     <>


        <h2 className='text-xl font-bold  mb-4'>IDENTIFICACIÓN DE LOS INTEGRANTES DEL HOGAR</h2>
  
        <Form 
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues = {intialValue}
            disabled={isUpdate} 
            onValuesChange={onFormLayoutChange}
            className="form-create"
        >

        <br/>
            <div className='grid md:grid-cols-6 sm:grid-cols-1 gap-4'>
                
                    <Item 
                        label="Nombres y apellidos completos" 
                        name="nombre_completo" 
                        rules={[{ required: true, message: 'Por favor Ingrese el nombre completo' }]}>
                        <Input placeholder='Nombres y apellidos completos'/>
                    </Item>
                    <Item 
                        label="Tipo de documento" 
                        name="tipo_documento_id" 
                        rules={[{ required: true, message: 'Por favor seleccione el tipo de documento' }]}>
                        <Select 
                                placeholder="Seleccione..." onChange={(v)=>{disableDocument(v)}}>
                                {optionDocumento.length > 0 && optionDocumento.map((op,i)=>(<Option   key={`p${i}`} value={op.value}>{op.descripcion}</Option> ))}
                        </Select>
                    </Item>
               
                { is_otro_document === true &&
               
                <Item 
                    label="Otro Tipo de documento" 
                    name="tipo_documento_otro" 
                    rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Input placeholder='tipo_documento_otro'/>
                </Item>
                
                }

               
                <Item 
                    label="Numero de documento" 
                    name="documento" >
                    <Input disabled={is_document} placeholder="Numero de documento"/>
                </Item>

               { is_nacionalidad === true &&
                    <Item 
                        label="Nacionalidad" 
                        name="nacionalidad" 
                        rules={[{ required: true, message: 'Por favor seleccione el tipo de documento' }]}>
                        <Input placeholder='Nacionalidad'/>
                    </Item>
              }
               
                <Item 
                    label="Grupo poblacional" 
                    name="grupo_poblacional_id" 
                    rules={[{ required: true, message: 'Por favor seleccione el tipo de documento' }]}>
                    <Select 
                        placeholder="Seleccione..."
                        onChange={(v)=>{
                                if(v === 12)
                                setIsOtroGrupo(true)
                                else setIsOtroGrupo(false)
                        }}>
                            {grupoPoblacion.length > 0 && grupoPoblacion.map((op,i)=>(<Option   key={`p${i}`} value={op.grupo_poblacional_id}>{op.descripcion_grupo}</Option> ))}
                    </Select>
                </Item>
           {is_otro_grupo === true &&
                <Item 
                    label="Grupo poblacional" 
                    name="grupo_poblacional_otro"  rules={[{ required: true, message: 'Campo obligatorio*' }]}>
                    <Input placeholder="Grupo poblacional" />
                </Item>
            }
                
                
                <Item 
                    label="Fecha de nacimiento" 
                    name="fecha_nacimiento" 
                    rules={[{ required: true, message: 'Por favor Ingrese la fecha de nacimiento'}]}>
                    <DatePicker  style={{ width:'100%' }}  onChange={(e)=>{calculateEdad(e)}}
                        format="YYYY-MM-DD"
                    />
                    {/* <Input type="date"  style={{ width:'100%' }}  onChange={(e)=>{console.log(e)}}/> */}
                </Item>

                {/* calculateEdad(e) */}
                
                <Item 
                    label="Edad" 
                    name="edad">
                    <Input disabled/>
                </Item>
            
            
                <Item 
                    label="Curso de vida" 
                    name="curso_vida_id" 
                    rules={[{ required: true, message: 'Por favor seleccione el curso de vida' }]}>
                        <Select disabled placeholder="Seleccione...">
                        {cursovida.length > 0 && cursovida.map((op,i)=>(<Option   key={`p${i}`} value={op.curso_vida_id}>{op.descripcion_curso}</Option> ))}
                        </Select>
                </Item>
               
                <Item 
                    label="Rol en el hogar" 
                    name="vinculo_id" 
                    rules={[{ required: true, message: 'Por favor seleccione el Rol en el hogar' }]}>
                        <Select placeholder="Seleccione...">
                                {vinculo.length > 0 && vinculo.map((op,i)=>(<Option   key={`p${i}`} value={op.vinculo_jefe_hogar_id}>{op.vinculo_descripcion}</Option> ))}
                        </Select>
                </Item>

                <Item  
                        label="Integrante que recibe la visita?" 
                        name="recibio_visita" 
                        rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                    <Radio.Group value={0}>
                        <Radio value={1}>SI</Radio>
                        <Radio value={0}>NO</Radio>
                    </Radio.Group>
                </Item>

                
                <Item 
                    label="Grupo etnico" 
                    name="grupo_etnico_id" 
                    rules={[{ required: true, message: 'Por favor seleccione el grupo etnico' }]}>
                        <Select placeholder="Seleccione...">
                        
                                {etnia.length > 0 && etnia.map((op,i)=>(<Option   key={`p${i}`} value={op.grupo_etnico_id}>{op.grupo_etnico_descripcion}</Option> ))}
                        </Select>
                </Item>

               
                <Item 
                    label="Familia indígena" 
                    name="is_indigena"
                    rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Radio.Group value={0} onChange={(v)=>{
                        setIsFamiliaIndigena(v.target.value)
                    }}>
                        <Radio value={1}>SI</Radio>
                        <Radio value={0}>NO</Radio>
                    </Radio.Group>
                </Item>

            { is_familiaIndigena == true && <Item 
                label="Familia a la que pertenece" 
                name="familiaindigenaid" 
                rules={[{ required: true, message: 'Por favor seleccione Familia a la que pertenece' }]}>
                    <Select placeholder="Seleccione...">
                    {familiaIndigena.length > 0 && familiaIndigena.map((op,i)=>(<Option   key={`p${i}`} value={op.familia_indigena_id}>{op.descripcion}</Option> ))}  
                    </Select>
            </Item>
            }   
                
            <Item 
                label="Habla español?" 
                name="habla_castellano" 
                rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Radio.Group value={0}>
                <Radio value={1}>SI</Radio>
                <Radio value={0}>NO</Radio>
            </Radio.Group>
            </Item>
           
            <Item 
                label="Sexo" 
                name="sexo_id" 
                rules={[{ required: true, message: 'Por favor seleccione el Sexo' }]}>
                    <Select placeholder="Seleccione..." onChange={(v)=>{ 
                        setIsMujer(v);
                        }}>
                            {sexo.length > 0 && sexo.map((op,i)=>(<Option   key={`p${i}`} value={op.sexo_id}>{op.sexo_descripcion}</Option> ))}
                    </Select>
            </Item>

                {is_mujer === true && edad >= 12 &&<Item 
                        label="Gestante" 
                        name="is_gestante" 
                        rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                    <Radio.Group value={0} onChange={(e)=>{
                        setIsGestante(e.target.value)
                    }}>
                        <Radio value={1}>SI</Radio>
                        <Radio value={0}>NO</Radio>
                    </Radio.Group>
                    </Item>
                }            

                
                <Item 
                    label="Genero" 
                    name="genero_id" 
                    rules={[{ required: true, message: 'Por favor seleccione el Genero' }]}>
                        <Select placeholder="Seleccione...">
                                {genero.length > 0 && genero.map((op,i)=>(<Option   key={`p${i}`} value={op.genero_id}>{op.genero}</Option> ))}
                        </Select>
                </Item>   

               
                <Item 
                    label="Orientación sexual" 
                    name="orientacion_id" 
                    rules={[{ required: true, message: 'Por favor seleccione la orientación sexual' }]}>
                        <Select placeholder="Seleccione...">
                                {orientacion.length > 0 && orientacion.map((op,i)=>(<Option   key={`p${i}`} value={op.orientacion_id}>{op.orientacion}</Option> ))}
                        </Select>
                </Item>

                <Item 
                    label="Ocupación" 
                    name="ocupacion_id" 
                    rules={[{ required: true, message: 'Por favor seleccione la ocupación' }]}>
                        <Select placeholder="Seleccione...">
                        {ocupacion.length > 0 && ocupacion.map((op,i)=>(<Option   key={`p${i}`} value={op.ocupaicon_id}>{op.ocupacion_descripcion}</Option> ))}
                        </Select>
                </Item>  


                <Item 
                    label="Estado civil" 
                    name="estado_civil_id" 
                    rules={[{ required: true, message: 'Por favor seleccione el estado civil' }]}>
                        <Select placeholder="Seleccione...">
                                {estadocivil.length > 0 && estadocivil.map((op,i)=>(<Option key={`p${i}`} value={op.estado_civil_id}>{op.estado_civil_descripcion}</Option> ))}
                        </Select>
                </Item>

                <Item 
                    label="Teléfono" 
                    name="telefono">
                    <Input  placeholder="Télefono"/>
                </Item>
                
                <Item 
                    label="Email" 
                    name="email">
                    <Input  placeholder="Email"/>
                </Item>

                <Item 
                    label="Escolaridad" 
                    name="escolaridad"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                        <Select
                            style={{
                            width: '100%',
                            }}
                            placeholder="seleccione..."
                            onChange={(v)=>{
                                if(v === 1 || v === 4 || v === 92)
                                setIsAbandonoE(true)
                                else
                                setIsAbandonoE(false)
                            }}
                            // optionLabelProp="label"
                            allowClear> 
                            {escolaridad.length > 0 && escolaridad.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                        </Select>
                </Item>
            
                <Item 
                    label="Vinculación al SGSSS" 
                    name="vinculacion" 
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                    <Radio.Group value={0} onChange={(v)=>{showSlud(v)}}>
                        <Radio value={1}>SI</Radio>
                        <Radio value={0}>NO</Radio>
                    </Radio.Group>
                </Item>
                
        {showEsalud === 1 ? <>
            <Item 
                label="Régimen SGSSS" 
                name="regimen" 
                rules={[{ required: true, message: 'Por favor seleccione el regimen' }]}>
                <Select 
                        placeholder="Seleccione..." onChange={(v)=>{ setisRegimen(v)}}>
                        {regimen.length > 0 && regimen.map((op,i)=>(<Option   key={`p${i}`} value={op.regimen_id}>{op.regimen}</Option> ))}
                </Select>
            </Item>

            <Item 
                label="EAPB" 
                name="eapb" 
                rules={[{ required: true, message: 'Por favor seleccione el EAPB' }]}>
                <Select 
                        placeholder="Seleccione...">
                        {eps.length > 0 && eps.map((op,i)=>(<Option   key={`p${i}`} value={op.eps_id}>{op.nombre_eps}</Option> ))}
                </Select>
            </Item>
           { is_regimen !== 2 &&
            
            <Item 
                label="Tipo de afiliación" 
                name="afiliacion_tipo" 
                rules={[{ required: true, message: 'Por favor seleccione el Tipo de afiliación' }]}>
                <Select 
                        placeholder="Seleccione...">
                        {afiliacion.length > 0 && afiliacion.map((op,i)=>(<Option   key={`p${i}`} value={op.afiliacion_tipo_id}>{op.afiliacion_tipo}</Option> ))}
                </Select>
            </Item>
             
            }
            </>  : null
        }          

            <Item 
                label="Riesgo psicosocial" 
                name="is_riesgopsico" 
                rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                <Radio.Group value={0} onChange={(v)=>{
                    setIsRiesgoPsico(v.target.value)
                }}>
                    <Radio value={1}>SI</Radio>
                    <Radio value={0}>NO</Radio>
                </Radio.Group>
            </Item>
            
            <Item 
                label="Infecciosas" 
                name="is_infecciosas" 
                rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                <Radio.Group value={0} onChange={(v)=>{
                    setIsRiesgoInfecciosas(v.target.value)
                }}>
                    <Radio value={1}>SI</Radio>
                    <Radio value={0}>NO</Radio>
                </Radio.Group>
            </Item>
            
            
            <Item 
                    label="Seguridad alimentaria" 
                    name="is_seguridad_a" 
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                <Radio.Group value={0} onChange={(v)=>{
                        setIsSeguridadA(v.target.value)
                }}>
                    <Radio value={1}>SI</Radio>
                    <Radio value={0}>NO</Radio>
                </Radio.Group>
            </Item>
           
            <Item 
                label="Salud oral" 
                name="is_salud_o" 
                rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                <Radio.Group value={0} onChange={(v)=>{
                    setIsSaludOral(v.target.value)
                }}>
                    <Radio value={1}>SI</Radio>
                    <Radio value={0}>NO</Radio>
                </Radio.Group>
            </Item>
            
            <Item 
                label="Sufre discapacidad" 
                name="is_discapacidad" 
                rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                <Radio.Group value={0} onChange={(v)=>{
                    setIsDiscapacidad(v.target.value)
                }}>
                    <Radio value={1}>SI</Radio>
                    <Radio value={0}>NO</Radio>
                </Radio.Group>
            </Item>
            

            <Item 
                label="Con intervención o seguimiento" 
                name="is_discapacidad_intervencion" 
                rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                <Radio.Group value={0} onChange={(v)=>{}}>
                    <Radio value={1}>SI</Radio>
                    <Radio value={0}>NO</Radio>
                </Radio.Group>
            </Item>
           
           
                <Item 
                    label="Es victima del conflicto" 
                    name="is_victimizante" 
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                    <Radio.Group value={0} onChange={(v)=>{
                        setIsConflicto(v.target.value)
                    }}>
                        <Radio value={1}>SI</Radio>
                        <Radio value={0}>NO</Radio>
                    </Radio.Group>
                </Item>
           
            </div>
            
        
    

    <Divider/>
    <HeaderTitle title={"IDENTIFICACIÓN DE INTEGRANTES DEL HOGAR CON RIESGO PRIORITARIO"}/>

    <Row gutter={[16, 16]}>

        {is_discapacidad === 1 &&
        <Col xs={24} md ={24} lg={12}>
        
            <Item 
                label="Discapacidad" 
                name="discapacidad" 
                rules={[{ required: true, message: 'Por favor este campo es obligatorio' }]}>
                    <Select
                        mode="multiple"
                        style={{
                        width: '100%',
                        }}
                        placeholder="seleccione centros poblados"
                        onChange={()=>{}}
                        // optionLabelProp="label"
                        allowClear> 
                        {discapacidad.length > 0 && discapacidad.map((op,i)=>(<Option key={i} value={op.discapacidad_id}><div className="demo-option-label-item">{op.discapacidad_descripcion}</div></Option>))}
                    </Select>
            </Item>
        </Col>
        }
        { is_conflicto === 1 && <Col xs={24} md ={24} lg={12}>
            <Item 
                label="HECHO VICTIMIZANTE POR CONFLICTO ARMADO" 
                name="hechovictimizante" 
                rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                    <Select
                        mode="multiple"
                        style={{
                        width: '100%',
                        }}
                        placeholder="seleccione...."
                        onChange={()=>{}}
                        // optionLabelProp="label"
                        allowClear> 
                        {conflicto.length > 0 && conflicto.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                    </Select>
            </Item>
        </Col>
        }
    </Row>
    <Row gutter={[16, 16]}>
                 {is_gestante === 1 &&
                    <Col xs={24} md ={24} lg={12}>
                        <Item 
                            label="GESTANTE" 
                            name="gestante"
                            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                <Select
                                    mode="multiple"
                                    style={{
                                    width: '100%',
                                    }}
                                    placeholder="seleccione centros poblados"
                                    onChange={()=>{}}
                                    // optionLabelProp="label"
                                    allowClear> 
                                    {gestante.length > 0 && gestante.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                                </Select>
                        </Item>
                    </Col>
                }
                 {is_riesgopsico === 1 &&
                    <Col xs={24} md ={24} lg={12}>
                        <Item 
                            label="RIESGOS PSICOSOCIALES" 
                            name="psicosocial"
                            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                <Select
                                    mode="multiple"
                                    style={{
                                    width: '100%',
                                    }}
                                    placeholder="seleccione centros poblados"
                                    onChange={()=>{}}
                                    // optionLabelProp="label"
                                    allowClear> 
                                    {psicosocial.length > 0 && psicosocial.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                                </Select>
                        </Item>
                    </Col>
                }
             { rangoVida < 3 &&
                    <Col xs={24} md ={24} lg={12}>
                        <Item 
                            label="SALUD INFANTIL" 
                            name="saludinfantil"
                            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                <Select
                                    mode="multiple"
                                    style={{
                                    width: '100%',
                                    }}
                                    placeholder="seleccione centros poblados"
                                    onChange={()=>{}}
                                    // optionLabelProp="label"
                                    allowClear>
                                    {saludInfantil.length > 0 && saludInfantil.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                                </Select>
                        </Item>
                    </Col>
}
            {rangoVida === 5 &&
                    <Col xs={24} md ={24} lg={24}>
                   {
                    isUpdate === true ? 
                    <div className="p-3 bg-white">
                        <h4 className="mb-2 font-semibold text-[14px]">{ isUpdate === true ? "RIESGOS" : "ADULTO"} </h4>
                        <ul className="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
                            {adulto.map( (i, index) =>  <li  className="p-3 rounded-md flex bg-gray1" key={index.toString()}> <span className="bg-orange text-white p-2 rounded-md w-[30px] h-[30px] flex justify-center items-center mr-1">
                            {index + 1}</span> {i.descripcion_riesgo}</li> 
                            )}
                        </ul>
                    </div>
                    :
                    <Item 
                        label={ isUpdate === true ? "RIESGOS" : "ADULTO"} 
                        name="adulto"
                        rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                            <Select
                                mode="multiple"
                                style={{
                                width: '100%',
                                }}
                                placeholder="seleccione centros poblados"
                                onChange={()=>{}}
                                defaultValue={ isUpdate === true ? adulto.map(ee => ee.riesgo_prioritario_id) : []}
                                // optionLabelProp="label"
                                allowClear> 
                                {adulto.length > 0 && adulto.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                            </Select>
                    </Item>
                   }
                    </Col>
        }
                 { is_salud_o === 1 &&
                    <Col xs={24} md ={24} lg={12}>
                        <Item 
                            label="SALUD ORAL" 
                            name="saludoral"
                            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                <Select
                                    mode="multiple"
                                    style={{
                                    width: '100%',
                                    }}
                                    placeholder="seleccione centros poblados"
                                    onChange={()=>{}}
                                    // optionLabelProp="label"
                                    allowClear>
                                     {saludOral.length > 0 && saludOral.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                                </Select>
                        </Item>
                    </Col>
                  }
                {is_seguridad_a === 1 &&
                    <Col xs={24} md ={24} lg={12}>
                        <Item 
                            label="SEGURIDAD ALIMENTARIA Y NUTRICIONAL" 
                            name="segalimentaria"
                            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>                      
                                <Select
                                    mode="multiple"
                                    style={{
                                    width: '100%',
                                    }}
                                    placeholder="seleccione centros poblados"
                                    onChange={()=>{}}
                                    // optionLabelProp="label"
                                    allowClear> 
                                    {seguridadAlimentaria.length > 0 && seguridadAlimentaria.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                                </Select>
                        </Item>
                    </Col>
                }
{ is_infecciosas === 1 &&
                    <Col xs={24} md ={24} lg={12}>
                        <Item 
                            label="INFECCIOSAS" 
                            name="infecciosas"
                            rules={[{ required: true, message: 'Este campo es obligatorio' }]}> 
                                <Select
                                    mode="multiple"
                                    style={{
                                    width: '100%',
                                    }}
                                    placeholder="seleccione centros poblados"
                                    onChange={()=>{}}
                                    // optionLabelProp="label"
                                    allowClear
                                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}> 
                                    {infeccionas.length > 0 && infeccionas.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                                </Select>
                        </Item>
                    </Col>
}
{ is_mujer === 1 &&
                    <Col xs={24} md ={24} lg={12}>
                        <Item 
                            label="ATENCIÓN A LA MUJER" 
                            name="atencionmujer"
                            rules={[{ required: true, message: 'Este campo es obligatorio' }]}> 

                                <Select
                                    mode="multiple"
                                    style={{
                                    width: '100%',
                                    }}
                                    placeholder="seleccione ..."
                                    onChange={()=>{ }}
                                    // optionLabelProp="label"
                                    allowClear
                                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}> 
                                    {atencionMujer.length > 0 && atencionMujer.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                                </Select>
                        </Item>
                    </Col>
}
              {is_abandono_e === true &&
                    <Col xs={24} md ={24} lg={12}>
                        <Item 
                            label="RAZÓN DE ABANDONO ESCOLAR" 
                            name="abandonoescolar"
                            rules={[{ required: true, message: 'Este campo es obligatorio' }]}> 

                                <Select
                                    mode="multiple"
                                    style={{
                                    width: '100%',
                                    }}
                                    placeholder="seleccione centros poblados"
                                    onChange={()=>{}}
                                    // optionLabelProp="label"
                                    allowClear
                                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}> 
                                    {abandonoEscolar.length > 0 && abandonoEscolar.map((op,i)=>(<Option key={i} value={op.riesgo_prioritario_id}><div className="demo-option-label-item">{op.descripcion_riesgo}</div></Option>))}
                                </Select>
                        </Item>
                    </Col>
                }
            </Row>
            <Item label="">        
                <Button  htmlType="submit" className='btn-primary' icon={<SendOutlined />}> Enviar</Button>
            </Item>
    </Form>
     </>
  )
}
