import { Collapse, Tag } from 'antd';
import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined,AlertOutlined} from '@ant-design/icons';
import { Button  } from 'antd';
import { getRequestAll } from '../helpers/Api';
import MyDataTable from '../helpers/MyDataTable';
import Loading from '../helpers/Loading';
import { useNavigate } from 'react-router-dom';
import HeaderTitle from '../components/HeaderTitle';
import { getLastRouter } from '../Api/history';
const { Panel } = Collapse;


const Deteccion = () => {
    const [dataTable,setDataTable]= useState([]);
    const [dataTableIntegrante,setDataTableIntegrante]= useState([]);
    const [loading,setLoading] = useState(false)
    const [refreshData,setRefreshData] = useState(false)
    const navigate = useNavigate();
  const onChange = (key) => {
   // console.log(key);
  };

  const[Datacolumns] = useState([
  {title:"Ficha",dataIndex:"ficha",key:"ficha"},
  {title:"Fecha de visita",dataIndex:"fecha",key:"fecha"},
  {title:"Jefe del Hogar",dataIndex:"jefe",key:"jefe"},
  {title:"Tipo documento",dataIndex:"tipo_documento",key:"tipo_documento"},
  {title:"documento",dataIndex:"documento",key:"documento"},
  {title:"Estado de alerta",dataIndex:"alertas",key:"alertas"},
  // {title:"Riesgo detectado",dataIndex:"alertas",key:"alertas"},
])


    useEffect(()=>{
        let data = [];
        let ObjNew = [];
        getRequestAll(`hogar/alertasHogar`).then(res=>{
              if(res['success']){
                setRefreshData(false);
                let i =1;
           res['alertas'].forEach(element => {
                let item = ObjNew.find((e=> e.numero_ficha == element.numero_ficha));
                if(!item){
                    element.alertas = [{alerta:element.descripcion_alerta,estado:element.descripcion_estado,alerta_id:element.hogar_alertas_id,}]
                    ObjNew.push(element)
                }else{
                     item.alertas.push({alerta:element.descripcion_alerta,estado:element.descripcion_estado,alerta_id:element.hogar_alertas_id,})
                }
            });

             ObjNew.forEach(d => {
                      data.push({
                        key: i,
                        ficha:d.numero_ficha,
                        jefe:d.jefe_hogar,
                        documento:d.documento,
                        tipo_documento:d.tipo_documento,
                        dormitorios:d.dormitorios,
                        personas:d.personas,
                        fecha:d.fecha_crea,
                        alertas:<>
                          <ol className='ol-list'>{
                            d.alertas.map((e,i)=>(
                              <li key={i} className='alert-hogar' onClick={()=>{}}> 
                              <Tag color={'green'} key={i}>
                                <AlertOutlined/>
                               <span style={{'fontSize':'9px','fontWeight':'bold','textAlign':'left'}}>{ e.alerta} -</span>  <strong style={{'fontSize':'8.5px',color:'orange'}}> {e.estado}</strong>
                         
                              </Tag>
                              </li>
                            ))
                           }
                             </ol>
                        </>,
                        
                      })
                      i++;
                  });
  
                  setDataTable(data)
              }
          })
      },[refreshData])

      const[DatacolumnsIntegrantes] = useState([
        {title:"Ficha",dataIndex:"ficha",key:"ficha"},
        {title:"Nombres y apellidos",dataIndex:"nombres",key:"nombres"},
        {title:"Tipo documento",dataIndex:"tipo_documento",key:"tipo_documento"},
        {title:"Numero de documento",dataIndex:"documento",key:"documento"},
        {title:"Riesgo detectado",dataIndex:"alertas",key:"alertas"}
      ])
      
    useEffect(()=>{
        let data = [];
        let ObjNew = [];
        getRequestAll(`hogar/alertasIntegrante`).then(res=>{
              if(res['success']){
                setRefreshData(false);
                let i =1;
           res['alertas'].forEach(element => {
        
                let item = ObjNew.find((e=> e.integrante_id == element.integrante_id));
                if(!item){
                    element.alertas = [{alerta:element.descripcion_alerta,estado:element.descripcion_estado,alerta_id:element.integrante_alertas_id,}]
                    ObjNew.push(element)
                }else{
                     item.alertas.push({alerta:element.descripcion_alerta,estado:element.descripcion_estado,alerta_id:element.integrante_alertas_id,})
                }
            });
             ObjNew.forEach(d => {
                      data.push({
                        key: i,
                        ficha:d.numero_ficha,
                        nombres:d.nombre_completo,
                        documento:d.documento,
                        tipo_documento:d.tipo_documento,
                        dormitorios:d.dormitorios,
                        personas:d.personas,
                        fecha:d.fecha_crea,
                        alertas:<>
                        <ol className='ol-list'>{
                          d.alertas.map((e,i)=>(
                            <li key={i} className='alert-hogar' onClick={()=>{}}> 
                            <Tag color={'green'} key={i}>
                              <AlertOutlined/>
                             <span style={{'fontSize':'9px','fontWeight':'bold','textAlign':'left'}}>{ e.alerta} -</span>  <strong style={{'fontSize':'8.5px',color:'orange'}}> {e.estado}</strong>
                       
                            </Tag>
                            </li>
                          ))
                         }
                           </ol>
                      </>,
                      })
                      i++;
                  });
  
                  setDataTableIntegrante(data)
              }
          })
      },[refreshData])

  return (
    <> 
   
     <HeaderTitle title={"Detección"}/>
     { loading && <Loading/>}
    <Collapse onChange={onChange}>
      
        <Panel header="Detección de la vivienda" key="1">
            <MyDataTable Datacolumns={Datacolumns}  dataTable={dataTable}/>
        </Panel>
        <Panel header="Detección de los integrantes del hogar" key="2">
             <MyDataTable Datacolumns={DatacolumnsIntegrantes}  dataTable={dataTableIntegrante}/>
        </Panel>
    </Collapse>
    </>
  );
};

export default Deteccion;