import React, { useState } from 'react'
import { hogares } from '../../Api/seguimiento';
import { useQuery } from '@tanstack/react-query';
import { DownloadOutlined  } from '@ant-design/icons';
import { Skeleton, Select, Table , Button} from 'antd';
import { downloadExcelApi } from '../../Api/excel';
function Hogares({region}) {

  const [infoHogares, setInfoHagares] = useState([]);
  const [listSelect, setListSelect] = useState([]);
  const [excelExpost, setexcelExpost] = useState([])
  const [optionSelect, setOptionSelect] = useState("");

  const { data, status } = useQuery({ 
      queryKey: [`hogares`, region], 
      queryFn: hogares,
      onSuccess: (info) => {
        let arrayAll = [];
        info.data.forEach(e => arrayAll.push(e.ciudad));
        const mySet1 = new Set(arrayAll);

        let finalAtta = [...mySet1]   
        if(finalAtta[0]){
            handleChange(finalAtta[0], info)
        }
        setInfoHagares(finalAtta);
        setexcelExpost(info.data)
      }
  });

  if(status === "loading") return <Skeleton active />
  if(status === "error")  return <p>Ups...</p>

  function handleChange (e, array){
    setOptionSelect(e)
    let arrayFilt = array ? array : data;
    const arrayFilter = arrayFilt.data.filter( i => i.ciudad === e);
    const formaArray = arrayFilter.map( (i, index) => {
        return ({
            ciudad: i.ciudad,
            riesgo: i.riesgo,
            cantidad: i.cantidad
        })
    })
    setListSelect(formaArray)
  };

  const columns = [
    {
      title: 'ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Descripción',
      dataIndex: 'riesgo',
      key: 'riesgo',
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
  ]

  function handleDownloadExcel() {
    let body = excelExpost.map( i => Object.values(i) );
    let header2 = excelExpost.map( i => Object.keys(i) );

    let obj = {
        fileName: "Riesgos por hogar",
        header: header2[0],
        body
    };
    downloadExcelApi(obj);
}

  return (
    <div>
    <div className='md:flex sm:block mb-4'>
      <h2 className='md:text-2xl sm:text-xl mb-2 font-bold mr-3 uppercase' >Riesgos por hogar</h2>
      <Button type="dashed" shape="round" onClick={handleDownloadExcel} icon={<DownloadOutlined />} size="Large"
          className='px-6 bg-geen2 text-white hover:text-geen2'
          disabled={excelExpost.length < 1 ? true : false}
      >
          Descargar Excel
      </Button>
    </div>
    { infoHogares.length <= 1 ? null :
      <Select
        style={{
            width: 300,
            marginBottom: 36
        }}
        placeholder="Selecciona una ciudad "
        onChange={ (e) => handleChange(e)}
        value={optionSelect}
        options={ infoHogares.map(i => {
            return   {
                value: i,
                label: i,
            }
        } )}
      />
    }
     { listSelect.length == 0 ? <div className='w-full flex justify-center items-center'>
      <p className='m-10 underline'>Selecciona una ciudad para ver resultados</p> 
     </div>
     :
        <Table dataSource={listSelect} columns={columns} />
      }
    </div>
  )
}

export default Hogares