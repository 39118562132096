import axios from "axios";
import { settings } from "./setting";

export const getInfouserFromCC =  async (cc) => {
    const res = await axios.get(`${settings.url}/generics/integrante/?documento=${cc}`)
    return res.data.data.Integrante
}

export const sexo =  async () => {
    const res = await axios.get(`${settings.url}/generics/sexo`)
    return res.data.data.sexo
}

export const cursodevida =  async () => {
    const res = await axios.get(`${settings.url}/generics/cursodevida`)
    return res.data
}

export const vinculo =  async () => {
    const res = await axios.get(`${settings.url}/generics/vinculo`)
    return res.data
}

export const orientacion =  async () => {
    const res = await axios.get(`${settings.url}/generics/orientacion`)
    return res.data
}

export const genero =  async () => {
    const res = await axios.get(`${settings.url}/generics/genero`)
    return res.data
}

export const ocupacion =  async () => {
    const res = await axios.get(`${settings.url}/generics/ocupacion`)
    return res.data.data
}

export const estadocivil =  async () => {
    const res = await axios.get(`${settings.url}/generics/estadocivil`)
    return res.data
}

export const etnia =  async () => {
    const res = await axios.get(`${settings.url}/generics/etnia`)
    return res.data.data
}

export const documentotipo =  async () => {
    const res = await axios.get(`${settings.url}/generics/documentotipo`)
    return res.data.data
}

export const afiliacion =  async () => {
    const res = await axios.get(`${settings.url}/generics/afiliacion`)
    return res.data.data
}

export const eps =  async () => {
    const res = await axios.get(`${settings.url}/generics/eps`)
    return res.data
}

export const regimen =  async () => {
    const res = await axios.get(`${settings.url}/generics/regimen`)
    return res.data
}

export const grupopoblacion =  async () => {
    const res = await axios.get(`${settings.url}/generics/grupopoblacion`)
    return res.data.data
}

export const discapacidad =  async () => {
    const res = await axios.get(`${settings.url}/generics/discapacidad`)
    return res.data
}

export const familiaindigena =  async () => {
    const res = await axios.get(`${settings.url}/generics/familiaindigena`)
    return res.data
}

export const riesgoprioritario =  async (id) => {
    const res = await axios.get(`${settings.url}/generics/riesgoprioritario?id=${id}`)
    return res.data
}

export const saveAtencion =  async (data) => {

    let userSession = JSON.parse(localStorage.getItem('user'));

    const res = await axios.post(`${settings.url}/generics/atencion`, {JSON_object: JSON.stringify(data) }, 
        { headers: {'Authorization': userSession.token}})
    return res.data
}
export const tipovivienda =  async (id) => {
    const res = await axios.get(`${settings.url}/generics/tipovivienda`)
    return res.data
}

export const familiatipo =  async (id) => {
    const res = await axios.get(`${settings.url}/generics/familiatipo`)
    return res.data
}

export const tenencia =  async (id) => {
    const res = await axios.get(`${settings.url}/generics/tenencia`)
    return res.data
}
export const complementos =  async (id) => {
    const res = await axios.get(`${settings.url}/generics/complemento`)
    return res.data
}

export const nomenclatura =  async (id) => {
    const res = await axios.get(`${settings.url}/generics/nomenclatura`)
    return res.data
}

export const getAtenciones =  async () => {
    let userSession = JSON.parse(localStorage.getItem('user'));

    const res = await axios.get(`${settings.url}/generics/atencionsalud`, 
        { headers: {'Authorization': userSession.token}})
    return res.data.data.Atenciones
}

export const saveAtencionIntegrante =  async (id, json) => {
    let userSession = JSON.parse(localStorage.getItem('user'));

    const res = await axios.post(`${settings.url}/generics/atencioni`, {
        "atencion_id": id,
        "JSON_Object_Integrante": JSON.stringify(json)
    }, 
    { headers: {'Authorization': userSession.token}}
    )
    return res.data
}

export const getIntegranteATencion =  async ({queryKey}) => {
    let userSession = JSON.parse(localStorage.getItem('user'));

    const res = await axios.get(`${settings.url}/generics/integranteByAtencionId?atencion_id=${queryKey[1]}`, 
        { headers: {'Authorization': userSession.token}}
    )
    return res.data.data.Atenciones;
}

export const encuestaOpciones = [
    {
        label: 'Nunca',
        value: 0
    },
    {
        label: 'Casi nunca',
        value: 1
    },
    {
        label: 'Algunas Veces',
        value: 2
    },
    {
        label: 'Casi siempre',
        value: 3
    },
    {
        label: 'Siempre',
        value: 4
    }
];

export const encuentaQuestions = [
    'Me satisface la ayuda que recibo de mi familia, cuando tengo algun problema o necesidad',
    'Me satisface la participacion que mi familia me brinda y me permite',
    'Me satisface como mi familia acepta y apoya mis deseos de emprender nuevas actividades',
    'Me satisface como mi familia expresa afecto y responde a mis emociones como: Rabia, Tristeza, Amor, etc',
    'Me satisface como compartimos en mi familia: A) El teimpo para estar juntos, B) Los espacios de la casa,  C) El dinero'
]
