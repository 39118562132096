import React from 'react'
import { mascotas } from '../../Api/seguimiento';
import { useQuery } from '@tanstack/react-query';
import { Skeleton  } from 'antd';
import perroImg from '../../assets/img/huella.png';
import gatoImg from '../../assets/img/pata.png';


function Mascotas({region}) {

    const { data, status } = useQuery({ 
        queryKey: [`mascotas`, region], 
        queryFn: mascotas
    });

    if(status === "loading") return <Skeleton active />
    if(status === "error")  return <p>Ups...</p>

  return (
    <div>
        <h3 className='text-2xl font-bold  mb-4 uppercase'>Mascotas</h3>
        <div>
            { data.data.map( (i, index) => 
            <div key={index.toString()} className="mb-3">
                <h4 className='font-bold'>{i.ciudad}</h4>
                <div className='flex justify-between md:flex-row sm:flex-col'>
                    <div className='md:w-2/5 sm:w-full flex rounded-md shadow-md p-4'>
                        <img src={perroImg} className="w-[45px]"/>
                        <div className='ml-3'>
                            <p>Perros: {i.cantidad_perros}</p>
                            <p>Vacunados: {i.perros_vacunados}</p>
                        </div>
                    </div>
                    <div className='md:w-2/5 sm:w-full flex rounded-md shadow-md p-4'>
                        <img src={gatoImg} className="w-[45px]"/>
                        <div  className='ml-3'>
                            <p>Gatos: {i.cantidad_gatos}</p>
                            <p>Vacunados: {i.gatos_vacunados}</p>
                        </div>
                    </div>
                    <div className='md:w-1/6 sm:w-full flex rounded-md shadow-md p-4'>
                        <span>Otros: {i.cantidad_otros}</span>

                    </div>
                </div>
            </div>
            ) }
            
        </div>
    </div>
  )
}

export default Mascotas