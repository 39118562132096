import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'antd/dist/antd.min.css'
import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import {Provider} from 'react-redux';
import combineReducers from './redux'

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore(combineReducers, {}, applyMiddleware(reduxThunk));


root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);
