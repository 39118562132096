import {Button,Col,Divider,Form,Input,Typography,Upload,Row,Select} from 'antd';
import { SendOutlined, InboxOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { getRequestAll, getRequestById, getRequestIdParams, postRequest } from '../helpers/Api';
import { useParams,useNavigate, Link, json} from 'react-router-dom';
import openNotificationWithIcon from '../helpers/Notification';
import Loading from '../helpers/Loading';
import MyDataTable from '../helpers/MyDataTable';
import { connect } from 'react-redux';
import { gestionCanalizacionId } from '../Api/canalizacion';


  const { Option } = Select;
  const {Title} = Typography;


  const normFile = (e) => {
  
    if (Array.isArray(e)) {
      return e;
    }
  
    return e?.fileList;
  };
  
  const GestionarCanalizacion = ({is_integrante}) => {
  const params = useParams();
  const [form] = Form.useForm();
  const {alerta_hogar_id,integrante_alertas_id,ficha, data, direccion} = params;

  const [canalizacionTipo,setCanalizacionTipo] = useState('')
  const [estado,setEstado] = useState(false)
  const [loading,setLoading] = useState(false)
  const [acciones,SetAcciones] = useState([])
  const navigate = useNavigate();
  const { alerta, alerta_Hohar_id, jefe_hogar, nombre_completo,numero_ficha, riesgo, ciudad, documento, integrante_id , alerta_id} = JSON.parse(data);

  const [dataTable,setDataTable]= useState([]);

  useEffect(()=>{
    let data= [];
    if(is_integrante == true){ //console.log(integrante_alertas_id)
    getRequestAll(`generics/gestiones?integrante_alertas_id=${integrante_alertas_id}`).then(res=>{
          if(res['success']){
            res['data']['gestion'].forEach((e,index) => {
               data.push({
                key:index,
              cumplimiento:e.cumplimiento,
              descripcion_estado:e.descripcion_estado,
              fecha_gestion:e.fecha_gestion,
              ficha:e.ficha,
              gestion_canalizacion_id:e.gestion_canalizacion_id,
              hogar_alertas_id:e.hogar_alertas_id,
              integrante_alertas_id:e,integrante_alertas_id,
              observacion:e.observacion,
              fecha_gestion:e.fecha_gestion,
               })
              setDataTable(data)
            });
          }
    })}
      else
    if(is_integrante == false)
    getRequestAll(`generics/gestioneshogar?hogar_alertas_id=${alerta_hogar_id}`).then(res=>{
          if(res['success']){
            res['data']['gestion'].forEach((e,index) => {
               data.push({
                key:index,
                cumplimiento:e.cumplimiento,
                descripcion_estado:e.descripcion_estado,
                descripcion: e.descripcion,
                fecha_gestion:e.fecha_gestion,
                ficha:e.ficha,
                gestion_canalizacion_id:e.gestion_canalizacion_id,
                hogar_alertas_id:e.hogar_alertas_id,
                integrante_alertas_id:e,integrante_alertas_id,
                observacion:e.observacion,
                fecha_gestion:e.fecha_gestion,
               })
              setDataTable(data)
            });
          }
      })
  },[loading])


 async function settingValus(){
    getRequestById(alerta_id,'hogar/canalizacion_tipo_alert').then(res=>{
      if(res['success'])
        setCanalizacionTipo(res['data']['canalizacionTipo'].canalizacion_tipo)
    })
    let riesgos = riesgo;
    let parse = parseInt(riesgo);
    if(isNaN(parse) === false) riesgos = alerta;
    if(is_integrante === true) {
      const info =  await gestionCanalizacionId(integrante_id, alerta_id);
      const { data } = info;
      if(Object.keys(data).length === 0) riesgos = alerta
    }

    form.setFieldsValue(
      {
        ficha:ficha,
        documento:documento,
        canalizacion:canalizacionTipo,
        direccion: direccion,
        jefe_hogar: jefe_hogar ? jefe_hogar : nombre_completo,
        riesgo: riesgos
      }
    )
  }
  useEffect(()=>{
    settingValus();
  },[canalizacionTipo])
    
    
  const onFinish = (values) => {

    if(is_integrante === true)
      values.integrante_alertas_id= integrante_alertas_id;
      else
    values.hogar_alerta_id = alerta_hogar_id;
    values.alerta_id = alerta_id;
    values.archivo ="file"
    setLoading(true)
    if(is_integrante=== false)  {
    setTimeout(() => {
      postRequest('hogar/g_canalizacion',values).then(res=>{
        if(res['success']){
          openNotificationWithIcon("success","Exito al canalizar","Se administro la canalizacion con exito")    
        }
        setLoading(false);
        form.setFieldsValue({
          observacion:null,
          descripcion: null
        })
      }).catch(err=>{
        setLoading(false)
      })
    }, 500);
  }

    if(is_integrante === true)  
    setTimeout(() => {
      postRequest('hogar/g_canalizacion_i',values).then(res=>{
        if(res['success']){
          openNotificationWithIcon("success","Exito al canalizar","Se administro la canalizacion con exito")    
        }
        setLoading(false);
    
        form.setFieldsValue({
          observacion:null,
          descripcion: null
        })
      }).catch(err=>{
        setLoading(false)
      })
    }, 500);



  };

// console.log(dataTable)
    return (
    <>
        <Row>
        <Col xs={24} md ={24} lg={24}>
          
          {is_integrante ?
           <Title level={4}>GESTIONAR CANALIZACIÓN INTEGRANTE DEL HOGAR</Title>
           :
           <Title level={4}>GESTIONAR CANALIZACIÓN DEL HOGAR</Title>
          }
        <Divider />
        </Col>
       </Row>
        <Row>
        <Col xs={20} sm={20} lg={20}>
        <Form
            form={form}
            layout="vertical"
            name="register"
            onFinish={onFinish}
            wrapperCol={{ span: 22 }}
            
            scrollToFirstError
        >
          {loading && <Loading/>}
        <Row>
            <Col lg={8}>
                <Form.Item
                name="ficha"
                label="Numero de ficha"
                rules={[
                    {
                    required: true,
                    message: 'Please input Intro',
                    },
                ]}
                >
                  <Input disabled/>
                </Form.Item>
            </Col>
          
            <Col lg={8}>
                <Form.Item
                name="direccion"
                label="direccion"
                rules={[
                    {
                    required: true,
                    message: 'Please input Intro',
                    },
                ]}
                >
                  <Input disabled/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col lg={8}>
                <Form.Item
                name="jefe_hogar"
                label={ is_integrante ? "Nombre integrante" : "Nombre jefe de hogar"}
                rules={[
                    {
                    message: 'Please input Intro',
                    },
                ]}
                >
                  <Input disabled/>
                </Form.Item>
            </Col>
            <Col lg={8}>
                <Form.Item
                name="documento"
                label="Numero de Documento"
                rules={[
                    {
           
                    message: 'Please input Intro',
                    },
                ]}
                >
                  <Input disabled/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
           
            <Col lg={17}>
                <Form.Item
                name="riesgo"
                label="riesgo"
                rules={[
                    {
                    required: true,
                    message: 'Please input Intro',
                    },
                ]}
                >
                  <Input.TextArea showCount maxLength={700}  disabled/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
           
            <Col lg={17}>
                <Form.Item
                name="descripcion"
                label="Descripcion"
                rules={[
                    {
                    required: true,
                    message: 'Please input Intro',
                    },
                ]}
                >
                  <Input.TextArea showCount maxLength={700} />
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col lg={17}>
                <Form.Item
                name="canalizacion"
                label="Canalización"
                rules={[
                    {
                    required: true,
                    message: 'Please input Intro',
                    },
                ]}
                >
                  <Input disabled/>
                </Form.Item>
            </Col>
            <Col lg={24}>
              <ul>
                {
                  acciones.map((item,i) => {
                     return (<li key={i}>{item.accion_educativa}</li>)
                  })
                }
              </ul>
            </Col>
        </Row>

        <Row>
            <Col lg={17}>
                <Form.Item
                name="observacion"
                label="Observaciones"  rules={[
                  {
                  required: false,
                  message: 'El observacion es requerido',
                  },
              ]}>
                <Input.TextArea showCount maxLength={500} />
                </Form.Item>
        
            </Col>
        </Row>

        <Row>

          <Col lg={8}>
             <Form.Item label="Adjuntar documento">
                <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                <Upload.Dragger name="files" action="/upload.do">
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined  style={{ color: '#ff7208' }}/>
                    
                    </p>
                    <p className="ant-upload-text">Haga clic o arrastre el archivo a esta área para cargarlo</p>
                    {/* <p className="ant-upload-hint">Carga única o masiva.</p> */}
                </Upload.Dragger>
                </Form.Item>
            </Form.Item>
          </Col>

          <Col lg={8}>

             <Form.Item label="Estado" name="estado"  rules={[
                    {
                    required: true,
                    message: 'El estado es requerido',
                    },
                ]}>
                <Select placeholder="Seleccione ..." onChange={(v)=>{
                  if(v === '3')
                      setEstado(true)
                      else
                      setEstado(false)
                }}>
                    <Option value="2">En proceso</Option>
                    <Option value="3">Finalizado</Option>
                </Select>
            </Form.Item>

           { estado === true &&
             <Form.Item label="Grado de cumplimiento" name="cumplimiento"  rules={[{ required: true, message: 'Campo obligatorio...' }]}>
                <Select placeholder="Seleccione ..." >
                    <Option value="Satisfactorio">Satisfactorio</Option>
                    <Option value="No satisfactorio">No satisfactorio</Option>
                </Select>
            </Form.Item>
          }
          </Col>

   
 
        </Row>
        <Row>
            <Form.Item>
              <Button  htmlType="submit" className='btn-primary' icon={<SendOutlined />}> Enviar</Button>
            </Form.Item>
            </Row>
        </Form>
        </Col>
        {/* <Col lg={4}>
            <MyDataTable  dataTable={dataTable} Datacolumns={
              [
                {title:"Riesgo detectado",dataIndex:"descripcion_riesgo",key:"descripcion_riesgo"},
              ]
            } />
          </Col> */}
        </Row>
        <Row>
        <Col>
            <MyDataTable  dataTable={dataTable} Datacolumns={
              [
                {title:"Ficha",dataIndex:"ficha",key:"ficha"},
                {title:"Observacion",dataIndex:"observacion",key:"observacion"},
                {title:"Estado",dataIndex:"descripcion_estado",key:"descripcion_estado"},
                {title:"descripcion",dataIndex:"descripcion",key:"descripcion"},
                
                {title:"Cumplimiento",dataIndex:"cumplimiento",key:"cumplimiento"},
                {title:"Fecha de gestion",dataIndex:"fecha_gestion",key:"fecha_gestion"},
                
              ]
            } />
          </Col>
        </Row>
    </>
    );
  };

  const mapStateToProps = state => state;
  export default connect(mapStateToProps, {})(GestionarCanalizacion);