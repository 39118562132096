import { SendOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Radio } from 'antd';
import React, { useState } from 'react';
import HeaderTitle from '../components/HeaderTitle';
import { LoginPost } from '../helpers/Api';
import Loading from '../helpers/Loading';
import openNotificationWithIcon from '../helpers/Notification';
import {  useNavigate,Link } from 'react-router-dom';
const NotificarCambio = () => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('vertical');
  const [loading,setLoading]= useState(false);

  const navigate = useNavigate();
  const onFinish = (values) => {
    setLoading(true)
    LoginPost('send-email',values)
     .then(res=>{
       setTimeout(() => {
            setLoading(false)
            let response = res['data'];
            if(res['success']){
              openNotificationWithIcon('success','Ingrese a la bandeja de entrada de su correo electronico para restablecer la contraseña')
              setTimeout(()=>{
                navigate('/', { replace: true });
              },3000)
            }else{
              openNotificationWithIcon('error','Error no se pudo notificar')
            }
        }, 500);

    })
    .catch(err=>{
        setLoading(false)
    })

  };

  return (
    <div className='flex justify-center items-center h-screen content-recupera'>
      <div className='div-recuperar md:w-[500px] sm:w-[350px] shadow-lg p-6 rounded-lg bg-white'>
        <div  className="mt-3 text-center font-semibold text-xl mb-3 ">
          <h2 className='text-orange'>Recuperar contraseña</h2>
        </div>
          <Form
            layout={formLayout}
            onFinish ={onFinish}
          >
            {loading === true && <Loading/>}
            <Form.Item label="Email" name="email"
              rules={[{ required: true, type:'email', message: 'Por favor ingrese su correo electrónico!'}]}>
              <Input placeholder="Correo electronico" required/>
            </Form.Item>
            <Form.Item >
            <Button  htmlType="submit" className='btn-primary' icon={<SendOutlined />}> Enviar</Button>
            <br/>
            <br/>
            <Link to="/">
                          <UserOutlined/>Iniciar sesión 
                        </Link>
            </Form.Item>
          </Form>
      </div>
    </div>

  );
};
export default NotificarCambio