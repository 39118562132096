export const getHistory = () => {
    let historial = JSON.parse(sessionStorage.getItem("history"));
    return historial
}

export const getLastRouter = (callback) => {
    let historial = JSON.parse(sessionStorage.getItem("history"));

    if(historial.length > 2) {
        callback( historial[ historial.length -2 ], { replace: true } )
        historial.pop();
        sessionStorage.setItem("history", JSON.stringify(historial));
        return historial
    }else{
        return false
    }
};
export function goToBack(){
   
   window.location.hash="no-back-button";
   window.location.hash="Again-No-back-button" //chrome
   window.onhashchange=function(){window.location.hash="no-back-button";}
}