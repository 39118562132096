import { useQueries } from '@tanstack/react-query'
import { Skeleton, List, Avatar } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getHogarInfo, getRiesgosHogarId } from '../Api/hogares';
import  IdentificacionHogar from '../pages/IdentificacionHogar';
import { hogarAction } from '../redux/actions/hogar.action';


function HogaresRiesgos(props) {

  const [infoRiesgo, setInfo] = useState({});
  const [listIresgos, setListIresgos] = useState({});
  const result = useQueries({
    queries: [
      { queryKey: ['getHogarInfo', props.hogar.hogar_id], queryFn: getHogarInfo, staleTime: Infinity, 
        onSuccess: (info) => setInfo(info.Hogar)
      },
      { queryKey: ['getRiesgosHogarId', props.hogar.hogar_id], queryFn: getRiesgosHogarId, staleTime: Infinity,
        onSuccess: (info) => {
          setListIresgos( info.data.Riesgos)
        }
      }
    ]
  });

  if( result.some(res => res.isLoading) ) return <Skeleton active />

  return (
    <div>
     <IdentificacionHogar isPreview={true} info={infoRiesgo} listIresgos={listIresgos}  />
    </div>
  )
}

const mapStateToProps = state => state;

export default connect(mapStateToProps, { hogarAction })(HogaresRiesgos);