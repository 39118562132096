 
export const municipios =  [
	{
		"ciudad_id" : "030",
		"departamento_id" : 47,
		"ciudad" : "ALGARROBO",
		"hospital_ejecutor" : "E.S.E HOSPITAL DEPARTAMENTAL SAN  RAFAEL ",
		"subregiones_id" : 4,
		coord: {
			lat: 10.190826,
			long: -74.0691773
		}
	},
	{
		"ciudad_id" : "053",
		"departamento_id" : 47,
		"ciudad" : "ARACATACA",
		"hospital_ejecutor" : "E.S.E HOSPITAL LUISA SANTIAGA MÁRQUEZ IGUARÁN ",
		"subregiones_id" : 3,
		coord: {
			lat: 10.5919255,
			long: -74.1904906
		}
	},
	{
		"ciudad_id" : "058",
		"departamento_id" : 47,
		"ciudad" : "ARIGUANI",
		"hospital_ejecutor" : "E.S.E HOSPITAL LOCAL TENERIFE ",
		"subregiones_id" : 2,
		coord: {
			lat: 10.325733890425688,
			long: -74.86900336718078
		}
		
	},
	{
		"ciudad_id" : 161,
		"departamento_id" : 47,
		"ciudad" : "CERRO SAN ANTONIO",
		"hospital_ejecutor" : " ESE HOSPITAL SANTADER HERRERA DE PIVIJAY ",
		"subregiones_id" : 1,
		coord: {
			lat: 10.325733890425688,
			long: -74.86900336718078
		}
	},
	{
		"ciudad_id" : 170,
		"departamento_id" : 47,
		"ciudad" : "CHIBOLO",
		"hospital_ejecutor" : "E.S.E HOSPITAL LOCAL TENERIFE ",
		"subregiones_id" : 2,
		coord: {
			lat: 10.028437318784327,
			long: -74.62489110135029
		}
	},
	{
		"ciudad_id" : 189,
		"departamento_id" : 47,
		"ciudad" : "CIENAGA",
		"hospital_ejecutor" : "E.S.E HOSPITAL DEPARTAMENTAL SAN  RAFAEL",
		"subregiones_id" : 4,
		coord: {
			lat: 11.007329220456917,
			long:  -74.24328385749381
		}
	},
	{
		"ciudad_id" : "205",
		"departamento_id" : 47,
		"ciudad" : "CONCORDIA",
		"hospital_ejecutor" : "ESE HOSPITAL SANTADER HERRERA DE PIVIJAY",
		"subregiones_id" : 1,
		coord: {
			lat: 10.257838919158063, 
			long: -74.83187636902782,
		}
	},
	{
		"ciudad_id" : "245",
		"departamento_id" : 47,
		"ciudad" : "EL BANCO",
		"hospital_ejecutor" : " E.S.E HOSPITAL LA CANDELARIA",
		"subregiones_id" : 5,
		coord: {
			lat: 9.004948486728269, 
			long: -73.9744219815574,
		}
	},
	{
		"ciudad_id" : "258",
		"departamento_id" : 47,
		"ciudad" : "EL PIÑON",
		"hospital_ejecutor" : " ESE HOSPITAL SANTADER HERRERA DE PIVIJAY ",
		"subregiones_id" : 1,
		coord: {
			lat: 10.403467637854297, 
			long: -74.82475611100327,
		}
	},
	{
		"ciudad_id" : "268",
		"departamento_id" : 47,
		"ciudad" : "EL RETEN",
		"hospital_ejecutor" : "E.S.E HOSPITAL DEPARTAMENTAL SAN  RAFAEL ",
		"subregiones_id" : 4,
		coord: {
			lat: 10.609936983132023, 
			long: -74.26884706639312,
		}
	},
	{
		"ciudad_id" : "288",
		"departamento_id" : 47,
		"ciudad" : "FUNDACION",
		"hospital_ejecutor" : "E.S.E HOSPITAL LUISA SANTIAGA MÁRQUEZ IGUARÁN ",
		"subregiones_id" : 4,
		coord: {
			lat: 10.521144625165133, 
			long: -74.19056704277791,
		}
	},
	{
		"ciudad_id" : "318",
		"departamento_id" : 47,
		"ciudad" : "GUAMAL",
		"hospital_ejecutor" : " E.S.E HOSPITAL LA CANDELARIA ",
		"subregiones_id" : 5,
		coord: {
			lat: 9.144166279173888, 
			long: -74.22393371443637,
		}
	},
	{
		"ciudad_id" : "460",
		"departamento_id" : 47,
		"ciudad" : "NUEVA GRANADA",
		"hospital_ejecutor" : "E.S.E HOSPITAL LOCAL TENERIFE ",
		"subregiones_id" : 2,
		coord: {
			lat: 9.802170143847105, 
			long: -74.39037771215246,
		}
	},
	{
		"ciudad_id" : "541",
		"departamento_id" : 47,
		"ciudad" : "PEDRAZA",
		"hospital_ejecutor" : "ESE HOSPITAL SANTADER HERRERA DE PIVIJAY",
		"subregiones_id" : 1,
		coord: {
			lat: 10.188434621657217, 
			long: -74.91649411164138,
		}
	},
	{
		"ciudad_id" : "545",
		"departamento_id" : 47,
		"ciudad" : "PIJIÑO DEL CARMEN",
		"hospital_ejecutor" : " E.S.E HOSPITAL LA CANDELARIA",
		"subregiones_id" : 5,
		coord: {
			lat: 9.331129320072503, 
			long: -74.45380267911777,
		}
	},
	{
		"ciudad_id" : "551",
		"departamento_id" : 47,
		"ciudad" : "PIVIJAY",
		"hospital_ejecutor" : " ESE HOSPITAL SANTADER HERRERA DE PIVIJAY ",
		"subregiones_id" : 1,
		coord: {
			lat: 10.461920054309505, 
			long: -74.6152332652625,
		}
	},
	{
		"ciudad_id" : "555",
		"departamento_id" : 47,
		"ciudad" : "PLATO",
		"hospital_ejecutor" : "E.S.E HOSPITAL LOCAL TENERIFE",
		"subregiones_id" : 2,
		coord: {
			lat: 9.79303107814235, 
			long: -74.78382219625263,
		}
	},
	{
		"ciudad_id" : "570",
		"departamento_id" : 47,
		"ciudad" : "PUEBLOVIEJO",
		"hospital_ejecutor" : "E.S.E HOSPITAL DEPARTAMENTAL SAN RAFAEL",
		"subregiones_id" : 4,
		coord: {
			lat: 10.994311862191648, 
			long: -74.28405996581596,
		}
	},
	{
		"ciudad_id" : "605",
		"departamento_id" : 47,
		"ciudad" : "REMOLINO",
		"hospital_ejecutor" : "E.S.E HOSPITAL LUISA SANTIAGA MÁRQUEZ IGUARÁN ",
		"subregiones_id" : 3,
		coord: {
			lat: 10.702996534443345, 
			long: -74.71640391582358,
		}
	},
	{
		"ciudad_id" : "660",
		"departamento_id" : 47,
		"ciudad" : "SABANAS DE SAN ANGEL",
		"hospital_ejecutor" : "E.S.E HOSPITAL DEPARTAMENTAL SAN  RAFAEL ",
		"subregiones_id" : 4,
		coord: {
			lat: 10.0807142, 
			long: -74.3257421,
		}
	},
	{
		"ciudad_id" : "675",
		"departamento_id" : 47,
		"ciudad" : "SALAMINA",
		"hospital_ejecutor" : " ESE HOSPITAL SANTADER HERRERA DE PIVIJAY ",
		"subregiones_id" : 1,
		coord: {
			lat: 10.491358157425433, 
			long: -74.79399006639616,
		}
	},
	{
		"ciudad_id" : "692",
		"departamento_id" : 47,
		"ciudad" : "SAN SEBASTIAN DE BUENAVISTA",
		"hospital_ejecutor" : " E.S.E HOSPITAL LA CANDELARIA ",
		"subregiones_id" : 5,
		coord: {
			lat: 9.239475082524004, 
			long: -74.35127933887031,
		}
	},
	{
		"ciudad_id" : "703",
		"departamento_id" : 47,
		"ciudad" : "SAN ZENON",
		"hospital_ejecutor" : " E.S.E HOSPITAL LA CANDELARIA ",
		"subregiones_id" : 5,
		coord: {
			lat: 9.244788471548757, 
			long: -74.498282109039,
		}
	},
	{
		"ciudad_id" : "707",
		"departamento_id" : 47,
		"ciudad" : "SANTANA",
		"hospital_ejecutor" : " E.S.E HOSPITAL LA CANDELARIA ",
		"subregiones_id" : 5,
		coord: {
			lat: 9.322833877706977, 
			long: -74.5691062942635,
		}
	},
	{
		"ciudad_id" : "720",
		"departamento_id" : 47,
		"ciudad" : "SANTA BARBARA DE PINTO",
		"hospital_ejecutor" : " E.S.E HOSPITAL LA CANDELARIA",
		"subregiones_id" : 5,
		coord: {
			lat: 9.436181545452659, 
			long: -74.70181714865183,
		}
	},
	{
		"ciudad_id" : "745",
		"departamento_id" : 47,
		"ciudad" : "SITIONUEVO",
		"hospital_ejecutor" : "E.S.E HOSPITAL LUISA SANTIAGA MÁRQUEZ IGUARÁN ",
		"subregiones_id" : 3,
		coord: {
			lat: 10.776300355866837, 
			long: -74.72085214707083,
		}
	},
	{
		"ciudad_id" : "798",
		"departamento_id" : 47,
		"ciudad" : "TENERIFE",
		"hospital_ejecutor" : "E.S.E HOSPITAL LOCAL TENERIFE ",
		"subregiones_id" : 2,
		coord: {
			lat: 9.899100435443824, 
			long: -74.85813537357434,
		}
	},
	{
		"ciudad_id" : "960",
		"departamento_id" : 47,
		"ciudad" : "ZAPAYAN",
		"hospital_ejecutor" : "ESE HOSPITAL SANTADER HERRERA DE PIVIJAY",
		"subregiones_id" : 1,
		coord: {
			lat: 10.1704379, 
			long: -74.7214787,
		}
	},
	{
		"ciudad_id" : "980",
		"departamento_id" : 47,
		"ciudad" : "ZONA BANANERA",
		"hospital_ejecutor" : "E.S.E HOSPITAL LUISA SANTIAGA MÁRQUEZ IGUARÁN ",
		"subregiones_id" : 3,
		coord: {
			lat: 10.804925615373579, 
			long: -74.17792782579613,
		}
	}
]