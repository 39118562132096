import React, { useEffect, useState } from 'react'
import { SearchOutlined,FileExcelOutlined,PlusOutlined ,MinusOutlined,CheckOutlined,ControlOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import { Button, Space,Tooltip ,Typography,Divider, Skeleton } from 'antd';
import {Col,Row} from 'antd';
// import TopNavigate from '../components/TopNavigate';
import { getRequestAll,patchRequestById } from '../helpers/Api';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { Excel } from "antd-table-saveas-excel";
import openNotificationWithIcon from '../helpers/Notification';
import MyDataTable from '../helpers/MyDataTable';
import { useQueries } from '@tanstack/react-query';
import { getCityUser, getAlluser } from '../Api/user';
import { getLastRouter } from '../Api/history';


export const UserList = () => {
  const [dataTable,setDataTable]= useState([]);
  const [refreshData,setRefreshData] = useState(false)
  const [userCityName, setUserCityName] = useState("");
  const navigate = useNavigate();
  const { Title } = Typography;

  const validTypeUser = (info) => {

    const userInfo = JSON.parse(localStorage.getItem("user"));
    let data = [];
    info.Users.forEach((d, index )=> {
   
      data.push({
        key: index,
        name:d.nombre_completo,
        identificacion:d.identificacion,
        email:d.email,
        municipio:d.ciudad,
        red:d.nombre_red,
        equipo:d.equipo,
        perfil:d.profesion_descripcion,
        cargo:d.cargo,
        celular: d.celular,
        visitas:0,
        hejecutor:d.hospital_ejecutor,
        subregion:d.subregion,
        acciones:<>
        <Tooltip title='Ver información del usuario' color="orange">
          <Button type="primary" className='btn-detalle' shape="circle" icon={<SearchOutlined  />}  onClick={()=>navigate(`/panel/usuario/${d.usuario_id}`, { replace: true })} /> 
        </Tooltip>
        {' '} 
      { d.is_active === 1 ?
        <Tooltip title='Desactivar usuario' color="orange">
        <Button type="primary" className='btn-active' shape="circle" onClick={()=>activateUser(d.usuario_id) }  danger icon={<CheckOutlined />}/>
        </Tooltip>: 
          <Tooltip title='Activar usuario' color="orange">
            <Button type="primary" className='btn-bloqued' shape="circle"  onClick={()=> inactiveUser(d.usuario_id) }  danger icon={< MinusOutlined/>}/>   
          </Tooltip> 
        }</>
      })
    });
    /*
    if(userInfo.user_type_id === 2){
      const arrfilter = data.filter( e => e.municipio == userCityName);
      return setDataTable(arrfilter)
    }
    */
    setDataTable(data);
  }
  
  const result = useQueries({
    queries: [
      { queryKey: ['userCity'], queryFn: getCityUser, staleTime: Infinity, 
        onSuccess: (info) => setUserCityName(info)
      },
      { queryKey: ['totalUser'], queryFn: getAlluser, staleTime: Infinity,
        onSuccess: (info => validTypeUser(info))
      }
    ]
  });

    useEffect( () => {
      if(result[1].data){
     
        validTypeUser(result[1].data)
      }
    }, [refreshData] )

    const[Datacolumns] = useState([
      {
        title:"Nombre",
        dataIndex:"name",
        key:"name",
        esponsive: ['xs', 'lg']
      },
      {
        title:"Celular",
        dataIndex:"celular",
        key:"Celular",
      },
      {
        title:"Email",
        dataIndex:"email",
        key:"email",
      },
      {
        title:"Subregión",
        dataIndex:"subregion",
        key:"subregion",
      },
      {
        title:"Hospital Ejecutor ",
        dataIndex:"hejecutor",
        key:"hejecutor",
      },
      {
        title:"Municipio",
        dataIndex:"municipio",
        key:"municipio",
      },
      {
        title:"Hospital Municipal",
        dataIndex:"red",
        key:"red",
      },
      {
        title:"Cargo",
        dataIndex:"cargo",
        key:"cargo",
      },
      {
        title:"Equipo de Trabajo",
        dataIndex:"equipo",
        key:"equipo",
      },
      {
        title:"Acciones",
        dataIndex:"acciones",
        key:"acciones",
        esponsive: ['xs', 'lg']
      }
    ])
      // {title:"Total de visitas realizadas",dataIndex:"visitas",key:"visitas"},

    const activateUser = (id) => {
      patchRequestById(id,"users/enable").then(res=>{
        if(res['data']['success']){
            openNotificationWithIcon('success','Usuario desactivado','El usuario se desactivo correctamente')
           setRefreshData(true);
        }
      })
    }    
    const inactiveUser = (id) => {
      patchRequestById(id,"users/disable").then(res=>{
        if(res['data']['success']){
            openNotificationWithIcon('success','Usuario activado','El usuario se activo correctamente')
          setRefreshData(true);
        }
      })
    }    
    const handleClick = () => {
      const excel = new Excel();
      excel
        .addSheet("test")
        .addColumns(Datacolumns)
        .addDataSource(dataTable, {
          str2Percent: true
        })
        .saveAs("Excel.xlsx");
    };

    if( result.some(res => res.isLoading) ) return <Skeleton active />

    return (
    <Row>
      <Col lg={24}>
        <Title level={4}>Listado de usuarios </Title> 
        <Divider />
        <Space style={{ marginBottom: 16 }}>
        <Tooltip title='Crear nuevo usuario' color="orange">
            <Button  icon={<PlusOutlined />} onClick={()=>navigate('/panel/usuario/registro', { replace: true })}></Button>
          </Tooltip>  
        <Tooltip title='Administrar permisos' color="orange">
            <Button  icon={<ControlOutlined />} onClick={()=>navigate('/panel/permisos', { replace: true })}></Button>
          </Tooltip>  
            {/* <Button  icon={<FilePdfOutlined  />} danger></Button> */}
            <Tooltip title='Exportar usuarios' color="orange">
              <Button  icon={<FileExcelOutlined  />} className="btn-excel" onClick={handleClick}></Button>
            </Tooltip>
        </Space>
        <MyDataTable Datacolumns={Datacolumns} dataTable={dataTable} />
      </Col>
    </Row>
  )
}
