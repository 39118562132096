import { combineReducers } from 'redux';
import atencionList from './reducers/atencionList.reducer';
import atencionSelect from './reducers/atencionSelect.reducer';
import canalizacionHogar from './reducers/canalizacionHogar.reducer';
import historyReducer from './reducers/history.reducer';
import hogarReducer from './reducers/hogar.reducer';
import userReducer from './reducers/user.reducer';


export default combineReducers({
    user: userReducer,
    history: historyReducer,
    canalizacionHogar,
    hogarReducer,
    atencionList,
    atencionSelect
 })