import axios from "axios";
import { settings } from "./setting";

export const getCityUser =  async () => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/totalvisitasbyciudad?id=${userInfo.ciudad_id}`, {
        headers: {
            headers: {'Authorization': userInfo.token}
        }
    })
    return res.data.data.visitas[0].ciudad
}
export const getAlluser =  async () => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/users/all`, {
        headers: {
            headers: {'Authorization': userInfo.token}
        }
    })
    return res.data
}