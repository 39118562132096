import { Divider, Tree } from 'antd';
import React, { useEffect, useState } from 'react'
import { getUserData } from '../../helpers/Api';
//components
import CardsTrakingInfo from './CardsTrakingInfo';
import Riesgos from './Riesgos';
import Hogares from './Hogares';
import Mascotas from './Mascotas';
import Usuarios from './Usuarios';
import "./seguimiento.css"
import { connect } from 'react-redux';
import Vivienda from './Vivienda';
import TotalCard from './TotalCard';

const Seguimiento = (props) => {
    const [subregionId,setSUbregionId]= useState(props.user.subregion_id);
    const [seguimiento,setSeguimiento]= useState(null);
    const [cargo,setCargo]= useState(null);

    useEffect(()=>{
      setCargo(Number(getUserData()['user_type_id']))
      let cargo = Number(getUserData()['user_type_id']) ;
      let funciones = Number(getUserData()['funciones_id']);
      let subregion = Number(getUserData()['subregion_id']);
      if(cargo === 4 || cargo === 2){
        if(funciones === 1){
          setSeguimiento(subregion)
        }
      }  
    },[subregionId])

  return (
    <>
     
      <CardsTrakingInfo action={setSUbregionId} cargo={cargo} region={seguimiento}/>
      <Divider />
      <Usuarios region={subregionId} cargo={cargo}/>
      <div className='mb-6'>
        <Vivienda cargo={cargo} region={subregionId} funciones_id={props.user.funciones_id}/>
      </div>
      <Divider />
      <TotalCard region={subregionId} cargo={cargo} funciones_id={props.user.funciones_id}/>
      <div className='flex justify-between md:flex-row sm:flex-col'>
        <div className='md:w-7/12  sm:w-full rounded-md shadow-lg p-5  bg-white'>
          <Riesgos region={subregionId}/>
        </div>
        <div className='md:w-5/12 sm:w-full p-3 bg-white'>
        <div className='shadow-lg p-5 rounded-md'>
          <Mascotas region={subregionId}/>
        </div>
        </div>
      </div>
      <div className='w-full rounded-md shadow-lg p-5  bg-white mt-6 pt-8'>
        <Hogares  region={subregionId}/>
      </div>
    
    
    </>
  );
};

const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(Seguimiento)