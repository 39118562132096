import React, { useEffect, useState } from 'react'
import { SearchOutlined,FileExcelOutlined,PlusOutlined,EyeOutlined, UserAddOutlined} from '@ant-design/icons';
import { Button, Space,Tooltip , Drawer } from 'antd';
import {Col,Row} from 'antd';
// import TopNavigate from '../components/TopNavigate';
import { getRequestAll,patchRequestById } from '../helpers/Api';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { Excel } from "antd-table-saveas-excel";
import openNotificationWithIcon from '../helpers/Notification';
import MyDataTable from '../helpers/MyDataTable';
import HeaderTitle from '../components/HeaderTitle';
import HogaresRiesgos from '../components/HogaresRiesgos';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const HogarList = () => {
  const [dataTable,setDataTable]= useState([]);
  const [opendDrawe, setopendDrawe]= useState(false);
  const [exectRequest, setexectRequest]= useState(false);
  const [hogarIdSelect, seHogarIdSelect]= useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      limit: 10,
      outset:0
    },
  });



  const handleTableChange = (pagination, filters, sorter) => {
    pagination.outset = pagination.current * 10;
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    setexectRequest(false)

      // `dataSource` is useless since `pageSize` changed
      // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      //   setData([]);
      // }
  };


    const[Datacolumns] = useState([
    {title:"N°",dataIndex:"numero",key:"numero"},
    {title:"Acciones",dataIndex:"acciones",key:"acciones"},
    {title:"Subregión",dataIndex:"subregion",key:"subregion"},
    {title:"Municipio",dataIndex:"ciudad",key:"ciudad"},
    {title:"Ficha",dataIndex:"ficha",key:"ficha"},
    {title:"Dirección",dataIndex:"direccion",key:"direccion"},
    {title:"Dormitorios",dataIndex:"dormitorios",key:"dormitorios"},
    {title:"Personas",dataIndex:"personas",key:"personas"},
    {title:"Fecha de visita",dataIndex:"fecha",key:"fecha"}])

   
    const handleClick = () => {
      const excel = new Excel();
      excel
        .addSheet("test")
        .addColumns(Datacolumns)
        .addDataSource(dataTable, {
          str2Percent: true
        })
        .saveAs("Excel.xlsx");
    };

    const showHogarInfo =(id)=>{
      seHogarIdSelect(id);
      setopendDrawe(true)
    }
    useEffect(()=>{
      if(exectRequest == false){
      let data = [];
        getRequestAll(`hogar/all/?limit=${tableParams.pagination.limit}&outset=${tableParams.pagination.outset}`).then(res=>{
            if(res['success']){
              setexectRequest(true)
              let i =1;
                res['Hogar'].forEach(d => {
                  setTableParams({
                    ...tableParams,
                    pagination: {
                      ...tableParams.pagination,
                      // total: res['Hogar'].length,
                      total: 94526,
                    },
                  });
                    data.push({
                      key: i,
                      numero:i, 
                      acciones:<div className='flex justify-between'>
                        <Tooltip title='Crear Nuevo Integrante' color="orange">
                        <Button className='btn-detalle'  icon={<UserAddOutlined />} onClick={()=>navigate(`/panel/vivienda/integrante/registro/${d.hogar_id}/${d.uiid}`, { replace: true })}></Button>
                      </Tooltip>  
                     
                        <Tooltip title='Ver integrantes del hogar' color="orange">
                        <Button className='btn-detalle'  icon={<SearchOutlined />} onClick={()=>navigate(`/panel/vivienda/integrantes/${d.hogar_id}`, { replace: true })}></Button>
                      </Tooltip>  
                      <div className='mr-2'>
                        <Tooltip title='Ver informacion del hogar' color="orange">
                        
                          <Button className='btn-detalle'  icon={<EyeOutlined />} onClick={()=>showHogarInfo({ hogar_id: d.hogar_id, uiid: d.uiid })} />
                        </Tooltip>  
                      </div>
                      </div>,
                      subregion:d.subregion,
                      ciudad:d.ciudad,
                      ficha:d.numero_ficha,
                      direccion:d.direccion,
                      dormitorios:d.dormitorios,
                      personas:d.personas,
                      fecha:d.fecha_crea
                          
                    })
                    i++;
                });

                setDataTable(data)
            }
        })
      }
    },[exectRequest])

  return (
   <>
     
     <HeaderTitle title={"Hogares"}/>
     <Row>
        <Col lg={24}>
          <Space style={{ marginBottom: 16 }}>
          <Tooltip title='Crear nuevo hogar' color="orange">
              <Button  icon={<PlusOutlined />} onClick={()=>navigate('/panel/vivienda/registro', { replace: true })}></Button>
            </Tooltip>  
              {/* <Button  icon={<FilePdfOutlined  />} danger></Button> */}
              <Tooltip title='Exportar Hogar' color="orange">
                <Button  icon={<FileExcelOutlined  />} className="btn-excel" onClick={handleClick}></Button>
              </Tooltip>
          </Space>
          </Col>
    </Row>
    <MyDataTable Datacolumns={Datacolumns} dataTable={dataTable} tableParams={tableParams}  handleTableChange={handleTableChange} />
      <Drawer
        
        placement="right"
        width={"70%"}
        closable={true}
        onClose={ () => setopendDrawe(false) }
        open={opendDrawe}
        key="right"
      >
        <HogaresRiesgos hogar={hogarIdSelect}/>
      </Drawer>
      { exectRequest === false && <div className=' fixed top-0 left-0 w-full h-full bg-[#00000031] flex justify-center items-center '> 
      <div className='bg-white w-[100px] h-[100px] flex justify-center items-center rounded-full'>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />
      </div>
      </div> }
   </>
  )
}
