import React, {useEffect, useState} from 'react'
import { Table, Button , Popover, Drawer} from 'antd';
import { SearchOutlined , EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { usuariosCaracterizacionByMunicipio, canalizacionbyuserId } from '../Api/seguimiento';
import moment from 'moment';

const content = (
    <div>
      <p>Ver informacion de este usuario</p>
    </div>
  );

function TableUser(props) {
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openDrawerTwo, setopenDrawerTwo] = useState(false)

    const [dataSource, setdataSource] = useState([]);
    const [dataSourceTwo, setdataSourceTwo] = useState([]);
    const [nameSelect, setnameSelect] = useState("");
    const [columnsTwo, setcolumnsTwo] = useState([
        {
            title: 'Municipio',
            dataIndex: 'ciudad',
            key: 'ciudad',
            responsive: ['md', 'xs']
        },
        {
        title: 'Nombre',
        dataIndex: 'title',
        key: 'title',
        responsive: ['md', 'xs']
        },
        {
        title: 'Identificacion',
        dataIndex: 'id',
        key: 'id',
        responsive: ['md']    
        },
        {
        title: 'celular',
        dataIndex: 'celular',
        key: 'celular',
        responsive: ['md']
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            responsive: ['md', 'sm']
        },
        {
            title: 'total_visitas',
            dataIndex: 'total_visitas',
            key: 'total_visitas',
            responsive: ['md', 'sm']
        },
        
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            key: 'acciones',
            responsive: ['md', 'xs']
        },
    
    ])
    const [columns, setcolumns] = useState([
        {
          title: 'Nombre',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Identificacion',
          dataIndex: 'id',
          key: 'id',
          responsive: ['md']    
        },
        {
          title: 'celular',
          dataIndex: 'celular',
          key: 'celular',
          responsive: ['md']
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            key: 'cargo',
            responsive: ['md']
        },
        {
            title: 'Hospital',
            dataIndex: 'nombre_red',
            key: 'nombre_red',
            responsive: ['md']
        },
        {
            title: 'Equipo',
            dataIndex: 'equipo',
            key: 'equipo',
        },
        {
            title: 'visitas',
            dataIndex: 'visitas_realizadas',
            key: 'visitas_realizadas',
        },
        {
            title: 'Integrantes',
            dataIndex: 'integrantes',
            key: 'integrantes',
            responsive: ['md']
        },
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            key: 'acciones',
            responsive: ['md']
        },
    ])
   
   
    const getRegion = async (id) => {
        try {
            const res = await usuariosCaracterizacionByMunicipio( { queryKey: ['', id] } );
           
            let array = res.data.usuarios.map( i => ( {
                title: i.nombre_completo,
                id: i.identificacion,
                celular: i.celular,
                cargo: i.cargo,
                nombre_red: i.nombre_red,
                equipo: i.equipo,
                visitas_realizadas: i.visitas_realizadas,
                integrantes: i.integrantes_registrados,
                email: i.email,
                ciudad: i.ciudad,
                user: i.usuario_id,
                isActive: i.is_active,
                hogar: i.hogar_id,
                acciones:  <Popover content={content}>
                    <Button className='bg-blue text-white' shape="circle"
                        onClick={()=>navigate(`/panel/canalizacion/gestion/hogar/${i.usuario_id}`, { replace: true })}
                        icon={<SearchOutlined />}
                    />
                </Popover>
            } ) );
            setcolumns([
                {
                  title: 'Nombre',
                  dataIndex: 'title',
                  key: 'title',
                },
                {
                  title: 'Identificacion',
                  dataIndex: 'id',
                  key: 'id',
                  responsive: ['md']    
                },
                {
                  title: 'celular',
                  dataIndex: 'celular',
                  key: 'celular',
                  responsive: ['md']
                },
                {
                    title: 'Cargo',
                    dataIndex: 'cargo',
                    key: 'cargo',
                    responsive: ['md']
                },
                {
                    title: 'Hospital',
                    dataIndex: 'nombre_red',
                    key: 'nombre_red',
                    responsive: ['md']
                },
                {
                    title: 'Equipo',
                    dataIndex: 'equipo',
                    key: 'equipo',
                },
                {
                    title: 'visitas',
                    dataIndex: 'visitas_realizadas',
                    key: 'visitas_realizadas',
                },
                {
                    title: 'Integrantes',
                    dataIndex: 'integrantes',
                    key: 'integrantes',
                    responsive: ['md']
                },
                {
                    title: 'Acciones',
                    dataIndex: 'acciones',
                    key: 'acciones',
                    responsive: ['md']
                },
            ])
            setdataSourceTwo(array)
        } catch (error) {
                console.error('eroor ->', error);
        }
    }
    const getInfoVis = async (usuario_id, name) => {
        setopenDrawerTwo(true); 
        setnameSelect(name)
        const id = await canalizacionbyuserId(usuario_id);
        let arra = id.data.Canalizaciones.map(i => {
            return(
                    {
                        ficha: i.ficha,
                        observacion: i.observacion,
                        cumplimiento: i.cumplimiento,
                        fecha_gestion: moment(i.fecha_gestion).format('l'),
                    }
                )
        })
            
    
      
       let arr = [
            {
                title: 'ficha',
                dataIndex: 'ficha',
                key: 'ficha',
            },
            {
                title: 'observacion',
                dataIndex: 'observacion',
                key: 'observacion',
            },
            {
                title: 'cumplimiento',
                dataIndex: 'cumplimiento',
                key: 'cumplimiento',
            },
          
            {
                title: 'fecha de gestion',
                dataIndex: 'fecha_gestion',
                key: 'fecha_gestion',
            }
       ]
       setcolumns(arr);
       setdataSourceTwo(arra);
       
    }
    useEffect( ()=> {
        if(props.user_type_id === 8 || props.user_type_id === 3){
           
            let array = props.list.map( i =>  {
                if(i.total_visitas){
                }else{
                    let colNew = columnsTwo.map( i => {
                        if(i.key === 'total_visitas'){
                            i.title = 'Total visitas'
                        }
                        return i
                    });
                    
                    setcolumnsTwo(colNew);
                }
                return {
                    title: i.nombre_completo,
                    id: i.identificacion,
                    celular: i.celular,
                    email: i.email,
                    ciudad:  i.subregion ?  i.subregion : i.ciudad,
                    total_visitas: i.total_visitas ? i.total_visitas : i.total_canalizaciones,
                    acciones: <div className='flex'>
                        <Popover content={content} className="mx-2">
                            <Button className='bg-blue text-white' shape="circle"
                                onClick={()=> {
                                        //   navigate(`/panel/canalizacion/gestion/hogar/${i.usuario_id}`, { replace: true });
                                        setdataSourceTwo([]);
                                        setOpenDrawer(true); 
                                        getRegion(i.ciudad_id ? i.ciudad_id : i.subregiones_id) 
                                    }
                                }
                                icon={<SearchOutlined />}
                            />
                        </Popover>
                        {props.user_type_id === 8 ||  props.user_type_id === 3 || props.funcion === 1 ?
                            <Button className='bg-blue text-white mr-3' shape="circle"
                                onClick={()=> {
                                        //   navigate(`/panel/canalizacion/gestion/hogar/${i.usuario_id}`, { replace: true });
                                    // setdataSourceTwo([]);
                                    
                                        getInfoVis(i.usuario_id,  i.nombre_completo) 
                                    }
                                }
                                icon={<EyeOutlined />}
                            />
                         : null}
                        
                    </div>
                    } 
            }  )
            setdataSource(array)

        }else{
            let array = props.list.map( i => ( {
                title: i.nombre_completo,
                id: i.identificacion,
                celular: i.celular,
                cargo: i.cargo,
                nombre_red: i.nombre_red,
                equipo: i.equipo,
                visitas_realizadas: i.visitas_realizadas,
                integrantes: i.integrantes_registrados,
                email: i.email,
                ciudad: i.ciudad,
                user: i.usuario_id,
                isActive: i.is_active,
                hogar: i.hogar_id,
                acciones:  <Popover content={content}>
                    <Button className='bg-blue text-white' shape="circle"
                        onClick={()=>navigate(`/panel/canalizacion/gestion/hogar/${i.usuario_id}`, { replace: true })}
                        icon={<SearchOutlined />}
                    />
                </Popover>
            } ) )
            setdataSource(array)
        }
    }, [] );



    return (
        <div>
            <div className='mb-8 border-b pb-3'>
                <h2  className='text-2xl font-bold text-gray5 uppercase'>
                    { props.user_type_id === 8 ||  props.user_type_id === 3 ? "Equipo pic Coordinadores municipales" : "Equipo pic auxiliar de enfermería"}
                </h2>
            </div>
            <Table dataSource={dataSource} columns={ props.user_type_id === 8 ||  props.user_type_id === 3 ?  columnsTwo  : columns } />
            <Drawer
                placement="right"
                size="large"
                width={1180}
                closable={true}
                onClose={() => setOpenDrawer(false) }
                open={openDrawer}
                key="right"
            >
            { dataSourceTwo.length === 0 ? 
                <p>No hay informacion</p>
            : 
            <>
            <div className='mb-8 border-b pb-3'>
                <h2  className='text-2xl font-bold text-gray5 uppercase'>
                Equipo pic auxiliar de enfermería
                </h2>
            </div>
                <Table dataSource={dataSourceTwo} columns={columns} />
            </>
               
            }
              
            </Drawer>
            <Drawer 
                placement="right"
                size="large"
                width={1180}
                closable={true}
                onClose={() => setopenDrawerTwo(false) }
                open={openDrawerTwo}
                key="right"
            >
                { dataSourceTwo.length === 0 ? 
                    <p>No hay informacion</p>
                : 
                <>
                <div className='mb-8 border-b pb-3'>
                    <h2  className='text-2xl font-bold text-gray5 uppercase'>
                        Canalizaciones de {nameSelect}
                    </h2>
                </div>
                    <Table dataSource={dataSourceTwo} columns={columns} />
                </>
                
            }
              
            </Drawer>
        </div>
    )
}

export default TableUser