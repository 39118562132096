import { Col, Divider, Row,Typography} from 'antd'
import React from 'react'
const { Title } = Typography;
const HeaderTitle = ({title}) => {
  return (
    <>
    <Row>
        <Col lg={24}>
        <Title level={4}>{title}</Title> 
    </Col>
    </Row>    
    </>
  )
}

export default HeaderTitle