

import React , { useState, useEffect } from 'react';
import { getLocationCity } from '../Api/vivienda';
import { Select } from 'antd';

function RiesgosBarrios({ info, city, map, totalData }) {
    let [listRiesgos, setListRiesgos] = useState([])
   

    useEffect( () => {
        
        let filtroPorCiudad = totalData.filter( item => item.ciudad === city );

        let objArray = [];

        filtroPorCiudad.forEach( i => {
           
            let filt = objArray.find( item => item.barrio === i.barrio  )
            if(filt){
                let riesgoFind =  filt.riesgos.find( reisgo => reisgo.reisgo === i.riesgo);
                if(riesgoFind) riesgoFind.riesgos.cantidad = riesgoFind.riesgos.cantidad + i.cantidad;
            }else{
                objArray.push({
                    barrio: i .barrio,
                    riesgos: [  {
                        reisgo: i.riesgo,
                        cantidad: i.cantidad
                    } ]
                })
            }
        } );
        setListRiesgos(objArray)
        //setListRiesgos(objArray)
    }, [] )

    return (
        <div className='grid grid-cols-2 gap-3'>
            { listRiesgos &&
                listRiesgos.map(( i, index )=> <div key={index.toString()} className="shadow-md p-2 rounded cursor-pointer hover:scale-105 ease-out duration-200">
                <h5><b>Barrio:</b> <b className='text-orange'>{i.barrio}</b></h5>
                <h5><b>Riesgo:</b> {i.riesgos[0].reisgo}</h5>
                <h5><b>Cantidad:</b><span className='text-orange font-bold'> {i.riesgos[0].cantidad}</span></h5>
            </div> )
            }
        </div>
    )
}

export default RiesgosBarrios