
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ProtectedRoute } from '../components/ProtectedRoute'
import { getUserData, setUserData } from '../helpers/Api'
import Canalizacion from '../pages/Canalizacion'
import Deteccion from '../pages/Deteccion'
import GestionarCanalizacion from '../pages/GestionarCanalizacion'
import { HogarList } from '../pages/HogarList'
import { HogarListCanalizacion } from '../pages/HogarListCanalizacion'
import  IdentificacionHogar  from '../pages/IdentificacionHogar'
import { IdentificacionIntegrantes } from '../pages/IdentificacionIntegrantes'
import  IntegrantesList  from '../pages/IntegrantesList'
import Login from '../pages/Login'
import NotificarCambio from '../pages/NotificarCambio'
import Principal from '../pages/Principal'
import RecuperarPass from '../pages/RecuperarPass'
import { RedPrestadora } from '../pages/RedPrestadora'
import RegistroUsuario from '../pages/RegistroUsuario'
import Seguimiento from '../pages/Seguimiento'
import { UserList } from '../pages/UserList'
import Inicio from './Inicio';
import {userAction} from '../redux/actions/user.action';

import { historyAction } from '../redux/actions/history.action'
import Mapa from '../components/Mapa'
import IdentificadorRiesgos from '../pages/IdentificadorRiesgos'
import CreatePerson from '../pages/IdentificadorRiesgos/CreatePerson';
import ListaIntegrantes from '../pages/IdentificadorRiesgos/ListaIntegrantes'
import Mapas from '../pages/Mapas'


const PrincipalRouter = (props) => {
  const [user,setUser] = useState(null)

  const valid = () => {
    let user = localStorage.getItem('user');
    if( user === null) return console.error("error");
    const userInfo = JSON.parse(user);
    props.userAction(userInfo);
    

  }

  const saveHistory = () => {
    const letStorage = sessionStorage.getItem("history");
    if(letStorage){
      let ActualStora = JSON.parse(letStorage);
      ActualStora.push(window.location.pathname);
      const mySet1 = new Set(ActualStora);
      sessionStorage.setItem("history", JSON.stringify([...mySet1]));
      return [...mySet1]
    }else{
      let url = window.location.pathname;
      sessionStorage.setItem("history", JSON.stringify([url]));
    }
    return []
  }
  useEffect( () => {
    valid();
  }, [] )

  
  const routers = createBrowserRouter([
    {
      path: "/",
      element: <Login setUser={setUser} />,
      //loader: rootLoader,
    },
    {
      path: "/notificar",
      element: <NotificarCambio/>,
      //loader: rootLoader,
    },
    {
      path: "/recuperar",
      element: <RecuperarPass />,
      //loader: rootLoader,
    },
    {
      path: "*",
      element: <Login />,
    },
    {
      path: "/panel",
      element: <ProtectedRoute user={user || getUserData()}>
      <Principal  />
    </ProtectedRoute>,
      loader: async () => {
        
        saveHistory()
      },
      children: [
        {
          path: "/panel",
          element: <Inicio />
        },
        {
          path: "/panel/principal",
          element: <Inicio />
        },
        {
          path: "usuario",
          element: <UserList />
        },
        {
          path: "usuario/registro",
          element: <RegistroUsuario isUpdate = {false} />
        },
        {
          path: "usuario/:id",
          element: <RegistroUsuario isUpdate = {true} />
        },
        {
          path: "usuario/:id",
          element: <HogarListCanalizacion />
        },
        {
          path: "vivienda",
          element: <HogarList />
        },
        {
          path: "canalizacion/gestion/hogar/:usuario_id",
          element: <HogarListCanalizacion />
        },
        {
          path: "vivienda/registro",
          element: <IdentificacionHogar/>
        },
        {
          path: "deteccion",
          element: <Deteccion/>
        },
        {
          path: "canalizacion",
          element: <Canalizacion/>
        },
        {
          path: "canalizacion/gestion/hogar/:alerta_hogar_id/:id_hogar/:documento/:ficha/:alerta_id/:data/:direccion",
          element: <GestionarCanalizacion is_integrante ={false}/>
        },
        {
          path: "canalizacion/gestion/integrante/:documento/:ficha/:integrante_alertas_id/:integrante_id/:alerta_id/:data/:direccion",
          element: <GestionarCanalizacion is_integrante ={true}/>
        },
        {
          path: "redes",
          element: <RedPrestadora/>
        },
        {
          path: "vivienda/integrantes/:id",
          element: <IntegrantesList/>
        },
        {
          path: "vivienda/integrante/update/:id",
          element: <IdentificacionIntegrantes isUpdate={true}/>
        },
        {
          path: "vivienda/integrante/registro/:id/:uiid",
          element: <IdentificacionIntegrantes isUpdate={false}/>
        },
        {
          path: "seguimiento",
          element: <Seguimiento/>
        },
        {
          path: "mapa",
          element: <Mapa/>
        },
        {
          path: 'identificadorRiesgos',
          element: <IdentificadorRiesgos />
        },
        {
          path: 'identificadorRiesgos/user/:type/:edit',
          element: <CreatePerson />
        },
        {
          path: 'identificadorRiesgos/user/list/:uuid',
          element: <ListaIntegrantes />
        },
        {
          path: 'identificadorRiesgos/user/:type/:uuid/:edit',
          element: <CreatePerson />
        },
        {
          path: 'mapas',
          element: <Mapas />
        }
        
      ]
    }
  ]);

  return (
    <>
      <RouterProvider router={routers} />    
    </>
  )
}

const mapStateToProps = state => state;
export default connect(mapStateToProps, { userAction, historyAction })(PrincipalRouter) 