import './map.css';
import React, {useState, useRef, useEffect} from 'react'
import {municipios} from '../Api/municipios';
import { getDepartamentData, mascotas, riesgoibysubregion, hogares } from '../Api/seguimiento';
import { getEntorno, geTipoVivienda, getTotalViviendas, getRiesgosPorBarrio } from '../Api/vivienda'
import { Skeleton, message, Collapse, Select } from 'antd';


import InitialMap from '../pages/Mapas/InitialMap';
import RiesgosBarrios from './RiesgosBarrios';

function Mapa() {

    const map = useRef(null);

    const [infoVisitas, setInfoVisitas] = useState(null);
    const [tenenciaInfo, seTenenciaInfo] = useState(null);
    const [tipoVivienda, seTipoVivienda] = useState(null);
    const [mascotasInfo, setMascotasInfo] = useState(null);
    const [totalVivienda, seTtotalVivienda] = useState(null);
    const [listRiesgos, setListRiesgos] = useState(null);
    const [listRiesgosHogar, setListRiesgosHogar] = useState(null);
    const [barriosRiesgos, setBarriosRiesgos] = useState([]);
    const [citySelect, setcitySelect] = useState('');
    const [listTotalRiesgoInfo, setlistTotalRiesgoInfo] = useState(null);

    const [loading, setLoading] = useState(false);
    
    const getinfo = (array, item, count, ciudad) => {
        let totals = {};
        array.forEach(e => {
            if(e.ciudad.toLowerCase() === ciudad.toLowerCase()  ){
                totals[e[item]] =  totals[e[item]] ? (totals[e[item]] + e[count]) : e[count];
            }
        });
        return totals
    }
    async function capturando(city){
        try {
            setLoading(true)
            const municipiosList = municipios.filter( e => e.ciudad === city);
            setcitySelect(city);

            if(municipiosList.length){
             
                const {ciudad_id, subregiones_id, ciudad, coord} = municipiosList[0];

               
                if(map.current.getSource('dot-point') ){
                    await map.current.removeLayer('layer-with-pulsing-dot');
                    await map.current.removeSource('dot-point');
                }
                await map.current.addSource('dot-point', {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [
                            { 
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [coord.long, coord.lat] 
                                }
                            }
                        ]
                    }
                });

                await map.current.addLayer({
                    'id': 'layer-with-pulsing-dot',
                    'type': 'symbol',
                    'source': 'dot-point',
                    'layout': {
                        'icon-image': 'pulsing-dot'
                    }
                });

                const res = await getDepartamentData({
                    queryKey: ['', ciudad_id]
                });

                map.current.flyTo({ center: [coord.long, coord.lat] , zoom: 14 });
                await getEntorno({ queryKey: ['', subregiones_id]})
                .then(data => {
                    const entornos = getinfo(data.data.tenencia, 'tenencia_descripcion', 'cantidad_tenencia', ciudad);
                    if(Object.keys(entornos).length >= 1){
                        seTenenciaInfo(entornos);
                    }else{
                        seTenenciaInfo(null);
                    }
                    
            
                }).catch( error => console.error(error))
                
                await  geTipoVivienda({ queryKey: ['', subregiones_id] })
                .then(data => {
                    const seteData = getinfo(data.data.tipo, 'vivienda_tipo_descripcion', 'cantidad', ciudad);
                    if(Object.keys(seteData).length >= 1){
                        seTipoVivienda(seteData)
                    }else{
                        seTipoVivienda(null)
                    }
                    
                }).catch( error => console.error(error));

                await mascotas({ queryKey: ['', subregiones_id] })
                .then(data => {
                    const listMascota = data.data.find( i => i.ciudad.toLowerCase() === ciudad.toLowerCase())
                    if(listMascota){
                        setMascotasInfo(listMascota)
                    }else{
                        setMascotasInfo(null)
                    }
                }).catch( error => console.error(error));

                await getTotalViviendas({
                    queryKey: ['', subregiones_id]
                }).then(data => {

                   const total = data.data.total.find( i => i.ciudad.toLowerCase() === ciudad.toLowerCase())
                    if(total){
                        seTtotalVivienda(total);
                    }else{
                        seTtotalVivienda(null)
                    }
                }).catch( error => console.error(error));

                await riesgoibysubregion({
                    queryKey: ['', subregiones_id]
                }).then(data => {
                    let filters = data.data.filter( i => i.ciudad.toLowerCase() === ciudad.toLowerCase()  )
                    if(filters.length){
                        setListRiesgos(filters)
                    }else{
                        setListRiesgos(null)
                    }
                }).catch( error => console.error(error));
                

                await hogares({
                    queryKey: ['', subregiones_id]
                }).then(data => {
                    let filters = data.data.filter( i => i.ciudad.toLowerCase() === ciudad.toLowerCase()  )
                    if(filters.length){
                        setListRiesgosHogar(filters)
                    }else{
                        setListRiesgosHogar(null)
                    }
                }).catch( error => console.error(error));

                setInfoVisitas(res.data.visitas[0]);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false);
            message.error('ho nay resultados');
            console.error(error)
        }
        
    }

    const getBarrioData = async () => {

        try {
            const infoBarrios = await getRiesgosPorBarrio();

            let listRiesgos = infoBarrios.map( i => i.riesgo);
            const mySet1 = new Set(listRiesgos);
            setBarriosRiesgos([...mySet1])
            setlistTotalRiesgoInfo(infoBarrios)
        } catch (error) {
            console.error(error) 
        }

    };

    useEffect( () => {
        getBarrioData()
    }, [] );

   
    return (
        <div className='containerMap flex md:flex-row sm:flex-col-reverse justify-center items-start h-full'>
            <div className='md:w-[45%] sm:w-[100%] containMap  h-full'>
                <InitialMap refMap={map} capturando={capturando}/>
            </div>
            <div className='p-3 md:w-[51%] sm:w-[98%] sm:pt-10'>
                <div>
                    <h3 className=' text-orange text-lg font-semibold mb-2'>Selecciona una ciudad</h3>
                    <Select
                        className='w-full mb-2 ]'
                        showSearch
                        placeholder="Selecciona una ubicacion"
                        optionFilterProp="children"
                        onChange={capturando}
                        filterOption={(input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={municipios.map( i => ( { value: i.ciudad , label: i.ciudad}) )}
                    />
                    
                </div>
                
                { loading  === true ? 
                    <Skeleton  active/>
                : 
                    infoVisitas != null 
                    ?
                    <>
                    
                        <div className='shadow-md p-4 rounded-lg md:w-[100%] sm:w-[100%]'>
                       
                            <h2 className='text-orange font-bold text-3xl'><span className='text-black'>{infoVisitas.ciudad} - </span>{ infoVisitas.total ? parseInt(infoVisitas.total).toLocaleString('es-MX') : '0'} </h2>
                            { totalVivienda && <div className='mt-4'>
                                <h4 className='f font-semibold text-[18px] '>Total viviendas: <b>{ totalVivienda.cantidad ? parseInt(totalVivienda.cantidad).toLocaleString('es-MX'): '0'}</b> </h4></div> }
                            <div className="grid grid-cols-2 gap-2 mb-6">
                                
                                { tipoVivienda && <div className='mt-4'>
                                    <h4 className='f font-semibold text-[18px] '>Tipos de vivienda</h4>
                                    <ul>
                                        <li>Casa: <b>  { tipoVivienda.Casa ? parseInt(tipoVivienda.Casa).toLocaleString('es-MX') : '0' }</b></li>
                                        <li>Apartamento: <b>{ tipoVivienda.Apartamento ? parseInt(tipoVivienda.Apartamento).toLocaleString('es-MX') : '0' }</b></li>
                                        <li>Carpa: <b>{ tipoVivienda.Carpa ? parseInt(tipoVivienda.Carpa ).toLocaleString('es-MX') : 0}</b></li>
                                        <li>Otro/cual: <b>{ tipoVivienda['Otro/cual'] ? parseInt( tipoVivienda['Otro/cual']).toLocaleString('es-MX') : 0}</b></li>
                                        <li>Pieza: <b>{ tipoVivienda.Pieza ? parseInt(tipoVivienda.Pieza).toLocaleString('es-MX') : '0'}</b></li>
                                        <li>Refugio: <b>{ tipoVivienda.Refugio ? parseInt(tipoVivienda.Refugio).toLocaleString('es-MX') : '0' }</b></li>
                                        <li>Tienda: <b>{tipoVivienda.Tienda ? parseInt(tipoVivienda.Tienda).toLocaleString('es-MX') : '0'}</b></li>
                                    </ul>
                                </div> }

                                { tenenciaInfo && <div className='mt-4'>
                                    <h4 className='f font-semibold text-[18px] '>Tenecia </h4>
                                    <ul>
                                        <li>Arriendo: <b>{tenenciaInfo.Arriendo ? parseInt(tenenciaInfo.Arriendo).toLocaleString('es-MX') : '0'}</b></li>
                                        <li>Propia: <b>{tenenciaInfo.Propia ? parseInt(tenenciaInfo.Propia).toLocaleString('es-MX') : '0'}</b></li>
                                        <li>Otro: <b>{tenenciaInfo.Otro ? parseInt(tenenciaInfo.Otro).toLocaleString('es-MX') : '0'}</b></li>
                                        <li>Propia Pagando: <b>{tenenciaInfo['Propia Pagando'] ? parseInt(tenenciaInfo['Propia Pagando']).toLocaleString('es-MX') : '0'}</b></li>
                                        <li>Subarriendo: <b>{tenenciaInfo.Subarriendo ? parseInt(tenenciaInfo.Subarriendo).toLocaleString('es-MX') : '0'}</b></li>
                                    </ul>
                                </div> }

                                { mascotasInfo && <div className='mt-4'>
                                    <h4 className='f font-semibold text-[18px] '>Mascotas</h4>
                                    <ul>
                                        <li>Gatos: <b>{mascotasInfo.cantidad_gatos ? parseInt( mascotasInfo.cantidad_gatos).toLocaleString('es-MX') : '0'}</b> / vacunados: <b>{ mascotasInfo.gatos_vacunados ? parseInt(mascotasInfo.gatos_vacunados).toLocaleString('es-MX') : '0'}</b></li>
                                        <li>Perros: <b>{mascotasInfo.cantidad_perros ? parseInt(mascotasInfo.cantidad_perros).toLocaleString('es-MX') : '0'}</b> / vacunados: <b>{ mascotasInfo.perros_vacunados ?parseInt(mascotasInfo.perros_vacunados).toLocaleString('es-MX') : '0'}</b></li>
                                        <li>Otros: <b>{mascotasInfo.cantidad_otros ? parseInt(mascotasInfo.cantidad_otros).toLocaleString('es-MX') : '0'}</b></li>
                                    </ul>
                                </div> }
                            </div>
                            

                            <Collapse >
                              
                                { listRiesgos && 
                                    <Collapse.Panel header="Lista de riesgos" key="1">
                                        <div className="grid grid-cols-2 gap-2">
                                            { listRiesgos.map( ( i, index ) => <div key={index.toString()} className="p-2 bg-gray1 rounded">
                                                <h3><b className='text-gray4'>Tipo riesgo: </b> <span className='text-orange'> {i.tipo_riesgo}</span></h3>
                                                <h3><b className='text-gray4'>Cantidad:</b> <b>{ i.cantidad ? parseInt(i.cantidad).toLocaleString('es-MX'): '0'}</b></h3> 
                                                <h3><b className='text-gray4'>Descripcion:</b> {i.descripcion_riesgo}</h3>
                                                
                                            </div> ) }
                                        </div>
                                    </Collapse.Panel>
                                }
                                <Collapse.Panel header="Riesgos por barrio" key="2">
                                    <RiesgosBarrios info={barriosRiesgos}  city={citySelect} map={map} totalData={listTotalRiesgoInfo} />
                                </Collapse.Panel>

                                { listRiesgosHogar && 
                                    <Collapse.Panel header="Riesgos por hogares" key="3">
                                        <div className="grid grid-cols-2 gap-2">
                                            { listRiesgosHogar.map( ( i, index ) => <div key={index.toString()} className="p-2 bg-gray1 rounded">
                                                <h3><b className='text-gray4'>Tipo riesgo: </b> <span className='text-orange'> {i.riesog_tipo_descripcion}</span></h3>
                                                <h3><b className='text-gray4'>Cantidad:</b> <b>{ i.cantidad ? parseInt(i.cantidad).toLocaleString('es-MX') : '0'}</b></h3> 
                                                <h3><b className='text-gray4'>Descripcion:</b> {i.riesgo}</h3>
                                                
                                            </div> ) }
                                        </div>
                                    </Collapse.Panel>
                                }
                            </Collapse>
                            
                        </div>
                       
                    </>
                    : 
                    null
                }
                
            </div>
        </div>
    )
}



export default Mapa;
