import {LISTATENTIONS} from '../types';

const INITIAL_STATE = null;

const atencionList = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case LISTATENTIONS :
            return  action.payload;
        default: return state
    }
};

export default atencionList;