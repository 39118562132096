import React, { useRef } from 'react';
import InitialMap from './InitialMap';

function Mapas(props) {

  const map = useRef(null);
  return (
    <InitialMap refMap={map} capturando={props.capturando}/>
  )
}

export default Mapas;