import axios from 'axios';
export const Api = () => {
  // return "http://localhost:3000";
  return "https://appmagdalena.net";
}

export const setUserData = (data) => {
  localStorage.setItem('user',JSON.stringify(data))
}

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem('user'));
  return userData.token;
}
export const getUserData = () => {
  return JSON.parse(localStorage.getItem('user'))
}

export const removeUserData = ()=>{
  localStorage.removeItem('user')
}

export const getRequestById = async (id,url) => {
  const token = getToken();
  let baseUrl = Api();
  try {
     const response = await axios.get(`${baseUrl}/${url}?id=${id}`, 
      {headers: {'Authorization': token}}
     );
     return response['data'];
   } catch (error) {
    const {data} = error['response'];
    return {data:{data:data['error']}};
   }
}

export const getRequestIdParams = async (id,alertaid,url) => {
  // const token = getToken();
  let baseUrl = Api();
  try {
     const response = await axios.get(`${baseUrl}/${url}?id=${id}&alertaid=${alertaid}`, 
    //  {headers: {'Authorization': token}}
     );
     return response['data'];
   } catch (error) {
    const {data} = error['response'];
    return {data:{data:data['error']}};
   }
}

export const getRequestAll = async (url) =>{

  let baseUrl = Api();
  let token = getUserData()['token'];

 try {
   let response = await axios.get(`${baseUrl}/${url}`, 
   {headers: {'Authorization': token}});
   return response['data'];
 } catch (error) {
  const {data} = error['response'];
  return {data:{data:data['error']}};
 }
}

export const postRequest = async (url,body) =>{
  let baseUrl = Api();
   try {
      let token = getUserData()['token'];
     const response = await axios.post(`${baseUrl}/${url}`,body,
     {headers: {'Authorization': token}}
     );
     return response['data']
   } catch (error) {
    const {data} = error['response'];
    return {data:{data:data['error']}};
   }
}
export const LoginPost = async (url,body) =>{
  let baseUrl = Api();
   try {
     const response = await axios.post(`${baseUrl}/${url}`,body,
     );
     return response['data']
   } catch (error) {
    const {data} = error['response'];
    return {data:{data:data['error']}};
   }
}
export const Post = async (url,body) =>{
  let baseUrl = Api();
   try {
     const response = await axios.post(`${baseUrl}/${url}`,body,
     );
     return response['data']
   } catch (error) {
    const {data} = error['response'];
    return {data:{data:data['error']}};
   }
}
export const AuthJwtUser = async ()=>{
  try {
    let token = getUserData()['token'];
     return await axios.get("/auth",{headers: {'Authorization': token}});
  } catch (error) {
    return false;
  }
}

export const patchRequestById = async (id,url) =>{
  let baseUrl = Api();
   // const token = getToken();
   try {
     // console.log(user.centropoblado,"USER====================")
     //  return await axios.post('http://localhost:3000/users/',user, 
     return await axios.post(`${baseUrl}/${url}`,{id} 
    // {headers: {'Authorization': token}}
    );
  } catch (error) {
   const {data} = error['response'];
   return {data:{data:data['error']}};
  }
}
