import { useQuery } from '@tanstack/react-query'
import { Skeleton, Collapse } from 'antd';
import React from 'react';
import { getEntorno } from '../../../Api/vivienda';
import { CaretRightOutlined } from '@ant-design/icons';
import { useState } from 'react';
const { Panel } = Collapse;

function Tenecia({region, cargo, funciones_id}) {

  const [info, setInfo] = useState([]);
  const [totalInfo, seTotalInfo] = useState([])
  const {data, status} = useQuery({
    queryKey: [`tenecia`, region], 
    queryFn: getEntorno,
    onSuccess: (info => {
      let myArray = []
      let totals = {}
      info.data.tenencia.forEach(e => {

        totals[e.tenencia_descripcion] =  totals[e.tenencia_descripcion] ? (totals[e.tenencia_descripcion] + e.cantidad_tenencia) :  e.cantidad_tenencia
        let filt = myArray.find((x) => e.ciudad == x.ciudad);
        if(filt){
          filt.data.push(e);
        }else{
          myArray.push({
            ciudad: e.ciudad,
            data: [e]
          });
        }
      });
      setInfo(myArray);
      seTotalInfo(totals);
    })
  });

  if(status === "loading") return <Skeleton active />
  if(status === "error")  return <p>Ups...</p>

  return (
    <div className='shadow-md p-4 rounded-md'>
      <div>
        <h2  className='text-xl font-bold mb-3 uppercase'>Tenencia de vivienda</h2>
        { cargo === 8  || cargo === 3  || funciones_id === 2 ?
        <>
          <ul>
            <li className='text-[16px]'>Arriendos: <b className='text-orange ml-3'>{ totalInfo.Arriendo }</b></li>
            <li className='text-[16px]'>Propia: <b className='text-orange ml-3'>{ totalInfo.Propia }</b></li>
            <li className='text-[16px]'>Propia Pagando: <b className='text-orange ml-3'>{ totalInfo['Propia Pagando'] }</b></li>
            <li className='text-[16px]'>Subarriendo:  <b className='text-orange ml-3'>{ totalInfo.Subarriendo }</b></li>
            <li className='text-[16px]'>Otro: <b className='text-orange ml-3'>{ totalInfo.Otro }</b></li>
          </ul>

 
        </>
        :
        <Collapse
            bordered={false}
            ghost
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          > 
          {info.map( (i, index) => {
            return <Panel header={i.ciudad} key={index} className="site-collapse-custom-panel">
              <ul>
                {
                  i.data.map((e, key) => (
                    <li key={key}>{e.tenencia_descripcion}: {e.cantidad_tenencia}</li>
                  ))
                }
              </ul>
              
            </Panel>
          } )}
        </Collapse>  
        }
      </div>
      
    </div>
  )
}

export default  Tenecia