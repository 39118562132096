import React, { useEffect, useState } from 'react'
import { FileExcelOutlined,ArrowLeftOutlined, EditFilled } from '@ant-design/icons';
import { Select,Form } from 'antd';
import { Button, Space,Tooltip ,Typography,Divider } from 'antd';
import {Col,Row} from 'antd';

import {getRequestAll} from '../helpers/Api';
import { useNavigate,useParams } from 'react-router-dom';
import { Excel } from "antd-table-saveas-excel";
import MyDataTable from '../helpers/MyDataTable';
import { connect } from 'react-redux';

const IntegrantesList = (props) => {
  const [dataTable,setDataTable]= useState([]);
  const [hogarId,sethogarId]= useState(null);
  const navigate = useNavigate();


  const {Item} = Form;
  const { Option } = Select;
  const { Title } = Typography;
  const params = useParams();

    const[Datacolumns] = useState([
    {title:"Ficha",dataIndex:"numero_ficha",key:"numero_ficha"},
    {title:"Tipo de documento",dataIndex:"tipo_doc",key:"tipo_doc"},
    {title:"Nombre completo",dataIndex:"nombre_completo",key:"nombre_completo"},
    {title:"Numero de documento",dataIndex:"documento",key:"documento"},
    {title:"Sexo",dataIndex:"sexo_descripcion",key:"sexo_descripcion"},
    {title:"Fecha de nacimiento",dataIndex:"fecha_nacimiento",key:"fecha_nacimiento"},
    {title:"Vinculo con el jefe del hogar",dataIndex:"vinculo_descripcion",key:"vinculo_descripcion"},
    {title:"Acciones",dataIndex:"acciones",key:"acciones"}])
 
    const handleClick = () => {
      const excel = new Excel();
      excel
        .addSheet("test")
        .addColumns(Datacolumns)
        .addDataSource(dataTable, {
          str2Percent: true
        })
        .saveAs("Excel.xlsx");
    };



    useEffect(()=>{

      const {id} = params;
      sethogarId(id)
      let data = [];
 
      getRequestAll(`hogar/integrantesbyId?id=${id}`).then(res=>{
            if(res['success']){
              let i =1;
                res['Integrantes'].forEach(d => {
                    data.push({
                      key: i,
                      numero_ficha:d.numero_ficha,
                      tipo_doc:d.tipo_doc,
                      nombre_completo:d.nombre_completo,
                      documento:d.documento,
                      fecha_nacimiento:d.fecha_nacimiento,
                      vinculo_descripcion:d.vinculo_descripcion,
                      sexo_descripcion:d.sexo_descripcion,
                      acciones:<>
                      {/* <Tooltip title='DISCAPACIDAD Y HECHO VICTIMIZANTE POR CONFLICTO ARMADO' color="orange">
                         <Button type="primary" className='btn-detalle' shape="circle" icon={<PlusOutlined  />}  onClick={()=>navigate(`/panel/vivienda/integrantes/discapacidad/${d.integrante_id}`, { replace: true })} /> 
                      </Tooltip>
                      &nbsp; */}
                      <Tooltip title='EDITAR INFORMACION DEL INTEGRANTE' color="orange">
                         <Button type="primary"  shape="circle" icon={<EditFilled  />}  onClick={()=>navigate(`/panel/vivienda/integrante/update/${d.integrante_id}`, { replace: true })} /> 
                      </Tooltip>
                      </>
                           
                    })
                    i++;
                });

                setDataTable(data)
            }
      })
    },[])


  return (
   <>
     <Row>
        <Col lg={24}>
        
        <Title level={4}>INTEGRANTES REGISTRADOS</Title> 
       <Divider />
       <Space style={{ marginBottom: 16 }}>
          {/* <Button  icon={<FilePdfOutlined  />} danger></Button> */}
          <Tooltip title='Exportar' color="orange">
             <Button  icon={<FileExcelOutlined  />} className="btn-excel" onClick={handleClick}></Button>
          </Tooltip>
      </Space>
          <MyDataTable Datacolumns={Datacolumns} dataTable={dataTable} />
        </Col>
    </Row>
   </>
  )
};

const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(IntegrantesList);
