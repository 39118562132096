import {ATENCIONSELECT} from '../types';

const INITIAL_STATE = null;

const atencionSelect = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case ATENCIONSELECT :
            return  action.payload;
        default: return state
    }
};

export default atencionSelect;