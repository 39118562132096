import React from 'react'
import { Col, Row, Divider, Tree } from 'antd';
import Tenecia from './Tenecia';
import TipoVivienda from './TipoVivienda';
import TotalVivienda from './TotalVivienda';

function Vivienda({region, cargo, funciones_id}) {
  return (
    <div className='grid md:grid-cols-3 sm:grid-cols-1 gap-4'>
        
        <Tenecia region={region} cargo={cargo} funciones_id={funciones_id}/>
        <TipoVivienda region={region} cargo={cargo} funciones_id={funciones_id}/>
        { cargo != 2 ? <TotalVivienda region={region} cargo={cargo} funciones_id={funciones_id} /> : null }
        
    </div>
  )
}

export default Vivienda;