import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { canalizacionbyuserId, getUserSubregion, } from '../../Api/seguimiento';
import { Skeleton, Select, Table,  Button, Popover, Drawer, notification, message   } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined, DownloadOutlined , EyeOutlined, FileExcelOutlined, AimOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { downloadExcelApi , hambresabanaSubregion, descargarTodo, sabanaIntranes} from '../../Api/excel';
import { connect } from 'react-redux';
import TableDrawe from './TableDrawer';
import moment from 'moment';
import Mapa from '../../components/Mapa'



const content = (
    <div>
      <p>Ver informacion de este usuario</p>
    </div>
  );

function Usuarios({region, user, cargo}) {

    const navigate = useNavigate();
    const [columns, setColumns] = useState([
        {
          title: 'Nombre',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'celular',
          dataIndex: 'celular',
          key: 'celular',
          responsive: ['md']
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            key: 'cargo',
            responsive: ['md']
        },
        {
            title: 'Hospital',
            dataIndex: 'nombre_red',
            key: 'nombre_red',
            responsive: ['md']
        },
        {
            title: 'Equipo',
            dataIndex: 'equipo',
            key: 'equipo',
        },
        {
            title: 'visitas',
            dataIndex: 'visitas_realizadas',
            key: 'visitas_realizadas',
        },
        {
            title: 'Integrantes',
            dataIndex: 'integrantes',
            key: 'integrantes',
            responsive: ['md']
        },
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            key: 'acciones',
            responsive: ['md']
        },
    ]);
    const [columnsTwo, setColumnsTwo] = useState( 
        [
            {
                title: 'Municipio',
                dataIndex: 'ciudad',
                key: 'ciudad',
                responsive: ['md', 'xs']
            },
            {
              title: 'Nombre',
              dataIndex: 'title',
              key: 'title',
              responsive: ['md', 'xs']
            },
           
            {
                title: 'Cargo',
                dataIndex: 'funcion',
                key: 'funcion',
                responsive: ['md']    
            },
            {
              title: 'celular',
              dataIndex: 'celular',
              key: 'celular',
              responsive: ['md']
            },
            {
                title: 'email',
                dataIndex: 'email',
                key: 'email',
                responsive: ['md', 'sm']
            },
            {
                title: 'Total visitas',
                dataIndex: 'total_visitas',
                key: 'total_visitas',
                responsive: ['md', 'sm']
            },
            {
                title: 'Acciones',
                dataIndex: 'acciones',
                key: 'acciones',
                responsive: ['md', 'xs']
            }
        ]
    )
    
    let arr = [
        {
            title: 'ficha',
            dataIndex: 'ficha',
            key: 'ficha',
        },
        {
            title: 'observacion',
            dataIndex: 'observacion',
            key: 'observacion',
        },
        {
            title: 'cumplimiento',
            dataIndex: 'cumplimiento',
            key: 'cumplimiento',
        },
      
        {
            title: 'fecha de gestion',
            dataIndex: 'fecha_gestion',
            key: 'fecha_gestion',
        }
   ]
    const [departaments, setDepartaments] = useState([])
    const [listSelect, setListSelect] = useState([]);
    const [excelExpost, setexcelExpost] = useState([])
    const [optionSelect, setOptionSelect] = useState("")
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openDrawerTwo, setopenDrawerTwo] = useState(false)
    const [selectRegion, setSelectReion] = useState("");
    const [nameSelect, setnameSelect] = useState("");
    const [dataSourceTwo, setdataSourceTwo] = useState(null);
    const [draweMap, setdraweMap] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false)

    const getInfoVis = async (usuario_id, name) => {
        setopenDrawerTwo(true); 
        setnameSelect(name)
        const id = await canalizacionbyuserId(usuario_id);
        let arra = id.data.Canalizaciones.map(i => {
            return(
                    {
                        ficha: i.ficha,
                        observacion: i.observacion,
                        cumplimiento: i.cumplimiento,
                        fecha_gestion: moment(i.fecha_gestion).format('l'),
                    }
                )
        })
            
       setdataSourceTwo(arra);
       
    }

    const { data, status } = useQuery({ 
        queryKey: [`userSubregion`,  user.funciones_id === 2 ? user.ciudad_id : region], 
        queryFn: getUserSubregion ,
        onSuccess: (info => {
            
            if(user.user_type_id === 8 || user.user_type_id === 3 || user.funciones_id === 1 ){
               
                let array = info.data.usuarios.map( i => {
            
                    if(i.total_visitas){
                    }else{
                        let colNew = columnsTwo.map( i => {
                                if(i.key === 'total_visitas'){
                                    i.title = 'total visitas'
                                }
                                return i
                        })

                        setColumnsTwo(colNew)
                        
                    }
                    
                    return  {
                        title: i.nombre_completo,
                        id: i.identificacion,
                        funcion: i.funcion,
                        celular: i.celular,
                        email: i.email,
                        ciudad:  i.subregion ?  i.subregion : i.ciudad,
                        total_visitas: i.total_visitas ?  i.total_visitas : i.total_canalizaciones,
                        acciones: <div className='flex'>
                            <Popover content={content} className="mx-2">
                                <Button className='bg-blue text-white' shape="circle"
                                    onClick={()=> {
                                        //   navigate(`/panel/canalizacion/gestion/hogar/${i.usuario_id}`, { replace: true });
                                        setOpenDrawer(true); 
                                        setSelectReion(i.ciudad_id ? i.ciudad_id : i.subregiones_id) 
                                        }
                                    }
                                    icon={<SearchOutlined />}
                                />
                            </Popover>
                            <Button className='bg-blue text-white' shape="circle"
                                onClick={()=> {
                                        notification.success({
                                            message: 'DESCARGANDO ARCHIVO, POR FAVOR ESPERE...',
                                            description: 'Esto puede tardar un poco.',
                                        });
                                        hambresabanaSubregion(i.ciudad_id ? i.ciudad_id : i.subregiones_id, i.ciudad_id ? 'city' : 'region');
                                    }
                                }
                                icon={<FileExcelOutlined />}
                            />
                            { 
                                user.funciones_id === 1  ? 
                                    <Button className='bg-blue text-white' shape="circle"
                                        onClick={()=> {
                                            //   navigate(`/panel/canalizacion/gestion/hogar/${i.usuario_id}`, { replace: true });
                                            getInfoVis(i.usuario_id, i.nombre_completo) 
                                            }
                                        }
                                        icon={<EyeOutlined />}
                                    />
                                :
                                <></>
                               
                            }
                           
                        </div> 
                    } 
                   
                    }
                    
                    
                );
                
                let myArray = [];
                array.forEach(e => {
                    let filt = myArray.find((x) => e.ciudad == x.ciudad);
                    if(filt){
                        myArray.push({
                            ...e,
                            total_visitas: ''
                        });
                    }else{
                        myArray.push(e);
                    }
                });
                setListSelect(myArray);
            }else{
                let arrayAll = [];
                info.data.usuarios.forEach(e => arrayAll.push(e.ciudad));
                const mySet1 = new Set(arrayAll);
                let finalAtta = [...mySet1]
                setDepartaments(finalAtta);
                if(finalAtta[0]){
                    handleChange(finalAtta[0], info)
                }
            }
            if( user.funciones_id === 2 || user.funciones_id === null ){
           
                setColumnsTwo(columnsTwo.filter( i => i.key != "funcion"))
            }
            setexcelExpost(info.data.usuarios);
           
        })
    });

    if(status === "loading") return <Skeleton active />
    if(status === "error")  return <p>Ups...</p>
    
    function handleChange (e, array){
     
        setOptionSelect(e)
        let arrayFilt = array ? array : data;
        const arrayFilter = arrayFilt.data.usuarios.filter( i => i.ciudad === e);
        const formaArray = arrayFilter.map( (i) => {

            return ({
                title: i.nombre_completo,
                id: i.identificacion,
                celular: i.celular,
                cargo: i.cargo,
                nombre_red: i.nombre_red,
                equipo: i.equipo,
                visitas_realizadas: i.visitas_realizadas ? i.visitas_realizadas :i.total_canalizaciones,
                total_visitas:  i.visitas_realizadas ? i.visitas_realizadas :i.total_canalizaciones,
                integrantes: i.integrantes_registrados,
                email: i.email,
                ciudad: i.ciudad,
                user: i.usuario_id,
                isActive: i.is_active,
                hogar: i.hogar_id,
                acciones:  <Popover content={content}> 
                <Button className='bg-blue text-white' shape="circle"
                    onClick={()=>navigate(`/panel/canalizacion/gestion/hogar/${i.usuario_id}`, { replace: true })}
                    icon={<SearchOutlined />}
                />
              </Popover>
            })
        })
        setListSelect(formaArray)
    };
   
   
    function handleDownloadExcel() {
        let body = excelExpost.map( i => Object.values(i) );
        let header2 = excelExpost.map( i => Object.keys(i) );
        let obj = {
            fileName: "usuarios",
            header:  header2[0],
            body
        };
        downloadExcelApi(obj);
    }
    
   const sabanaUsairo2 = async () => {

    try {
       
        const suerInfo =  JSON.parse(localStorage.getItem('user'));
        notification.success({
            message: 'DESCARGANDO ARCHIVO:',
            description: 'Tiempo estimado 5minutos'
        })

       if( suerInfo.user_type_id === 3 ||  suerInfo.user_type_id === 8){
       await  descargarTodo();
       setLoadingExcel(false)
       }else{
       await setLoadingExcel(true)
        await hambresabanaSubregion(
            suerInfo.funciones_id === 2 ? suerInfo.ciudad_id : suerInfo.subregion_id, 
            suerInfo.funciones_id === 2 ? 'city' : 'region'
        );
        setLoadingExcel(false)
       }
        
    } catch (error) {
        setLoadingExcel(false)
        notification.error({
            message: 'error al descargar',
            description: 'Tiempo estimado 5minutos'
        })
    }
    
    
   }

   const sabanaIntranesHamble = () => {
    notification.success({
        message: 'DESCARGANDO ARCHIVO:',
        description: 'Tiempo estimado 5minutos'
    })
    sabanaIntranes()
   }

    return (
        <div>
        <div className='flex mb-4 md:flex-row sm:flex-col'>
            <h2 className='text-2xl font-bold mr-3 uppercase'>Equipo de trabajo pic</h2>
            <Button type="dashed" shape="round" onClick={handleDownloadExcel} icon={<DownloadOutlined />} 
                size="Large"
                className='px-6 bg-geen2 text-white hover:text-geen2 hover:border-geen2'
                disabled={listSelect.length < 1 ? true : false}
            >
                Descargar Excel
            </Button>
            <Button type="dashed" shape="round" icon={<AimOutlined />} 
                size="Large"
                onClick={() => setdraweMap(!draweMap)}
                className='px-6 bg-orange text-white hover:text-orange hover:border-orange flex justify-center items-center md:ml-3 sm:ml-0 sm:mt-2 md:mt-0'
            >
                    Mapa de calor
            </Button>
            { user.funciones_id === 2 || user.funciones_id === 1 || user.user_type_id === 3 || user.user_type_id === 8  ?
            <>
                <Button type="dashed" className='mx-3 px-6 bg-white  text-geen2 hover:text-geen2 hover:border-geen2' shape="round" onClick={ () => {
                    sabanaUsairo2()
                }}  
                icon={<FileExcelOutlined />}>
                    Descargar Sabana de datos
                </Button>
                <Button type="dashed" className='bg-orange text-white hover:text-orange hover:border-orange' onClick={sabanaIntranesHamble} icon={<CloudDownloadOutlined />}> Descargar Sabana de datos de integrantes</Button>
            </>
             : null }
        </div>
            { cargo === 4 || cargo === 8 || departaments.length <= 1 ?
                null
            : 
            <Select
                style={{
                    width: 300,
                    marginBottom: 36
                }}
                placeholder="Selecciona una ciudad "
                onChange={ (e) => handleChange(e) }
                value={optionSelect}
                options={ departaments.map(i => {
                    return   {
                        value: i,
                        label: i,
                    }
                } )}
                />
            }
            
          
            <Table bordered  dataSource={listSelect} columns={ user.user_type_id === 8 ||  user.user_type_id === 3 ||  user.funciones_id === 1 ? columnsTwo  : columns  } pagination={{ defaultPageSize: 40 }} />
            
            <Drawer
                placement="right"
                size="large"
                width={1280}
                closable={true}
                onClose={() => setOpenDrawer(false) }
                open={openDrawer}
                key="right"
            >
            <TableDrawe region={selectRegion} />
            </Drawer>
            <Drawer
                placement="right"
                size="large"
                width={1280}
                onClose={() => setopenDrawerTwo(!openDrawerTwo) }
                open={openDrawerTwo}
                key="dos"
            >
                { dataSourceTwo === null ? 
                
                <Skeleton active />
                : 
                <>
                <div className='mb-8 border-b pb-3'>
                    <h2  className='text-2xl font-bold text-gray5 uppercase'>
                    Canalizaciones de {nameSelect}
                    </h2>
                </div>
                    <Table bordered  dataSource={dataSourceTwo} columns={arr } pagination={{ defaultPageSize: 40 }} />
                </>
                }
            </Drawer>
            <Drawer
                placement="right"
                size="large"
                width={'90%'}
                title="Mapa de calor"
                onClose={() => setdraweMap(!draweMap) }
                open={draweMap}
                key="tres"
            >
                <Mapa/>
            </Drawer>
            { loadingExcel && <div className='fixed bottom-0 w-full bg-green p-2 z-50 text-[20px]'>
                <h3 className='text-white'>DESCARGANDO ARCHIVO: <span className='font-bold'> Tiempo estimado 5minutos</span></h3>
            </div> }
        </div>
    )
}

const mapStateToProps = state => state;

export default  connect(mapStateToProps, {})(Usuarios);