import React, {useEffect, useState, useRef} from 'react';
import { Space, Table, Button, Skeleton, Tooltip, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UsergroupAddOutlined, EyeOutlined, SearchOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';

function Lista(props) {
  const navigate = useNavigate();
  const [listAtenciones, setListAtenciones] = useState([]);
  const [Loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const getDataStorage = async () => {
    let listIbj = [];
    props.atencionList.forEach( (i) => {
      let obj = {
        key: i.uiid,
        ficha: i.ficha,
        fecha: i.fecha_crea,
        document:  i.docNum,
        jefe: i.jefe,
        action: <Space>
          <Tooltip placement="top" title='Agregar integrante a este hogar'>
            <Button onClick={()=>navigate( `user/integrante/${i.atencion_salud_id}/create`, { replace: true })} className="text-green flex justify-between items-center w-[50px] h-[40px] text-[20px]"><UsergroupAddOutlined /></Button>
          </Tooltip>
          <Tooltip placement="top" title='Ver integrante del hogar'>
            <Button icon={ <SearchOutlined />}  onClick={()=>navigate( `user/list/${i.atencion_salud_id}`, { replace: true })} className="w-[50px] h-[40px]" />
          </Tooltip>
          <Tooltip placement="top" title='Ver informacion del hogar'>
          <Button icon={<EyeOutlined />}  onClick={()=>navigate( `user/vivienda/${i.atencion_salud_id}/${i.JSON_object }`, { replace: true })} className="w-[50px] h-[40px]" />
          </Tooltip>
        </Space>
      }
      listIbj.push(obj);
    })
    setListAtenciones(listIbj);
    setLoading(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
    ),
  });

  const columns = [
    {
      title: 'Acciones',
      key: 'action',
      render: (_, record) => record.action,
    },
    {
      title: 'ficha',
      dataIndex: 'ficha',
      key: 'ficha',
      render: (text) => <a>{text}</a>,
      ...getColumnSearchProps('ficha'),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha'
    },
  ];
  useEffect(()=> {
      setLoading(true);
      getDataStorage()
  }, []);



  return (
    <div>
        {Loading === true ? <Skeleton active /> :
        <Table columns={columns} dataSource={listAtenciones} />
        }
       
    </div>
  )
}

const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(Lista)