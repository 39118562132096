import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { municipios } from '../../Api/municipios';
mapboxgl.accessToken = 'pk.eyJ1IjoianBhbHRhaG9uYSIsImEiOiJja2Qxb3FpMnQwNWtmMnhueDFoeWNqdXA0In0.GpHphk05qXvdmu0W2iuPig';

function InitialMap({ refMap, capturando }) {
    const mapContainer = useRef(null);
    const map = refMap;
    const [lng, setLng] = useState(-74.8070021);
    const [lat, setLat] = useState(10.130215);
    const [zoom, setZoom] = useState(8);



    const setMark = () => {

        let arrayInfo = municipios.map( i => {
       
            return {
                    'type': 'Feature',
                    'properties': {
                        'title': i.ciudad
                    },
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [i.coord.long, i.coord.lat]
                    },
            }
        })
        let geojsonI = {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': arrayInfo
            }
        };
        
        map.current.addSource('points', geojsonI)
      
        map.current.addLayer({
            'id': 'circle',
            'type': 'symbol',
            'source': 'points',
            'layout': {
                'icon-image': 'miLocation',
                'icon-size': 0.5,
                'text-field': ['get', 'title'],
                  'text-font': [
                  'Open Sans Semibold',
                  'Arial Unicode MS Bold'
                ],
                'text-offset': [0, -1.0],
                'text-anchor': 'bottom'
            }
        });

        map.current.on('click', 'circle', (e) => {
            capturando(e.features[0].properties.title)
        });
        
    }
    useEffect(() => {
        if (map.current) return; // initialize map only once
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/dark-v11',
                center: [lng, lat],
                zoom: zoom
            });
            map.current.on('load', () => {
                map.current.addSource('maine', {
                    'type': 'geojson',
                    'data': {
                    'type': 'Feature',
                    'geometry': {
                    'type': 'Polygon',
                    // These coordinates outline Maine.
                    'coordinates': [
                            [
                                [-73.86736891151817, 8.920099224029368],
                                [-73.80332750328448, 9.059786678490113],
                                [-73.87003730352791, 9.130927392299022],
                                [-73.94475227980054, 9.294233654615699],
                                [-73.97677298391739, 9.32319943071809],
                                [-74.08884544832634, 9.465360056758863],
                                [-74.13954489651132, 9.499575164261827],
                                [ -74.06216152822896, 9.562732526209087],
                                [-73.98744655195632, 9.594306814458255],
                                [-73.7899855432358, 9.591675735968607],
                                [-73.83534820740132, 9.723204536744776],
                                [-73.93941549578106, 9.925657505572532],
                                [-74.08084027229712, 10.067563023511974],
                                [-74.07283509228598, 10.141119170141078],
                                [-73.98435062875205, 10.26282050602264],
                                [-73.70835812462458, 10.461472500478616],
                                [-73.56250844171842, 10.512218821812901],
                                [-73.61187294977897, 10.629124186503843],
                                [-73.54455771151459, 10.74818928696437],
                                [-73.65001825146211, 10.80329614953717],
                                [-73.64777441109982, 11.14252957864099],
                                [-73.94998230631786, 11.314680057761343],
                                [-74.19969995432308 ,11.285959971857984],
                                [ -74.23207076054598, 11.163489560766951],
                                [-74.32185260364673, 10.986247778939951 ],
                            
                                [-74.72077720910241, 10.90241227565747 ],
                                [-74.72744820930767, 10.57208187938902],
                                [-74.87020718182859, 10.354288228576097],
                                [-74.9502589421207, 10.138969943341602],
                                [-74.88755172373807, 10.106134236395555],
                                [ -74.79682639540701, 10.022059580133389,],
                                [-74.85686521978901, 9.951104611696534, ],
                                [ -74.86887298383284, 9.836755896108857],
                                [-74.81016835961863, 9.765760893646243],
                                [ -74.83018129969165, 9.692120452207913],
                                [-74.75813471542877 , 9.58031392503609],
                                [-74.79415800122548, 9.443465276291295],
                                [-74.65673581272404, 9.389500342122124],
                                [-74.51130844819338, 9.238090575990185],
                                [-74.36854945413404, 9.220970573031739],
                                [-74.26047957212887, 9.170922731636946],
                                [-74.15107549972966, 9.066853361806015],
                                [-74.0390030353207, 9.040501886262751],
                                [-74.0016455471844, 9.026007752353783],
                                [-73.88557049476084, 8.977250487125975],
                                [-73.86555755468783, 8.948256570784611 ],
                                [ -73.86736891151817, 8.920099224029368]

                            ]
                        ]
                    }
                    }
                });

                map.current.addLayer({
                    'id': 'maine',
                    'type': 'fill',
                    'source': 'maine', // reference the data source
                    'layout': {},
                    'paint': {
                    'fill-color': '#ff7208', // blue color fill
                    'fill-opacity': .1
                    }
                });

                map.current.addLayer({
                    'id': 'outline',
                    'type': 'line',
                    'source': 'maine',
                    'layout': {},
                    'paint': {
                    'line-color': '#ff7208',
                    'line-width': 2
                    }
                });

                setMark();

            })
            map.current.loadImage(
                'https://cdn.shopify.com/s/files/1/0274/5804/3990/files/location-dot-solid_2x_53a35713-ae23-4ce3-ba6f-c590eda28430.png?v=1662651645',
                (error, image) => {
                if (error) throw error;
                map.current.addImage('miLocation', image);
            });
            map.current.loadImage(
                'https://docs.mapbox.com/mapbox-gl-js/assets/popup.png',
                (error, image) => {
                if (error) throw error;
                map.current.addImage('popup-debug', image);
            });

        const size = 200;

        const pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),
             
            // When the layer is added to the map,
            // get the rendering context for the map canvas.
            onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
            },
             
            // Call once before every frame where the icon will be used.
            render: function () {
            const duration = 1000;
            const t = (performance.now() % duration) / duration;
             
            const radius = (size / 2) * 0.3;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const context = this.context;
             
            // Draw the outer circle.
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
            );
            context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
            context.fill();
             
            // Draw the inner circle.
            context.beginPath();
            context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
            );
            context.fillStyle = 'rgba(255, 100, 100, 1)';
            context.strokeStyle = 'white';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();
             
            // Update this image's data with data from the canvas.
            this.data = context.getImageData(
            0,
            0,
            this.width,
            this.height
            ).data;
             
            // Continuously repaint the map, resulting
            // in the smooth animation of the dot.
            map.current.triggerRepaint();
             
            // Return `true` to let the map know that the image was updated.
            return true;
            }
        };
        
        map.current.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

        
    }, []);

  
    return (
        <div ref={mapContainer} className="w-full h-full" />
  )
}

export default InitialMap