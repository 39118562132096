import React from 'react';
import { useQuery } from '@tanstack/react-query'
import { Skeleton } from 'antd';
import { getTotalViviendas } from '../../../Api/vivienda';

function TotalVivienda({region}) {
    const {data, status} = useQuery({
        queryKey: [`getTotalViviendas`, region], 
        queryFn: getTotalViviendas
    });

    if(status === "loading") return <Skeleton active />
    if(status === "error")  return <p>Ups...</p>

  return (
    <div className='shadow-md p-4 rounded-md'>
        <h2 className='text-xl font-bold mb-3 uppercase'>Total vivienda</h2>
        <ul className='list-none'>
            { data.data.total.map ( (i, index) => <li key={index} className="mb-4 className='text-[16px]'">
                {i.ciudad} :  <b className='text-orange ml-3'>{i.cantidad}</b>
            </li> )} 
        </ul>
    </div>
  )
}

export default TotalVivienda