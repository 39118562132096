import axios from "axios";
import { settings } from "./setting";
import { downloadExcel } from "react-export-table-to-excel";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

let reisgosLis = [
  "Paredes y techos sin grietas, huecos, humedades",
  "Adecuado manejo de combustibles (sólidos, líquidos, gaseosos)",
  "Las áreas habitacionales de la vivienda están separadas entre si (baño, cocinas y habitaciones)",
  "La vivienda tiene iluminación y ventilación adecuada",
  "Las condiciones físicas y locativas del baño son adecuadas",
  "Las condiciones físicas y locativas  de la cocina son adecuadas (evitan la concentración de humo, chimeneas en buen estado (tubo extractor sin obstrucción, sin fisuras, con salida fuera de la vivienda) lavaplatos interno)",
  "Adecuadas instalaciones eléctricas y de gas (instalaciones seguras, sin recargar, fijas a paredes y techos)\t",
	"Los elementos del hogar están en lugares seguros (materas, tijeras, cuadros, utensilios y muebles)\t",
	"La vivienda cuenta con adecuado sistema de suministro de agua (acueducto, continuo, sin fugas, acometidas en buen estado, fuentes sin semovientes o libres de elementos extraños)",
	"Los tanques o recipientes para el almacenamiento de agua están limpios, tapados y elevados del piso",
	"Manipulación adecuada del agua para consumo (desinfección adecuada, uso seguro de utensilios)",
	"Uso racional y reutilización adecuada del agua",
	"La familia cuenta con unidad sanitaria sin riesgo higiénico sanitario",
  "Existe adecuada disposición de aguas residuales y grises (baño, cocina, lavadero, terraza, campo abierto, pozo séptico)",
	"El baño, terraza y lavadero se encuentran limpios y sin olores",
	"Recipientes aseados, con tapa y bien ubicados",
	"Vivienda aseada y ordenada",
  "Separación de residuos (basuras) en la fuente",
  "Reutilización y practicas de reducción de residuos sólidos",
	"Hay presencia de residuos alrededor de la vivienda (focos o residuos dispersos)",
	"La familia dispone los residuos sólidos adecuadamente  (empresa de aseo, recolección de envases de agroquímicos vacíos y lavados, relleno sanitario casero, residuos sin quemar )",
	"La familia conoce y cumple el horario de recolección de residuos sólidos",
	"hay presencia de plagas en la vivienda (roedores, insectos, piojos, pulgas, palomas)",
	"Se realiza adecuado control preventivo de plagas (químico o alternativo)\t",
	"Las practicas higiénico - sanitarias no fomentan la proliferación de vectores en la vivienda\t",
	"Almacenamiento y conservación adecuada de alimentos (lugar adecuado, alimentos tapados, recipientes limpios, refrigerados, ahumados, salados)",
	"Adecuada limpieza de la cocina (orden, limpieza y desinfección)",
	"Adecuado cuidado del cuerpo (uñas, manos, calzado, ropa limpia) a diario y para la manipulación de alimentos",
	"Se verifican etiquetas y fechas de vencimiento de alimentos",
	"Adecuado estado y limpieza de los utensilios utilizados \t",
	"Los productos químicos están almacenados de forma segura (lugar ventilado, rotulados, separados de las áreas habitacionales)\t",
	"Se siguen las recomendaciones de uso de la etiqueta de los productos químicos\t",
	"Los medicamentos están almacenados adecuadamente (rotulados, separados)\t",
	"Adecuada disposición de medicamentos vencidos\t",
	"Se implementan medidas de protección personal para el manejo de químicos, incluido el estado y limpieza de equipo (guantes, peto, gafas,botas,careta )\t",
	"Conocimiento sobre los signos de alarma para identificar la presencia de monóxido de carbono  (llama amarilla, hollín) y gases de agroquímicos (olor a químicos, derrames, elementos impregnados con químicos\t",
	"Las mascotas (Gato, Perro) son vacunadas y desparasitadas periódicamente",
  "Las mascotas son aseadas periódicamente",
	"Las mascotas u otras especies cuentan con alojamiento adecuado (aseado y separado)",
	"Las excretas se recogen y manejan adecuadamente",
	"Permanencia de las mascotas u otras especies en un lugar adecuado"
]
let reisgosLisIntegrtante = [
  "riesgo_prioritario_id,tipo_riesgo_prioritario_id,descripcion_riesgo"
,"NO ESCOLARIZADO"
,"Hasta Primaria completa"
,"Secundaria incompleta"
,"Primaria incompleta "
,"Tecnólogo","Técnico",
"Universitario",
"Actualmente se encuentra estudiando","Desertor por trabajo"
,"Desertor por violencia escolar"
,"Condiciones económicas"
,"Desmotivación personal"
,"Distancia al centro educativo"
,"Gestación"
,"Problemas de salud"
,"Conflictos Conyugales"
,"Conflictos entre padres e hijos"
,"Conflictos entre hermanos"
,"Conflictos entre Familia",
"Violencia Intrafamiliar y maltrato infantil u otros",
"Roles inadecuados al interior de la familia"
,"Transtornos Mentales y del Comportamiento "
,"Conducta Suicida"
,"consumo  de alcohol o psicoactivos sin tratamiento"
,"Funcion familiar Normal (APGAR)"
,"Disfuncion Familiar (Leve, Moderada, Severa)"
,"Niño/niña con esquema de  vacunación incompleto para la edad "
,"Menor de 5 años sin  control de crecimiento y desarrollo en el último año"
,"Menor  de 5 años con signos y síntomas de EDA sin tratamiento"
,"Menor  de 5 años con signos y síntomas de ERA sin tratamiento"
,"Menores de 5 años de grupos etnicos con practica propia desde la medicina ancestral sin complementarse con la medicina facultativa"
,"Menor de 5 años con problemas nutricionales según perimetro braquial"
,"menor de 5 años con problemas de desnutricion según resolucion 2350 del 2020"
,"Menor de 5 años con riesgo de sobrepeso - obesidad"
,"Menor de 6 meses sin lactancia materna exclusiva"
,"Menor de un años con Bajo Peso al Nacer sin intervención"
,"Niño/Niña con signos de peligro de muerte"
,"Niño/niña en condiciones de explotación no dignas"
,"Chequeo odontológico en 6 últimos meses"
,"Citología vaginal en 2 últimos años (Mujer de 21 a 64 años)"
,"Mamografía en 5 últimos años (Mujer de 50 y más años)"
,"Uso de método de planificación"
,"Adolescente en o con antecedente de embarazo"
,"Antecedentes de malformados en la familia o de malformaciones congénitas mayores."
,"Antecedente de 2 o más abortos espontáneos, muerte perinatal, bajo peso al nacer, prematurez."
,"Antecedente de cirugia ginecológica."
,"Enfermedades crónicas: Diabetes,  hipertensión, cardiopatias, tumores, enfermedad renal, epilepsia sin tratamiento"
,"Periodo intergenésico menor a dos años o mayor a diez años. "
,"Multiparidad (5 o más partos)"
,"Antecedente de VIH e ITS no tratada o en tratamiento."
,"Antecedente de recién nacido macrosómico (mayor de 4.000 gramos) o de bajo peso (menor de 2.500). "
,"Antecedente de Embarazo múltiple "
,"Mujer en puerperio sin método de regulación de la fecundidad"
,"Violencia contra las mujeres basada en el genero y riesgo de feminicidio. "
,"Persona con diagnóstico de obesidad sin tratamiento"
,"Adulto con diagnóstico de enfermedad crónica"
,"Adulto con diagnóstico de Diabetes mellitus"
,"Adulto con enfermedad renal cronica"
,"Adulto mayor con diagnóstico de EPOC"
,"Pesona con cáncer"
,"Sin tratamiento para esas enfermedades o patología no controlada"
,"Tosedor crónico por más de 14 días "
,"Persona  que ha viajado en los últimos 15 días a zonas endémicas con sintomatología asociada a ETV sin tratamiento."
,"Hábitos inadecuados de cuidado y autocuidado"
,"Ausencias dentales"
,"Dieta cariogenica"
,"No uso de los elementos de higiene oral"
,"Frecuencia del cepillado dental "
,"Limpieza dental profesional cada 6 meses"
,"Atención odontológica en los últimos 6 meses"
,"No acceso al servicio de salud oral "
,"Niños y niñas menores de 5 años con diagnóstico confirmado de desnutrición aguda moderada y severa o de delgadez"
,"Niños y niñas menores de 5 años con diagnóstico confirmado de desnutrición aguda moderada y severa o de desnutrición Global o Crónica Severa."
,"Adolescentes gestantes y lactantes con prácticas inadecuadas de alimentación. "
,"Personas con condiciones crónicas (Cardiovasculares, metabólicas, cánceres, VIH) y/o discapacidad que presenta riesgo nutricional."
,"Gestante sin control prenatal en el último mes"
,"Gestante en la semana 36 sin control prenatal"
,"Gestante con signos o síntomas de alarma"
,"Gestante con examen paraclínico anormal (urocultivo,parcial de orina, serología sifilis, hepatitis B ,VIH  Y ecografía)"
,"Gestante con enfermedad crónica no controlada o en tratamiento (Diabetes,hipertensíon,cardiopatías, tumores enfermedad renal, epilepsia"
,"Gestantes sin hierro y acído fólico"
,"Gestante de grupos étnicos con practica propia desde la medicina ancestral sin complementarse con la medicina facultativa"
,"Secundaria incompleta"
,"Abandono o despojo forzado de tierrasDesplazamiento forzado"
,"Acto terrorista"
,"Amenaza"
,"Atentado"
,"Delitos contra la libertad y la integridad sexual en desarrollo del conflicto armados"
,"Desaparición forzada"
,"Desplazamiento forzado"
,"Homicidio"
,"Masacre"
,"Mina antipersonal munición sin explotar y artefacto explosivo improvisado"
,"Secuestro"
,"Tortura"
,"Vinculación de niños (as) y adolescentes a actividades relacionadas con grupos armados"
,"Hasta Secundaria Completa"

]

export const downloadExcelApi = (obj) => {
    const {fileName, header, body} = obj ;

    downloadExcel({
        fileName: fileName,
        sheet: 'hoja-1',
        tablePayload: {
          header: header,
          body: body,
        }
    });
}

export const sabanadataHogarBySubregionId = async (subregion) => {
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const res = await axios.get(settings.url+'/generics/sabanadataHogarBySubregionId/?subregion_id='+subregion, {
      headers: {
        'Authorization': userInfo.token
      }
  });

  return res.data
}

export const sabanadataIntegranteSubregionById = async (subregion) => {
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const res = await axios.get(settings.url+'/generics/sabanadataIntegranteSubregionById/?subregion_id='+subregion, {
      headers: {
        'Authorization': userInfo.token
      }
  });
  return res.data
}

export const sabanadataHogarCiudadById = async (city) => {
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const res = await axios.get(settings.url+'/generics/sabanadataHogarCiudadById?ciudad_id='+city, {
      headers: {
        'Authorization': userInfo.token
      }
  });
  return res.data
}

export const sabanadataIntegranteCiudadById = async (city) => {
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const res = await axios.get(settings.url+'/generics/sabanadataIntegranteCiudadById?ciudad_id='+city, {
      headers: {
        'Authorization': userInfo.token
      }
  });
  return res.data
}


export const sabanadataCity  = async(city) => {
  try {
    const res = await sabanadataHogarCiudadById(city);
    const resTwo = await sabanadataIntegranteCiudadById(city);

    let body = res.data.SabanaDatos.map( i => Object.values(i) );
    let header1 = Object.keys(res.data.SabanaDatos[0])

    let body2 = resTwo.data.SabanaDatos.map( i => Object.values(i) );
    let header2 = Object.keys(resTwo.data.SabanaDatos[0])


    let obj = {
        fileName: "Sabana datos Hogar City",
        header: header1,
        body
    };
    let objTwo = {
      fileName: "Sabana datos Integrantes City",
      header: header2,
      body2
  };
  await downloadExcelApi(obj);
  await downloadExcelApi(objTwo);

  } catch (error) {
    
  }
  
};

export const hambresabanaSubregion = async(subregion, type) => {
  try {
    let res;
   if(type === 'city'){
    res =  await sabanadataHogarCiudadById(subregion)
   }else{
     res = await sabanadataHogarBySubregionId(subregion);
   }
    let arrayUsers = [];
    const workbook = XLSX.utils.book_new();

    const SabanaDatos = res.data.SabanaDatos;
    SabanaDatos.forEach(e => {
      let d = arrayUsers.find( (x) => e.numero_ficha == x.numero_ficha);
      if(d){
        d.riesgo.push(e.riesgo);
      }else{
        arrayUsers.push({
          ...e,
          riesgo: [e.riesgo]
        })
      }
    });

    
    let finalArray = arrayUsers.map(i => {
       const mySet1 = new Set(i.riesgo);
       let final = [...mySet1]

       let objRetunr = {
        ...i,
       };

       
       reisgosLis.forEach( item => {
          const list = final.find( elem => elem === item);
          if(list){
            return objRetunr[item] =  'SI'
          }else{
            return objRetunr[item] =  'NO'
          }
       });
       delete objRetunr.riesgo;
       return objRetunr;
    });    

    const rows = finalArray;
    const worksheet = XLSX.utils.json_to_sheet(rows);
    XLSX.utils.book_append_sheet(workbook, worksheet,`Datos hogares`);
    XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(finalArray[0])], { origin: "A1" });
    XLSX.writeFile(workbook, "SABANA-DE-DATOS.xlsx", { compression: true });

    return true
 
  } catch (error) {

 
    return false
  }
  
};


const formatDataSabana = (sabanaDatos) => {
  return new Promise( (resolve, reject) => {
    let arrayUsers = [];

    sabanaDatos.forEach(e => {
      let d = arrayUsers.find( (x) => e.numero_ficha == x.numero_ficha);
      if(d){
        d.riesgo.push(e.riesgo);
      }else{
        arrayUsers.push({
          ...e,
          riesgo:   [e.riesgo]
        })
      }
    });

    let finalArray = arrayUsers.map(i => {
      const mySet1 = new Set(i.riesgo);
      let final = [...mySet1]

      let objRetunr = {
       ...i,
      };
      
      reisgosLis.forEach( item => {
         const list = final.find( elem => elem === item);
         if(list){
           return objRetunr[item] =  'SI'
         }else{
           return objRetunr[item] =  'NO'
         }
      });
      delete objRetunr.riesgo;
      return objRetunr;
    });
    if(  finalArray.length >= 1 )  resolve (finalArray);
    reject(null)
  })
}

const formatDataSabanaTwo = (sabanaDatos) => {
  return new Promise( (resolve, reject) => {
    let arrayUsers = [];

    sabanaDatos.forEach(e => {
      let d = arrayUsers.find( (x) => e.numero_ficha == x.numero_ficha);
      if(d){
        d.riesgo.push(e.descripcion_riesgo);
      }else{
        arrayUsers.push({
          ...e,
          riesgo:   [e.descripcion_riesgo]
        })
      }
    });

    let finalArray = arrayUsers.map(i => {
      const mySet1 = new Set(i.riesgo);
      let final = [...mySet1]

      let objRetunr = {
       ...i,
      };
      
      reisgosLisIntegrtante.forEach( item => {
         const list = final.find( elem => elem === item);
         if(list){
           return objRetunr[item] =  'SI'
         }else{
           return objRetunr[item] =  'NO'
         }
      });
      delete objRetunr.riesgo;
      return objRetunr;
    });
    if(  finalArray.length >= 1 )  resolve (finalArray);
    reject(null)
  })
}


export const descargarTodo = async() => {
  try {

    const userInfo = JSON.parse(localStorage.getItem("user"));
    let arraySabana = [];
    let outset = 0;
    const workbook = XLSX.utils.book_new();

    const recursividad = async () => {
      const res = await axios.get(settings.url+`/generics/totalsabana?outset=${outset}&limit=250000`, {
          headers: {
            'Authorization': userInfo.token
          }
      });
     // if(res.data.data.SabanaDatos.length >= 1){
        outset = outset + 50000;
        arraySabana = [...arraySabana, ...res.data.data.SabanaDatos];

       await formatDataSabana(arraySabana)
        .then( data => {
          const rows = data;
          const worksheet = XLSX.utils.json_to_sheet(rows);

          XLSX.utils.book_append_sheet(workbook, worksheet,`list-${outset}`);
          /* fix headers */
          XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(data[0])], { origin: "A1" });
          
        }).catch( error => {
          console.log(error)
        })
       
      //  return recursividad()

      /*}else{
        console.log('salio')
        
        return 
      }
      */
      XLSX.writeFile(workbook, "SABANA-DE-DATOS.xlsx", { compression: true });
    }
    recursividad();
  
  }
  catch (error) {
    console.error("error ->, ", error)
  }

}


export const sabanaIntranes = async () => {
  let outset = 0;
  const workbook = XLSX.utils.book_new();

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const res = await axios.get(settings.url+`/generics/totalsabanaIntegrante?outset=${outset}&limit=100000`, {
    headers: {
      'Authorization': userInfo.token
    }
  });

  let sabanaDatos = res.data.data.SabanaDatos;

  formatDataSabanaTwo(sabanaDatos)
    .then( data => {
      const rows = data;
      const worksheet = XLSX.utils.json_to_sheet(rows);

      XLSX.utils.book_append_sheet(workbook, worksheet,`SABANA-DE-DATOS-INTEGRANTES`);
      /* fix headers */
      XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(data[0])], { origin: "A1" });

      XLSX.writeFile(workbook, "SABANA-DE-DATOS-INTEGRANTES.xlsx", { compression: true });

    } )
    .catch( (error) => console.log(error) )
  
}