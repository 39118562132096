import React, { useState } from 'react'
import {Skeleton, Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import Lista from './Lista';
import { useNavigate } from 'react-router-dom';
import { atencionListaAction } from '../../redux/actions/atencionList.action';
import { connect } from 'react-redux';
import { getAtenciones } from '../../Api/identificadores';
import { useQuery } from '@tanstack/react-query';



function IdentificadorRiesgos(props) {
    const navigate = useNavigate();
    const { data, status } = useQuery({ queryKey: [`itegrantes`, ''],  queryFn: getAtenciones, 
        onSuccess: (info) => {
        props.atencionListaAction(info);
    } })

   
    if(status === "loading") return <Skeleton active />
    if(status === "error")  return <p>Ups...</p>


    return (
        <div>
            <h2 className=' font-bold text-xl '>IDENTIFICACION DE RIESGOS Y EDUCACION EN SALUD</h2>
            <div className='mb-3'>
                <Button icon={<PlusOutlined />}  onClick={()=>navigate(`user/vivienda/create`, { replace: true })}/>
            </div>
            <Lista />
        </div>
    )
}
const mapStateToProps = state => state;
export default connect(mapStateToProps, { atencionListaAction })(IdentificadorRiesgos)