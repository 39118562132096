import React from 'react'

const Inicio = ({auth}) => {
  return (
    <div className='logo-principal'>
         <img src='/img/banner.png' alt='logo' />
    </div>
  )
}

export default Inicio