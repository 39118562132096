import React from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  max-height:auto;
  min-height:100%;
  width: 100%;
  background:#ffffffb5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const funkyRotation = keyframes`
    from {
  transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  `;

const ImageWithFunkyRotation = styled.img`
  margin: 10px;
  animation: ${funkyRotation} 2s linear infinite;
`;

const Loading = () => {
  return (
    <Container>
      <div className="container-loader">
      <ImageWithFunkyRotation
       src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Spinner_font_awesome.svg/512px-Spinner_font_awesome.svg.png"
        width={80}
        height={80}
      />
      </div>
    </Container>
  );
};

export default Loading;

