import axios from "axios";
import { settings } from "./setting";

export const getHogarInfo =  async ({queryKey}) => {
    const res = await axios.get(`${settings.url}/hogar/hogarById?id=${queryKey[1]}`)
    return res.data
}

export const getRiesgosHogarId =  async ({queryKey}) => {
    const res = await axios.get(`${settings.url}/hogar/riesgoHogarById?id=${queryKey[1]}`)
    return res.data
}

