import axios from "axios";
import { settings } from "./setting";

export const getSubregionData = async ({queryKey}) => {
    const res = await axios.get(`${settings.url}/generics/totalvisitasubregion?id=${queryKey[1]}`)
    return res.data
}

export const riesgoibysubregion = async ({queryKey}) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));

    const res = await axios.get(`${settings.url}/generics/riesgoibysubregion/?id=${queryKey[1]}`,
    {
        headers: {'Authorization': userInfo.token}
    })
    return res.data
}
export const hogares = async ({queryKey}) => {

    const userInfo = JSON.parse(localStorage.getItem("user"));

    const res = await axios.get(`${settings.url}/generics/riesgohbysubregion?id=${queryKey[1]}`,
    {
        headers: {'Authorization': userInfo.token}
    })
    return res.data
}
export const mascotas = async ({queryKey}) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/totalmascotas/?id=${queryKey[1]}`,
    {
        headers: {'Authorization': userInfo.token}
    })
    return res.data
}
export const getUserSubregion = async ({queryKey}) => {

    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/usuarioscaracterizacion/?id=${queryKey[1]}`, 
    {
        headers: {'Authorization': userInfo.token}
    })
    return res.data
}

export const usuariosCaracterizacionByMunicipio = async ({queryKey}) => {

    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/usuarioscaracterizacionbymunicipio?id=${queryKey[1]}`, 
    {
        headers: {'Authorization': userInfo.token}
    })
    return res.data
}

export const getDepartamentData = async ({queryKey}) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/totalvisitasbyciudad?id=${queryKey[1]}`,
        {
            headers: {'Authorization': userInfo.token}
        }
    )
    return res.data
}

export const coordinadorBySubregion = async ({queryKey}) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/coordinadorBySubregion?id=${queryKey[1]}`, 
    {
        headers: {'Authorization': userInfo.token}
    })
    return res.data
}
export const canalizacionbyuserId = async (id) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/canalizacionbyuserId/?id=${id}`, 
    {
        headers: {'Authorization': userInfo.token }
    })
    return res.data
}

export const integrantesByGener = async (id) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/integrantesByGenero`, 
    {
        headers: {'Authorization': userInfo.token }
    })
    return res.data.data.Subregiones

}

export const integrantesByGrupoEtario = async (id) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/integrantesByGrupoEtario`, 
    {
        headers: {'Authorization': userInfo.token }
    })
    return res.data.data.Subregiones;
}

export const integrantesByTipofamilia = async (id) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/integrantesByTipofamilia`, 
    {
        headers: {'Authorization': userInfo.token }
    })
    return res.data.data.Subregiones
}

export const totalIntegrantesJefeHogar = async (id) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/totalIntegrantesJefeHogar`, 
    {
        headers: {'Authorization': userInfo.token }
    })
    return res.data.data.Subregiones
}

export const totalIntegrantesJefeHogarEstadocivil = async (id) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/totalIntegrantesJefeHogarEstadocivil`, 
    {
        headers: {'Authorization': userInfo.token }
    })
    return res.data.data.Subregiones
}

export const totalIntegrantesJefeHogarGrupoEtnico = async (id) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/totalIntegrantesJefeHogarGrupoEtnico`, 
    {
        headers: {'Authorization': userInfo.token }
    })
    return res.data.data.Subregiones
}

export const totalIntegrantesJefeHogarOcupacion = async (id) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/totalIntegrantesJefeHogarOcupacion`, 
    {
        headers: {'Authorization': userInfo.token }
    })
    return res.data.data.Subregiones
}