import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { ArrowLeftOutlined,SendOutlined } from '@ant-design/icons';

import {Col,Row,Typography } from 'antd';
import { Divider, Checkbox,Form,Input,Button} from 'antd';
import { getRequestById,postRequest,getRequestAll } from '../helpers/Api';
import Loading from '../helpers/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import openNotificationWithIcon from '../helpers/Notification';

export const RedPrestadora = ({isUpdate}) => {

const {Item} = Form;
const {Title} = Typography;
const { Option } = Select;
const [form] = Form.useForm();
const [intialValue] = useState()
const [formRed,setFormRed] = useState({})
const [loading,setLoading] = useState(false)
const [subregion,setSubregion] = useState('')
const [componentDisabled, setComponentDisabled] = useState(false);
const [optionsCiudad,setCiudad] = useState([])
const navigate = useNavigate();
const params = useParams();


useEffect(()=>{
    if(isUpdate) {
      setComponentDisabled(true);
      getRedById();
    }
    
},[])

useEffect(()=>{
    form.setFieldsValue(
        {
          municipio:formRed.ciudad_id,
          nombre:formRed.nombre_red,
          nit:formRed.nit,
          email:formRed.correo,
          direccion:formRed.direccion,
          telefono:formRed.telefono,
          nombre_contacto:formRed.nombre_contacto,
          identificacion:formRed.identificacion_contacto,
          emailcontacto:formRed.email_contacto,
          celular:formRed.celular
        }
      )    
      setSubregion(formRed.subregion)
},[form,formRed])


useEffect(()=>{
  getRequestAll('generics/ciudad?id=47').then(res=>{
    const {ciudades} = res['data'];
      if(res['success']) setCiudad(ciudades);
});
},[])

const getSubregion =(id)=>{
    getRequestById(id,'generics/subregion').then(res=>{
        const {region} = res['data'];
         if(res['success'])setSubregion(region[0]['subregion']);
    });
}
  
const getRedById=()=>{
    const {id} = params;
    getRequestById(id,"red/redid").then(res=>{
      if(res['success'])
      setFormRed(res['Red'])
    })
}



const onFinish  = (value) => {

    if(!isUpdate){
    setLoading(true)
      postRequest("red/",value).then(res=>{
        if(res['success'])
          setTimeout(() => {
          openNotificationWithIcon("success","Exito al crear la red prestadora de servicios!!","Se creo una nueva red prestadora servicios")
          form.resetFields();
          setSubregion('')
          setLoading(false)
          }, 500);
      }).catch(err=>{
        openNotificationWithIcon("error","Error!!","No se pudo crear la red prestadora")
      })
   }else{
    const {id} = params;
    value.red = id;
        setLoading(true)
        postRequest("red/update",value).then(res=>{
        if(res['success'])
            setTimeout(() => {
              openNotificationWithIcon("success","Exito al actualizar la red prestadora de servicios!!","Se actualizo una nueva red prestadora servicios")
            setLoading(false)
            }, 500);
        }).catch(err=>{
          openNotificationWithIcon("error","Error!!","No se pudo actualizar la red prestadora")
        })
   }
  };

  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  return (
    <>
      
      <Row>
        <Col xs={24} md ={24} lg={24}>
         <Title level={4}>Administrar red prestadora</Title>
         <Divider />
        </Col>
   { isUpdate && <Checkbox checked={componentDisabled} onChange={(e) => setComponentDisabled(e.target.checked)}>
    Habilitar formulario
  </Checkbox>
}
     </Row>
    <Form 
       layout="vertical"
       form={form}
       onFinish={onFinish}
       initialValues = {intialValue}
       disabled={componentDisabled} 
       onValuesChange={onFormLayoutChange}
       wrapperCol={{ span: 20 }}>
   {loading && <Loading/>}
  
   <br/>
    <Row>
      <Col xs={24} md ={8} lg={5}>
         <Item 
             label="Municipio" 
             name="municipio" 
             rules={[{ required: true, message: 'Por favor seleccione un municipio' }]}>
                <Select placeholder="Seleccione..."
                 onChange={(id)=>{
                    getSubregion(id)
                 }}>
                        {optionsCiudad.length > 0 && optionsCiudad.map((op,i)=>(<Option   key={`p${i}`} value={op.value}>{op.descripcion}</Option> ))}
                </Select>
         </Item>

         <Item 
             label="Nit" 
             name="nit" 
             rules={[{ required: true, message: 'Por favor seleccione un municipio' }]}>
             <Input placeholder='Nit'/>
         </Item>

         <Item 
             label="Email" 
             name="email" 
             rules={[{ required: true, message: 'Por favor ingrese un email valido!', type: 'email' }]}>
             <Input placeholder='Email'/>
         </Item>

      </Col>

        <Col xs={24} md ={8} lg={5}>

            <Item
                label="Subregión"
                rules={[{ required: true, message: 'Por favor ingrese un cargo!'}]}>
                <Input disabled placeholder='Subregión' value={subregion}/>
            </Item>

            <Item
                label="Nombre de la red prestadora"
                name="nombre"
                rules={[{ required: true, message: 'Por favor ingrese un cargo!'}]}>
                <Input placeholder='Nombre de la red prestadora'/>
            </Item>



            <Item
                label="Dirección"
                name="direccion"
                rules={[{ required: true, message: 'Por favor ingrese un cargo!'}]}>
                <Input placeholder='Dirección' />
            </Item>


        </Col>    

        <Col xs={24} md ={8} lg={5}>
            <Item 
                label="Nombre de contacto" 
                name="nombre_contacto" 
                rules={[{ required: true, message: 'Ingrese el nombre del contacto' }]}>
                <Input placeholder='Nombre de contacto'/>
            </Item>
            <Item 
                label="Celular" 
                name="celular" 
                rules={[{ required: true, message: 'Ingrese el telefono del contacto' }]}>
                <Input placeholder='Telefono de contacto'/>
            </Item>
            <Item
                label="Teléfono"
                name="telefono"
                rules={[{ required: true, message: 'Por favor ingrese un cargo!'}]}>
                <Input placeholder='Teléfono'/>
            </Item>
        </Col>
        <Col xs={24} md ={8} lg={5}>
            <Item 
                label="Identificación de contacto" 
                name="identificacion" 
                rules={[{ required: true, message: 'Ingrese la identificación del contacto' }]}>
                <Input placeholder='Identificación de contacto'/>
            </Item>
            <Item 
             label="Email de contacto" 
             name="emailcontacto" 
             rules={[{ required: true, message: 'Por favor ingrese un email valido!', type: 'email' }]}>
             <Input placeholder='Email'/>
           </Item>
        </Col>
    </Row>
    <Row>
        <Col xs={24} md ={24} lg={4}>
        <Item label="">        
             <Button  htmlType="submit" className='btn-primary' icon={<SendOutlined />}> Enviar</Button>
            </Item>
        </Col>
    </Row>
    </Form>
    
    </>
  
  )
}
