import React, { useState } from 'react';
import 'antd/dist/antd.min.css'
import './index.css';
import PrincipalRouter from "./router/PrincipalRouter";
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App(){
  return (
    <QueryClientProvider client={queryClient}>
      <PrincipalRouter/>
    </QueryClientProvider>
  )
};
export default App;