
import React, { useState, useEffect } from 'react';

import { integrantesByGener, integrantesByGrupoEtario, integrantesByTipofamilia, totalIntegrantesJefeHogar, totalIntegrantesJefeHogarEstadocivil,
    totalIntegrantesJefeHogarGrupoEtnico, totalIntegrantesJefeHogarOcupacion
} from '../../Api/seguimiento';

function TotalCard(props) {
    const [ integrantesByGeneraInfo, setIntegrantesByGeneraInfo ] = useState([]);
    const [ integrantesByGrupoEtarioInfo, setintegrantesByGrupoEtarioInfo ] = useState([]);
    const [ integrantesByTipofamiliaInfo, setintegrantesByTipofamiliaInfo ] = useState([]);
    const [ totalIntegrantesJefeHogarInfo, seTotalIntegrantesJefeHogarInfo ] = useState([]);
    const [ totalIntegrantesJefeHogarEstadocivilInfo, seTtotalIntegrantesJefeHogarEstadocivilInfo ] = useState([]);
    const [ totalIntegrantesJefeHogarGrupoEtnicoInfo, seTtotalIntegrantesJefeHogarGrupoEtnicoInfo ] = useState([]);
    const [ totalIntegrantesJefeHogarOcupacionInfo, seTtotalIntegrantesJefeHogarOcupacionInfo ] = useState([]);

    const getRepetidos = (data, type) => {
   
        let arr = []
        data.forEach(e => {
            let filt = arr.find( (x) => x.subregiones_id === e.subregiones_id );
            if(filt){
                filt.data.push(e)
            }else{
                arr.push({ city: e.subregion, subregiones_id: e.subregiones_id, data: [e] })
            }
        });
        //console.log(type, arr);
        if(type === 'integrantesByGenera') setIntegrantesByGeneraInfo(arr) ;
        if(type === 'integrantesByGrupoEtario') setintegrantesByGrupoEtarioInfo(arr);
        if(type === 'integrantesByTipofamilia') setintegrantesByTipofamiliaInfo(arr);
        if(type === 'totalIntegrantesJefeHogar') seTotalIntegrantesJefeHogarInfo(arr);
        if(type === 'totalIntegrantesJefeHogarEstadocivil') seTtotalIntegrantesJefeHogarEstadocivilInfo(arr);
        if(type === 'totalIntegrantesJefeHogarGrupoEtnico') seTtotalIntegrantesJefeHogarGrupoEtnicoInfo(arr);
        if(type === 'totalIntegrantesJefeHogarOcupacion') seTtotalIntegrantesJefeHogarOcupacionInfo(arr);
    }


    const getAllData = async () => {
        try {
            const integrantesByGenerRes = await integrantesByGener();
            const  integrantesByGrupoEtarioRes = await integrantesByGrupoEtario();
            const integrantesByTipofamiliaRes = await integrantesByTipofamilia();
            const totalIntegrantesJefeHogarRes = await totalIntegrantesJefeHogar();
            const totalIntegrantesJefeHogarEstadocivilRes = await totalIntegrantesJefeHogarEstadocivil();
            const totalIntegrantesJefeHogarGrupoEtnicoRes = await totalIntegrantesJefeHogarGrupoEtnico();
            const totalIntegrantesJefeHogarOcupacionRes = await totalIntegrantesJefeHogarOcupacion();

            getRepetidos(integrantesByGenerRes, 'integrantesByGenera')
            getRepetidos(integrantesByGrupoEtarioRes, 'integrantesByGrupoEtario')
            getRepetidos(integrantesByTipofamiliaRes, 'integrantesByTipofamilia')
            getRepetidos(totalIntegrantesJefeHogarRes, 'totalIntegrantesJefeHogar')
            getRepetidos(totalIntegrantesJefeHogarEstadocivilRes, 'totalIntegrantesJefeHogarEstadocivil')
            getRepetidos(totalIntegrantesJefeHogarGrupoEtnicoRes, 'totalIntegrantesJefeHogarGrupoEtnico')
            getRepetidos(totalIntegrantesJefeHogarOcupacionRes, 'totalIntegrantesJefeHogarOcupacion')

        } catch (error) {
            console.error('error ->', error)
        }
    } 
    useEffect( () => {
        getAllData();
    }, [] )
   
    return (
        <>
            { props.cargo === 8  || props.cargo === 3 || props.funciones_id === 1  || props.funciones_id === 2 ?
                <div className='mb-4 p-3 bg-[#f9f9f9]'>
                    <h2 className="text-2xl font-bold mr-3 uppercase mb-4">CARACTERÍSTICAS POBLACIONALES </h2>
                    <div className={`${props.funciones_id === 1  || props.funciones_id === 2 ? 'md:grid-cols-3' : 'md:grid-cols-1'} grid sm:grid-cols-1 gap-4` } 
                        >
                        <div className="rounded-md shadow-lg p-5 bg-white cursor-pointer">
                            <p className="font-bold text-xl py-2 rounded ">Generos</p>
                            <ul className={`${props.funciones_id === 1 || props.funciones_id === 2 ? 'md:grid-cols-3' : 'md:grid-cols-5'} grid sm:grid-cols-1 gap-4'` }>
                            
                                { integrantesByGeneraInfo.map((i)=> {
                                
                                    return  <>
                                        <li className='mb-2 p-2'><span  className='font-bold'>{i.city}</span>
                                            <ul>
                                        
                                                { i.data.map( e  => <li className='mb-2 flex justify-between'> {e.genero}: <span className='text-orange font-bold ml-3'>{parseInt(e.total).toLocaleString('es-MX')}</span> </li> ) }
                                            </ul>
                                        </li>
                                    </> 
                                } ) }
                            </ul>
                        </div>

                        <div className="rounded-md shadow-lg p-5 bg-white cursor-pointer">
                            <p className="font-bold text-xl py-2 rounded ">Cursos de vida</p>
                            <ul className={`${props.funciones_id === 1 || props.funciones_id === 2 ? 'md:grid-cols-1' : 'md:grid-cols-3'} grid sm:grid-cols-1 gap-4'` } >
                                { integrantesByGrupoEtarioInfo.map((i)=> {
                                
                                    return  <>
                                        <li className='mb-2 p-2'><span  className='font-bold'>{i.city}</span>
                                            <ul>
                                                { i.data.map( e  =>  e.descripcion_curso ? 
                                                    <li className='mb-2 flex justify-between'> {e.descripcion_curso } : <span className='text-orange font-bold'>{ parseInt(e.total).toLocaleString('es-MX') }</span> </li> 
                                                : null  ) }
                                            </ul>
                                        </li>
                                    </> 
                                } ) }
                            </ul>
                        </div>
                        
                        <div className="rounded-md shadow-lg p-5 bg-white cursor-pointer">
                            <p className="font-bold text-xl py-2 rounded ">Tipos de integrantes por familia</p>
                            <ul className={`${props.funciones_id === 1 || props.funciones_id === 2 ? 'md:grid-cols-1' : 'md:grid-cols-3'} grid sm:grid-cols-1 gap-4'` } >
                                { integrantesByTipofamiliaInfo.map((i)=> {
                                
                                    return  <>
                                        <li className='mb-2 p-2'><span  className='font-bold'>{i.city}</span>
                                            <ul>
                                                { i.data.map( e  => <li className='mb-2 flex justify-between'> {e.familia_tipo ? e.familia_tipo: 'Otro' }: <span className='text-orange font-bold ml-3'>{parseInt(e.total).toLocaleString('es-MX')}</span> </li> ) }
                                            </ul>
                                        </li>
                                    </> 
                                } ) }
                            </ul>
                        </div>
                        
                        <div className="rounded-md shadow-lg p-5 bg-white cursor-pointer">
                            <p className="font-bold text-xl py-2 rounded ">Tipos Jefe de hogar</p>
                            <ul className={`${props.funciones_id === 1 || props.funciones_id === 2 ? 'md:grid-cols-1' : 'md:grid-cols-3'} grid sm:grid-cols-1 gap-4'` }>
                                { totalIntegrantesJefeHogarInfo.map((i)=> {
                                
                                    return  <>
                                        <li className='mb-2 p-2'><span  className='font-bold'>{i.city}</span>
                                            <ul>
                                                { i.data.map( e  => <li className='mb-2 flex justify-between'> {e.genero}: <span className='text-orange font-bold ml-3'>{parseInt(e.total).toLocaleString('es-MX')}</span> </li> ) }
                                            </ul>
                                        </li>
                                    </> 
                                } ) }
                            </ul>
                        </div>

                        <div className="rounded-md shadow-lg p-5 bg-white cursor-pointer">
                            <p className="font-bold text-xl py-2 rounded ">Estado civil integranes de hogar</p>
                            <ul className={`${props.funciones_id === 1 || props.funciones_id === 2 ? 'md:grid-cols-1' : 'md:grid-cols-3'} grid sm:grid-cols-1 gap-4'` }>
                                { totalIntegrantesJefeHogarEstadocivilInfo.map((i)=> {
                                
                                    return  <>
                                        <li className='mb-2 p-2'><span  className='font-bold'>{i.city}</span>
                                            <ul>
                                                { i.data.map( e  => <li className='mb-2 flex justify-between'> {e.estado_civil_descripcion ? e.estado_civil_descripcion : e.genero}: <span className='text-orange font-bold ml-3'>{parseInt(e.total).toLocaleString('es-MX')}</span> </li> ) }
                                            </ul>
                                        </li>
                                    </> 
                                } ) }
                            </ul>
                        </div>
                        
                        <div className="rounded-md shadow-lg p-5 bg-white cursor-pointer">
                            <p className="font-bold text-xl py-2 rounded ">Grupos etnicos</p>
                            <ul className={`${props.funciones_id === 1 || props.funciones_id === 2 ? 'md:grid-cols-1' : 'md:grid-cols-3'} grid sm:grid-cols-1 gap-4'` }>
                                { totalIntegrantesJefeHogarGrupoEtnicoInfo.map((i)=> {
                                
                                    return  <>
                                        <li className='mb-2 p-2'><span  className='font-bold'>{i.city}</span>
                                            <ul>
                                                { i.data.map( e  => <li className='mb-2 flex justify-between'> {e.grupo_etnico}: <span className='text-orange font-bold ml-3'>{parseInt(e.total).toLocaleString('es-MX')}</span> </li> ) }
                                            </ul>
                                        </li>
                                    </> 
                                } ) }
                            </ul>
                        </div>

                        <div className="rounded-md shadow-lg p-5 bg-white cursor-pointer">
                            <p className="font-bold text-xl py-2 rounded ">Ocupaciones</p>
                            <ul className={`${props.funciones_id === 1 || props.funciones_id === 2 ? 'md:grid-cols-1' : 'md:grid-cols-3'} grid sm:grid-cols-1 gap-4'` } >
                                { totalIntegrantesJefeHogarOcupacionInfo.map((i)=> {
                                
                                    return  <>
                                        <li className='mb-2 p-2'><span  className='font-bold'>{i.city}</span>: 
                                            <ul>
                                                { i.data.map( e  => <li className='mb-2 flex justify-between'> {e.ocupacion_descripcion}: <span className='text-orange font-bold ml-3'>{parseInt(e.total).toLocaleString('es-MX')}</span> </li> ) }
                                            </ul>
                                        </li>
                                    </> 
                                } ) }
                            </ul>
                        </div>
                        
                    </div>
                </div>
                : null
            }
        </>
    )
}

export default TotalCard