import { Collapse, Tag , Button} from 'antd';
import React, { useEffect, useState } from 'react'
import { AlertOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import { getRequestAll } from '../helpers/Api';
import MyDataTable from '../helpers/MyDataTable';
import Loading from '../helpers/Loading';
import { json, useNavigate } from 'react-router-dom';
import HeaderTitle from '../components/HeaderTitle';
import { connect } from 'react-redux';

const { Panel } = Collapse;


const Canalizacion = (props) => {
  const [dataTableIntegrante,setDataTableIntegrante]= useState([]);
  const [dataTable,setDataTable]= useState([]);
  const [loading,setLoading] = useState(false)
  const [refreshData,setRefreshData] = useState(false)
  const [exectuteRequest,setexectuteRequest] = useState(false)


  const navigate = useNavigate();

  const onChange = (key) => {
    setexectuteRequest(key[0])
  };

  const[Datacolumns] = useState([
  {title:"Ficha",dataIndex:"ficha",key:"ficha"},
  {title:"Fecha de visita",dataIndex:"fecha",key:"fecha"},
  {title:"Jefe del Hogar",dataIndex:"jefe",key:"jefe"},
  {title:"Tipo documento",dataIndex:"tipo_documento",key:"tipo_documento"},
  {title:"documento",dataIndex:"documento",key:"documento"},
  {title:"Alertas generada por el riesgo detectado",dataIndex:"alertas",key:"alertas"},
  ])

    useEffect(()=>{
        let data = [];
        let ObjNew = [];
        if(exectuteRequest =='1') 
        getRequestAll(`hogar/alertasHogar_cn`).then(res=>{
              if(res['success']){
                setRefreshData(false);
                let i =1;
           res['alertas'].forEach(element => {
                let item = ObjNew.find((e=> e.numero_ficha == element.numero_ficha));
                if(!item){
                  element.alertas = [
                    {
                      alerta:element.descripcion_alerta, 
                      estado:element.descripcion_estado, 
                      alerta_Hohar_id:element.hogar_alertas_id,
                      alerta_id:element.alerta_id,
                      riesgo:element.riesgo
                    }
                  ]
                  ObjNew.push(element)
                }else{
                    item.alertas.push({
                    alerta:element.descripcion_alerta,
                    estado:element.descripcion_estado,
                    alerta_Hohar_id:element.hogar_alertas_id,
                    alerta_id:element.alerta_id,
                    riesgo:element.riesgo
                  })
                }
            });
             ObjNew.forEach(d => {
                      data.push({
                        key: i,
                        ficha:d.numero_ficha,
                        jefe:d.jefe_hogar,
                        documento:d.documento,
                        tipo_documento:d.tipo_documento,
                        dormitorios:d.dormitorios,
                        personas:d.personas,
                        fecha:d.fecha_crea,
                        alertas:<>
                        <ol className='ol-list'>{
                          d.alertas.map((e,i)=>(
                            <li key={i} className='alert-hogar'
                             onClick={()=>{
                              let obj = {
                                ...e,
                                hogar_id: d.hogar_id,
                                numero_ficha: d.numero_ficha,
                                documento: d.documento,
                                jefe_hogar: d.jefe_hogar,
                                ciudad: d.ciudad,
                                direccion: d.direccion,
                                alerta_id: e.alerta_id
                              }
                              delete obj.direccion;
                              JSON.stringify(obj)
                              navigate(`gestion/hogar/${e.alerta_Hohar_id}/${d.hogar_id}/${d.documento}/${d.numero_ficha}/${e.alerta_id}/${JSON.stringify(obj)}/${d.direccion != "" ? d.direccion : 'NO' } `, { replace: true })
                              }}> 
                            <Tag color={'green'} key={i}>
                              <AlertOutlined/>
                             <span style={{'fontSize':'9px','fontWeight':'bold','textAlign':'left'}}>{ e.alerta} -</span>  <strong style={{'fontSize':'8.5px',color:'orange'}}> {e.estado}</strong>
                       
                            </Tag>
                            </li>
                          ))
                         }
                           </ol>
                      </>  
                      })
                      i++;
            });
  
                  setDataTable(data)
              }
          })
    },[exectuteRequest])


    const[DatacolumnsIntegrantes] = useState([
      {title:"Ficha",dataIndex:"ficha",key:"ficha"},
      {title:"Nombres y apellidos",dataIndex:"nombres",key:"nombres"},
      {title:"Tipo documento",dataIndex:"tipo_documento",key:"tipo_documento"},
      {title:"Numero de documento",dataIndex:"documento",key:"documento"},
      {title:"Alertas generada por el riesgo detectado",dataIndex:"alertas",key:"alertas"}
    ])

    const[DatacolumnsUser] = useState([{title:"Nombre",dataIndex:"name",key:"name"},
    {title:"Celular",dataIndex:"celular",key:"celular"},
    {title:"Email",dataIndex:"email",key:"email"},
    {title:"Subregión",dataIndex:"subregion",key:"subregion"},
    {title:"Hospital ejecutor ",dataIndex:"hejecutor",key:"hejecutor"},
    {title:"Municipio",dataIndex:"municipio",key:"municipio"},
    {title:"Hospital municipal",dataIndex:"red",key:"red"},
    {title:"Cargo",dataIndex:"cargo",key:"cargo"},
    {title:"Equipo de trabajo",dataIndex:"equipo",key:"equipo"},
    {title:"Total de visitas realizadas",dataIndex:"visitas",key:"visitas"},
    {title:"Integrantes registrados",dataIndex:"integrantes_registrados",key:"integrantes_registrados"},
    {title:"Acciones",dataIndex:"acciones",key:"acciones"}])
    
    useEffect(()=>{
      let data = [];
      let ObjNew = [];
      if(exectuteRequest =='2') 
      getRequestAll(`hogar/alertasIntegrante`).then(res=>{
            if(res['success']){
              setRefreshData(false);
              let i =1;
          res['alertas'].forEach(element => {
              let item = ObjNew.find((e=> e.integrante_id == element.integrante_id));
              if(!item){
                  element.alertas = [{alerta:element.descripcion_alerta,estado:element.descripcion_estado,integrante_alertas_id:element.integrante_alertas_id,alerta_id:element.alerta_id}]
                  ObjNew.push(element)
              }else{
                    item.alertas.push({alerta:element.descripcion_alerta,estado:element.descripcion_estado,integrante_alertas_id:element.integrante_alertas_id,alerta_id:element.alerta_id})
              }
          });
          // console.log(ObjNew)
            ObjNew.forEach(d => {
                data.push({
                  key: i,
                  ficha:d.numero_ficha,
                  nombres:d.nombre_completo,
                  documento:d.documento,
                  tipo_documento:d.tipo_documento,
                  dormitorios:d.dormitorios,
                  personas:d.personas,
                  fecha:d.fecha_crea,
                  alertas:<>
                  <ol className='ol-list'>{
                    d.alertas.map((e,i)=> {
                      
                      return (
                      
                      <li 
                        key={i} 
                        className='alert-hogar' 
                        onClick={()=>{ 
                          let obj = {
                            ...e,
                            ...d,
                            alerta_id: e.alerta_id
                          };
                           delete obj.direccion;
                          navigate(`gestion/integrante/${d.documento}/${d.numero_ficha}/${e.integrante_alertas_id}/${d.integrante_id}/${e.alerta_id}/${JSON.stringify(obj)}/${d.direccion != "" ? d.direccion : 'NO'  }`, { replace: true })
                        }}
                      > 
                      <Tag color={'green'} key={i}>
                        <AlertOutlined/>
                        <span style={{'fontSize':'9px','fontWeight':'bold','textAlign':'left'}}>{ e.alerta} -</span>  <strong style={{'fontSize':'8.5px',color:'orange'}}> {e.estado}</strong>
                  
                      </Tag>
                      </li>
                    )
                    })
                    }
                      </ol>
                </>  
                })
                i++;
            });

            setDataTableIntegrante(data)
            }
        })
    },[exectuteRequest])


  return (
    <> 
     
     <HeaderTitle title={"Canalización"}/>
     { loading && <Loading/>}
    <Collapse onChange={onChange}>
        <Panel header="Canalización de la vivienda" key="1">
        <MyDataTable Datacolumns={Datacolumns}  dataTable={dataTable.reverse()}/>
        </Panel>
        <Panel header="Canalización de los integrantes del hogar" key="2">
        <MyDataTable Datacolumns={DatacolumnsIntegrantes}  dataTable={dataTableIntegrante}/>
        </Panel>
    </Collapse>
    </>
  );
};

const mapStateToProps = state => state;
export default connect(mapStateToProps)(Canalizacion);