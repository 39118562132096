import { v4 } from "uuid";
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined,SendOutlined } from '@ant-design/icons';
import { Divider, Radio,Form,Input,Button, Select,Checkbox, Col,Row,Typography, Collapse, Tag   } from 'antd';
import { getRequestAll,getRequestById,getUserData,postRequest } from '../helpers/Api';
import Loading from '../helpers/Loading';
import { useNavigate, Link } from 'react-router-dom';
import openNotificationWithIcon from '../helpers/Notification';
import HeaderTitle from '../components/HeaderTitle';
const { Panel } = Collapse;

export const IdentificacionHogar = (props) => {
    let uiid = v4() ;
    const {Item} = Form;
    const {Title} = Typography;
    const { Option } = Select;
    const [form] = Form.useForm();
    const [familiatipo,setFamiliaTipo] = useState([])
    const [complemento,setComplemento] = useState([])
    const [nomenclatura,setNomenclatura] = useState([])
    const [familiaIndigena,SetFamiliaIndigena] = useState([])
    const [tipovivienda,SetTipoVivienda] = useState([])
    const [tenenciaVivienda,SetTenenciaVivienda] = useState([])
    const [caracteristicas,SetCaracteristicas] = useState([])

    const [optionsEspacioVital,setOptionsEspacioVital] = useState([])
    const [optionsAguaPotable,setOptionsAguaPotable] = useState([])
    const [optionsAguaResidual,setOptionAguaResidual] = useState([])
    const [optionsResiduosSolidos,setOptionAguaResiduosSolidos] = useState([])
    const [optionsManejoPlagas,setOptionsManejoPlagas] = useState([])
    const [optionsAlimnetosHigine,setAlimentosHigiene] = useState([])
    const [optionsRiesgosQuimicos,setOtionsRiesgosQuimicos] = useState([])
    const [optionsNuestrasMascotas,setOptionsNuestrasMascotas] = useState([])
    const [centros,setCentros] = useState([])

    const [loading,setLoading] = useState(false)
    const [familiaPertenece,setFamiliaPertenece] = useState(false)
    const [subsidio,setSubsidio] = useState(false)
    const [load,setLoad] = useState(false)
    const [disabled,setdisabled] = useState(false)
    const [disablevivienda,setVivienda] = useState(false)
    const [disabletenencia,setTenencia] = useState(false)
    const [complementoDesc,setComplementoDesc] = useState(false)
    const [showCabecera] = useState(getUserData()['territorio'])

    const [isespaciovital,setIsEspaciovital] = useState(0)
    const [isaguapotable,setIsAguaPotable] = useState(0)
    const [isaguaresidual,setIsAguaResidual] = useState(0)
    const [isresiduossolidos,setIsResiduosSolidos] = useState(0)
    const [isManejoDeplagas,setIsManejoPlagas] = useState(0)
    const [isAlimentosHigiene,setIsAlimentosHigiene] = useState(0)
    const [isRiesgoQuimico,setIsRiesgoQuimico] = useState(0)
    const [isMascotas,setIsMascotas] = useState(0)
    const [istenencia_animales,setIsAnimales] = useState(0)
    const [is_perros,setIsPerros] = useState(0)
    const [is_perros_vacunados,setIsPerrosVacunados] = useState(0)
    const [is_gatos,setIsGatos] = useState(0)
    const [is_gatos_vacunados,setIsGatosVacunados] = useState(0)
    const [is_otro,setIsOtro] = useState(0)
    const [is_bovino,setBovino] = useState(0)
    const [is_porcino,setPorcino] = useState(0)
    const [is_ovino,setOvino] = useState(0)
    const [is_exotico,setExotico] = useState(0)
    const [is_caprino,setCaprino] = useState(0)
    const [is_equino,setEquino] = useState(0)
    const [is_especies,setEspecies] = useState(0)
    const [intialValue, setintialValue] = useState()
    const [coords, setCoords] = useState(null)
    const navigate = useNavigate();


    const getLocationUser = () => {
        navigator.geolocation.getCurrentPosition( (position) => {
            const {coords} = position;
            setCoords({
                latitude: coords.latitude,
                longitude: coords.longitude
            })
        });
    };

    useEffect(()=>{

        getRequestAll('generics/complemento').then(res=>{
            if(res['success'])
            setComplemento(res['data']['complemento'])
        })
        getRequestAll('generics/nomenclatura').then(res=>{
            if(res['success'])
            setNomenclatura(res['data']['nomenclatura'])
        })
        getRequestAll('generics/familiatipo').then(res=>{
            if(res['success'])
            setFamiliaTipo(res['data']['familiatipo'])
        })
        getRequestAll('generics/familiaindigena').then(res=>{
            if(res['success'])
            SetFamiliaIndigena(res['data']['familia'])
        })
        getRequestAll('generics/tipovivienda').then(res=>{
            if(res['success'])
            SetTipoVivienda(res['data']['tipo'])
        })
        getRequestAll('generics/tenencia').then(res=>{
            if(res['success'])
            SetTenenciaVivienda(res['data']['tenencia'])
        })
        getRequestAll('generics/subsidio').then(res=>{
            if(res['success'])
            SetCaracteristicas(res['data']['subsidio'])
        })

        getRequestAll(`generics/centrosBycity?id=${getUserData()['ciudad_id']}`).then(res=>{
            const {centros} = res['data'];
            if(res['success']) setCentros(centros);
        });

        getRequestById(1,`generics/riesgosHogar`).then(res=>{
    
            const {riesgos} = res['data'];
            let riesgo = [];
            if(res['success']){
                setOptionsEspacioVital(riesgos);
                riesgos.forEach(r => {
                riesgo.push(r.riesgo_id)
            });
                form.setFieldsValue(
                    {
                    espaciovital:riesgo,
                    is_espaciovital:isespaciovital,
                    }
                )

            }
        });
        getRequestById(2,`generics/riesgosHogar`).then(res=>{
    
            const {riesgos} = res['data'];
            let riesgo = [];
            if(res['success']){
                setOptionsAguaPotable(riesgos);
                riesgos.forEach(r => {
                riesgo.push(r.riesgo_id)
            });
                form.setFieldsValue({
                    aguapotable:riesgo,
                    is_aguapotable:isespaciovital
                })

            }
        });
        getRequestById(3,`generics/riesgosHogar`).then(res=>{
    
            const {riesgos} = res['data'];
            let riesgo = [];
            if(res['success']){
                setOptionAguaResidual(riesgos);
                riesgos.forEach(r => {
                riesgo.push(r.riesgo_id)
            });
                form.setFieldsValue(
                    {
                        aguasresiduales:riesgo,
                        is_aguaresidual:isaguaresidual
                    })

            }
        });
        getRequestById(4,`generics/riesgosHogar`).then(res=>{
    
            const {riesgos} = res['data'];
            let riesgo = [];
            if(res['success']){
                setOptionAguaResiduosSolidos(riesgos);
                riesgos.forEach(r => {
                riesgo.push(r.riesgo_id)
            });
                form.setFieldsValue({
                    residuossolidos:riesgo,
                    is_residuossolidos:isresiduossolidos
                })

            }
        });
        getRequestById(5,`generics/riesgosHogar`).then(res=>{
    
            const {riesgos} = res['data'];
            let riesgo = [];
            if(res['success']){
                setOptionsManejoPlagas(riesgos);
                riesgos.forEach(r => {
                riesgo.push(r.riesgo_id)
            });
                form.setFieldsValue({
                    manejoplagas:riesgo,
                    is_manejoplagas:isManejoDeplagas
                })

            }
        });
        getRequestById(6,`generics/riesgosHogar`).then(res=>{
    
            const {riesgos} = res['data'];
            let riesgo = [];
            if(res['success']){
                setAlimentosHigiene(riesgos);
                riesgos.forEach(r => {
                riesgo.push(r.riesgo_id)
            });
                form.setFieldsValue({
                    alimentoshigiene:riesgo,
                    is_higienealimentos:isAlimentosHigiene
                })

            }
        });
        getRequestById(7,`generics/riesgosHogar`).then(res=>{
    
            const {riesgos} = res['data'];
            let riesgo = [];
            if(res['success']){
                setOtionsRiesgosQuimicos(riesgos);
                riesgos.forEach(r => {
                riesgo.push(r.riesgo_id)
            });
                form.setFieldsValue({
                    riesgoquimico:riesgo,
                    is_riesgoquimico:isRiesgoQuimico
                })

            }
        });
        getRequestById(8,`generics/riesgosHogar`).then(res=>{
    
            const {riesgos} = res['data'];
            let riesgo = [];
            if(res['success']){
                setOptionsNuestrasMascotas(riesgos);
                riesgos.forEach(r => {
                riesgo.push(r.riesgo_id)
            });
                form.setFieldsValue({
                    nuestrasmascotas:riesgo,
                    is_nuestrasmascota:isMascotas,
                    is_perros:is_perros,
                    is_perros_vacunados:is_perros_vacunados,
                    is_gatos_vacunados:is_gatos_vacunados,
                    is_gatos:is_gatos,
                    is_otro:is_otro,
                    istenencia_animales:istenencia_animales,
                })

            }
        });
    },[load])

    useEffect( () => {
        if( props.isPreview === true ){
            const objProps = props.info[0];
           // console.log('info ->', objProps)
           // console.log(form)
            //
            form.setFieldsValue({
                tipovivienda_id: objProps.vivienda_tipo_id,
                otro_tipo: objProps.tenencia_id,
                tenenciavivienda_id: objProps.tenencia_id,
                tenencia_otro: '',
                familia_tipo_id: objProps.familia_tipo_id,
                barrio: objProps.barrio,
                nomenclatura: objProps.tipo_direccion_id,
                numero: objProps.no,
                direccion_cabecera: objProps.direccion_cabecera,
                letra: '',
                bis: '',
                _letra: '',
                numero_extra: '',
                letra_extra: '',
                bis_extra: '',
                _letra_extra: '',
                complemento: objProps.complemento_id,
                descripcion_complemento: objProps.desc_complemento,
                centro_poblado: '',
                direccion: '',
                dormitorios: objProps.dormitorios,
                personas: objProps.personas,
                recibe_aporte: objProps.recibe_aporte,
                cuales: ''
            })
        }
        getLocationUser();
    }, [] )
    const setRiesgosHogar=(obj,riesgos)=>{
        let riesgosDetectados = [];
        // fore
        obj.forEach(item => {
            let riesgo = riesgos.find(e=> e === item.riesgo_id)
            if(riesgo === undefined)
            riesgosDetectados.push(item.riesgo_id)
        });
        return JSON.stringify(riesgosDetectados);
    }

    const onFinish  = (p) => {
       
        if(p.is_espaciovital === 1)
        p.espaciovital = setRiesgosHogar(optionsEspacioVital, p.espaciovital);
        if(p.is_aguapotable === 1)
        p.aguapotable = setRiesgosHogar(optionsAguaPotable, p.aguapotable);
        if(p.is_aguaresidual === 1)
        p.aguasresiduales = setRiesgosHogar(optionsAguaResidual, p.aguasresiduales);
        if(p.is_residuossolidos === 1)
        p.residuossolidos = setRiesgosHogar(optionsResiduosSolidos, p.residuossolidos);
        if(p.is_manejoplagas === 1)
        p.manejoplagas = setRiesgosHogar(optionsManejoPlagas, p.manejoplagas);
        if(p.is_higienealimentos === 1)
        p.alimentoshigiene = setRiesgosHogar(optionsAlimnetosHigine, p.alimentoshigiene);
        if(p.is_riesgoquimico === 1)
        p.riesgoquimico = setRiesgosHogar(optionsRiesgosQuimicos, p.riesgoquimico);
        if(p.is_nuestrasmascota === 1)
        p.nuestrasmascotas = setRiesgosHogar(optionsNuestrasMascotas, p.nuestrasmascotas);

        setLoading(true)
        setdisabled(true)
        if(showCabecera === 1)
        p.direccioncabecera = `${p.numero || ''} ${p.letra || ''} ${p.bis || ''} ${p._letra || ''} ${p.numero_extra || ''} ${p.letra_extra || ''} ${p.bis_extra || ''} ${p._letra_extra || ''}`;
        if(p.cuales!== undefined)
        p.cuales = JSON.stringify(p.cuales);
        p.uiid = uiid;
        p.hogar_id = uiid;
        if(coords != null){  
            p = {
                ...p,
                ...coords
            }
         }

        
        postRequest("hogar/",p).then(res=>{
            setTimeout(() => {
            if(res['success']){
                openNotificationWithIcon("success","Exito al crear la identificacion del hogar!!","Se registró con exito la información del hogar")
                form.resetFields();
                setLoad(!load)
                setdisabled(false)
                setTimeout(()=>{
                    navigate(`/panel/vivienda/integrante/registro/${res['Hogar']['id']}/${uiid}`, { replace: true })
                },200)
            }
            setLoading(false)
            }, 1000);
        }).catch(err=>{
            setLoading(false)
        })
        
    };
    
    const isFamiliaIndigena = (v)=>{
        setFamiliaPertenece(v);
    }
    const isVivienda = (v)=>{
        if(v === 4)
        setTenencia(true);
       else setTenencia(false)
    }
    const TipoVivienda = (v)=>{
        if(v === 14)
        setVivienda(true);
       else setVivienda(false)
    }
    const Complemento = (v)=>{
        if(v !== "")
        setComplementoDesc(true);
       else setComplementoDesc(false)
    }

    const onFormLayoutChange = ({ disabled }) => {
        // setComponentDisabled(disabled);
    };



    return (
     <>
        <HeaderTitle title={"IDENTIFICACION DEL HOGAR"}/>
        <Form 
            layout="vertical"
            form={form}
            onFinish={onFinish}
            disabled={ props.isPreview === true ? true : false} 
            // onValuesChange={onFormLayoutChange}
            >
            <Row>
                <Col xs={24} md={24} lg={24}>

                <br/>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md ={8} lg={9}>
                            <Item 
                                label="Tipo de vivienda" 
                                name="tipovivienda_id">
                                    <Select placeholder="Seleccione..." onChange={(v)=>{TipoVivienda(v)}}>
                                    {tipovivienda.length > 0 && tipovivienda.map((op,i)=>(<Option   key={`p${i}`} value={op.vivienda_tipo_id}>{op.vivienda_tipo_descripcion}</Option> ))}  
                                    </Select>
                            </Item>
                    </Col>
                    {disablevivienda && <Col xs={24} md ={8} lg={7}>
                            <Item 
                                label="Descripcion otro tipo de vivienda" 
                                name="otro_tipo"
                                >
                            <Input  placeholder='Otro/cual'/>
                            </Item>
                    </Col>}
                        
                    <Col xs={24} md ={8} lg={4}>
                        <Item 
                            label="Tenencia de la vivienda" 
                            name="tenenciavivienda_id"
                            >
                            <Select placeholder="Seleccione..." onChange={(v)=>{isVivienda(v)}}>
                            {tenenciaVivienda.length > 0 && tenenciaVivienda.map((op,i)=>(<Option   key={`p${i}`} value={op.tenencia_id}>{op.tenencia_descripcion}</Option> ))}  
                            </Select>
                        </Item>
                    </Col>
                    {  disabletenencia && <Col xs={24} md ={8} lg={4}>
                        <Item label="Cual" name="tenencia_otro">
                            <Input  placeholder='Tenencia de la vivienda otro' />
                        </Item>
                    </Col>
                    }
                    <Col xs={24} md={24} lg={6}>
                        <Item 
                            label="Tipo de familia" 
                            name="familia_tipo_id" 
                            rules={[{ required: true, message: 'Por favor seleccione el tipo de familia' }]}>
                                <Select placeholder="Seleccione...">
                                        {familiatipo.length > 0 && familiatipo.map((op,i)=>(<Option   key={`p${i}`} value={op.familia_tipo_id}>{op.familia_tipo_descripcion}</Option> ))}
                                </Select>
                        </Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                { showCabecera === 1 &&<>

                <Col xs={24} md={6} lg={7}>
                    <Item 
                        label="Barrio" 
                        name="barrio" 
                        rules={[{ required: true, message: 'este campo es oblogatorio' }]}>
                        <Input placeholder='Barrio' />
                    </Item>
                </Col> 
                {
                    props.isPreview === true ? 
                    <>
                    <Col xs={24} md ={24} lg={7}>
                    <Item 
                        label="Direccion" 
                        name="direccion_cabecera" 
                        rules={[{ required: true, message: 'este campo es oblogatorio' }]}>
                        <Input placeholder='Direccion' />
                    </Item>
                    </Col>
                  
                    </>
                    :
                    <>
                    <Col xs={24} md ={24} lg={7}>
                        <Item 
                            label="Dirección" 
                            name="nomenclatura" 
                            rules={[{ required: true, message: 'Por favor seleccione Dirección para cabecera municipal' }]}>
                                <Select placeholder="Seleccione...">
                                {nomenclatura.length > 0 && nomenclatura.map((op,i)=>(<Option   key={`p${i}`} value={op.tipo_direccion_id}>{op.direccion_tipo}</Option> ))}  
                                </Select>
                        </Item>
                    </Col>
                
                    <Col xs={24} md={6} lg={2}>
                        <Item 
                            label="Numero" 
                            name="numero" 
                            rules={[{ required: true, message: 'este campo es oblogatorio' }]}>
                            <Input placeholder='Numero' />
                        </Item>
                    </Col>    

                    <Col xs={24} md={6} lg={2}>
                        <Item 
                            label="Letra" 
                            name="letra" >
                            <Input placeholder='Letra'  />
                        </Item>
                    </Col>    

                    <Col xs={24} md={6} lg={2}>

                        <Item 
                            label="Bis" 
                            name="bis" >
                            <Input placeholder='Bis'  />
                        </Item>

                    </Col>    

                    <Col xs={24} md={6} lg={2}>
                        <Item 
                                label="Letra" 
                                name="_letra">
                                <Input placeholder='Letra'  />
                        </Item>
                    </Col>  

                    <Col xs={24} md={6} lg={2}>

                        <Item 
                            label="Numero" 
                            name="numero_extra">
                            <Input placeholder='Numero' />
                        </Item>

                    </Col>    

                    <Col xs={24} md ={6} lg={2}>
                        <Item 
                            label="Letra" 
                            name="letra_extra">
                            <Input placeholder='Letra'  />
                        </Item>
                    </Col>    

                    <Col xs={24} md ={6} lg={2}>
                        <Item 
                            label="Bis" 
                            name="bis_extra">
                            <Input placeholder='Bis'/>
                        </Item>
                    </Col>   

                    <Col xs={24} md={6} lg={2}>
                        <Item 
                            label="Letra" 
                            name="_letra_extra">
                            <Input placeholder='Letra'  />
                        </Item>
                    </Col> 
                    </>
                }
                
                
                <Col xs={24} md ={24} lg={4}>
                    <Item 
                        label="Complemento" 
                        name="complemento">
                            <Select placeholder="Seleccione..." onChange={(v)=>{Complemento(v)}}>
                            {complemento.length > 0 && complemento.map((op,i)=>(<Option   key={`p${i}`} value={op.complemento_id}>{op.complemento_descripcion}</Option> ))}  
                        </Select>
                    </Item>
                </Col>

                    { complementoDesc === true && <Col xs={24} md ={24} lg={6}>
                            <Item 
                                label="Descripción" 
                                name="descripcion_complemento">
                            <Input placeholder='Complemento'/>
                            </Item>
                        </Col>
                    }
                    </> } 
                    {showCabecera === 2 &&  <>
                        <Col xs={24} md ={24} lg={6}>
                            <Item 
                                label="Centro poblado" 
                                name="centro_poblado" 
                                rules={[{ required: true, message: 'Por favor seleccione el centro poblado' }]}>
                                    <Select placeholder="Seleccione...">
                                    {centros.length > 0 && centros.map((op,i)=>(<Option   key={`p${i}`} value={op.centro_poblado_id}>{op.centro_poblado}</Option> ))}  
                                    </Select>
                            </Item>
                        </Col>
                        </> }
                    {showCabecera > 1 &&  <>
                        <Col xs={24} md ={24} lg={12}>
                            <Item 
                                label="Dirección para centro poblado / rural disperso" 
                                name="direccion"
                                rules={[{ required: true, message: 'este campo es oblogatorio' }]}>
                                <Input placeholder='Dirección'/>
                            </Item>
                        </Col>
                    </> }
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md ={24} lg={6}>
                        <Item 
                            label="Número de Dormitorios" 
                            name="dormitorios"
                            rules={[{ required: true, message: 'este campo es oblogatorio' }]}>
                            <Input placeholder='Numero de dormitorios'/>
                        </Item>
                    </Col>
                    <Col xs={24} md ={24} lg={6}>
                        <Item 
                            label="Número de personas" 
                            name="personas"
                            rules={[{ required: true, message: 'este campo es oblogatorio' }]}>
                            <Input placeholder='Número de personas'/>
                        </Item>
                    </Col>
                </Row>
        
                <Row>
                    <Col xs={24} md ={8} lg={24}>
                        <Item 
                            label="LA FAMILIA RECIBE ALGÚN SUBSIDIO O APORTE DE ALGUNA INSTITUCIÓN DE ORDEN NACIONAL O DISTRITAL" 
                            name="recibe_aporte"
                            rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Radio.Group value={0} onChange={(e)=>setSubsidio(e.target.value)}>
                                <Radio value={1}>SI</Radio>
                                <Radio value={0}>NO</Radio>
                            </Radio.Group>
                        </Item>
                    </Col>
                { subsidio === 1 &&  
                    <Col xs={24} md ={24} lg={24}>
                        <Item 
                            label="Cuál / Cuáles" 
                            name="cuales" 
                            rules={[{ required: true, message: 'este campo es requerido!' }]}>
                                <Select mode="multiple" placeholder="Seleccione...">
                                        {caracteristicas.length > 0 && caracteristicas.map((op,i)=>(<Option  key={`p${i}`} value={op.otras_caracteristicas_id}>{op.caracteristicas}</Option> ))}
                                </Select>
                        </Item>
                    </Col>
                }    
                </Row>
            </Col>
            </Row>
            { props.isPreview === true ?
                <div className="p-3 bg-white">
                    <h4 className="mb-2 font-semibold text-[19px]">Lista de riesgos</h4>
                    <ul className="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
                        {  props.listIresgos.length > 0 && props.listIresgos.map( (i, index) =>  <li  className="p-3 rounded-md flex bg-gray1" key={index.toString()}> <span className="bg-orange text-white p-2 rounded-md w-[30px] h-[30px] flex justify-center items-center mr-1">
                        {index + 1}</span> {i.riesgo}</li> 
                        )}
                    </ul>
                </div>
                : 
                <>
                <Collapse>
                    <Panel header="RIESGOS AMBIENTALES EN LA VIVIENDA" key="1">
                        <HeaderTitle title={"RIESGOS AMBIENTALES EN LA VIVIENDA"}/> 
                        <Row  gutter={[16, 16]}>         
                                <Col xs={24} lg={24}>
                                    <Item  
                                                label="Existe riesgo en el espacio vital - EV" 
                                                name="is_espaciovital" 
                                                rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                            <Radio.Group value={0} onChange={(e)=>{setIsEspaciovital(e.target.value)}}>
                                                <Radio value={1}>SI</Radio>
                                                <Radio value={0}>NO</Radio>
                                            </Radio.Group>
                                    </Item>
                            { isespaciovital === 1 &&
                                    <Item
                                        label="Espacio vital - EV"
                                            name="espaciovital"
                                            rules={[{ required: true, message: 'Por favor ingrese minimo un centro poblado!'}]}>
                                                <Select
                                                    mode="multiple"
                                                    style={{
                                                    width: '100%',
                                                    }}
                                                    placeholder=""
                                                    onChange={()=>{}}
                                                    // optionLabelProp="label"
                                                    allowClear>
                                                    {optionsEspacioVital.length > 0 && optionsEspacioVital.map((op,i)=>(<Option key={i} value={(op.riesgo_id)}><div className="demo-option-label-item">{op.riesgo}</div></Option>))}
                                                </Select>
                                    </Item>
                            }
                                </Col>
                                <Divider/>
                                <Col xs={24} lg={24}>
                                    <Item  
                                        label="Existe riesgo en el consumo de agua potable -AP" 
                                        name="is_aguapotable" 
                                        rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Radio.Group value={0} onChange={(e)=>{setIsAguaPotable(e.target.value)}}>
                                        <Radio value={1}>SI</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                    </Item>
                                { isaguapotable === 1 &&  
                                        <Item
                                            label="Consumo de agua potable - AP"
                                                name="aguapotable"
                                                rules={[{ required: true, message: 'Por favor ingrese minimo uno'}]}>
                                                    <Select
                                                        mode="multiple"
                                                        style={{
                                                        width: '100%',
                                                        }}
                                                        placeholder=""
                                                        onChange={()=>{}}
                                                        // optionLabelProp="label"
                                                        allowClear>
                                                        {optionsAguaPotable.length > 0 && optionsAguaPotable.map((op,i)=>(<Option key={i} value={(op.riesgo_id)}><div className="demo-option-label-item">{op.riesgo}</div></Option>))}
                                                    </Select>
                                    </Item>}
                                </Col>
                                <Divider/>
                                <Col xs={24} lg={24}>
                                    <Item  
                                        label="Existe riesgo en el manejo de aguas residuales - AR" 
                                        name="is_aguaresidual" 
                                        rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Radio.Group value={0} onChange={(e)=>{setIsAguaResidual(e.target.value)}}>
                                        <Radio value={1}>SI</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                    </Item>
                                    { isaguaresidual === 1 &&
                                    <Item
                                        label="Manejo de aguas residuales -AR"
                                            name="aguasresiduales">
                                                <Select
                                                    mode="multiple"
                                                    style={{
                                                    width: '100%',
                                                    }}
                                                    placeholder=""
                                                    onChange={()=>{}}
                                                    // optionLabelProp="label"
                                                    allowClear>
                                                    {optionsAguaResidual.length > 0 && optionsAguaResidual.map((op,i)=>(<Option key={i} value={(op.riesgo_id)}><div className="demo-option-label-item">{op.riesgo}</div></Option>))}
                                                </Select>
                                    </Item>
                                }
                                </Col>
                                <Divider/>
                                <Col xs={24} lg={24}>
                                <Item  
                                        label="Existe riesgo en el manejo de residuos sólidos - RS" 
                                        name="is_residuossolidos" 
                                        rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Radio.Group value={0} onChange={(e)=>{setIsResiduosSolidos(e.target.value)}}>
                                        <Radio value={1}>SI</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                    </Item>
                                { isresiduossolidos === 1 &&
                                    <Item
                                        label="Residuos sólidos - RS"
                                            name="residuossolidos"
                                            rules={[{ required: true, message: 'Por favor ingrese minimo uno'}]}>
                                                <Select
                                                    mode="multiple"
                                                    style={{
                                                    width: '100%',
                                                    }}
                                                    placeholder=""
                                                    onChange={()=>{}}
                                                    // optionLabelProp="label"
                                                    allowClear>
                                                    {optionsResiduosSolidos.length > 0 && optionsResiduosSolidos.map((op,i)=>(<Option key={i} value={(op.riesgo_id)}><div className="demo-option-label-item">{op.riesgo}</div></Option>))}
                                                </Select>
                                        </Item>
                                }
                                </Col>
                                <Divider/>
                                <Col xs={24} lg={24}>
                                <Item  
                                        label="Existe riesgo en el manejo de plagas - MP" 
                                        name="is_manejoplagas" 
                                        rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Radio.Group value={0} onChange={(e)=>{setIsManejoPlagas(e.target.value)}}>
                                        <Radio value={1}>SI</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                    </Item>
                                    { isManejoDeplagas === 1 &&
                                    <Item
                                        label="Manejo de plagas - MP"
                                            name="manejoplagas"
                                            rules={[{ required: true, message: 'Por favor ingrese minimo uno'}]}>
                                                <Select
                                                    mode="multiple"
                                                    style={{
                                                    width: '100%',
                                                    }}
                                                    placeholder=""
                                                    onChange={()=>{}}
                                                    // optionLabelProp="label"
                                                    allowClear>
                                                    {optionsManejoPlagas.length > 0 && optionsManejoPlagas.map((op,i)=>(<Option key={i} value={(op.riesgo_id)}><div className="demo-option-label-item">{op.riesgo}</div></Option>))}
                                                </Select>
                                        </Item>
                                    }
                                </Col>
                                <Divider/>
                                <Col xs={24} lg={24}>
                                <Item  
                                        label="Existe riesgo en los alimentos e higiene - AR" 
                                        name="is_higienealimentos" 
                                        rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Radio.Group value={0} onChange={(e)=>{setIsAlimentosHigiene(e.target.value)}}>
                                        <Radio value={1}>SI</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                    </Item>
                                    { isAlimentosHigiene === 1 &&
                                        <Item
                                            label="Alimentos e higiene - AH"
                                                name="alimentoshigiene"
                                                rules={[{ required: true, message: 'Por favor ingrese minimo uno'}]}>
                                                    <Select
                                                        mode="multiple"
                                                        style={{
                                                        width: '100%',
                                                        }}
                                                        placeholder=""
                                                        onChange={()=>{}}
                                                        // optionLabelProp="label"
                                                        allowClear>
                                                        {optionsAlimnetosHigine.length > 0 && optionsAlimnetosHigine.map((op,i)=>(<Option key={i} value={(op.riesgo_id)}><div className="demo-option-label-item">{op.riesgo}</div></Option>))}
                                                    </Select>
                                        </Item>
                                    }
                                </Col>
                                <Divider/>
                                <Col xs={24} lg={24}>
                                    <Item  
                                                label="Conoce los riesgos químicos en el hogar - RQ" 
                                                name="is_riesgoquimico" 
                                                rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                            <Radio.Group value={0} onChange={(e)=>{setIsRiesgoQuimico(e.target.value)}}>
                                                <Radio value={1}>SI</Radio>
                                                <Radio value={0}>NO</Radio>
                                            </Radio.Group>
                                        </Item>
                                {isRiesgoQuimico === 1  &&
                                        <Item
                                                label="Nuestras mascotas - NM"
                                                name="riesgoquimico"
                                                rules={[{ required: true, message: 'Por favor ingrese minimo uno'}]}>
                                                    <Select
                                                        mode="multiple"
                                                        style={{
                                                        width: '100%',
                                                        }}
                                                        placeholder=""
                                                        onChange={()=>{}}
                                                        // optionLabelProp="label"
                                                        allowClear>
                                                        {optionsRiesgosQuimicos.length > 0 && optionsRiesgosQuimicos.map((op,i)=>(<Option key={i} value={(op.riesgo_id)}><div className="demo-option-label-item">{op.riesgo}</div></Option>))}
                                                    </Select>
                                            </Item>
                                    }
                                </Col>
                                <Divider/>

                        </Row>
                    </Panel>    
                    <Panel header="USO DE LOS ESPACIOS DE LA VIVIENDA" key="2">
                            <HeaderTitle title={"USO DE LOS ESPACIOS DE LA VIVIENDA"}/>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} lg={24}>
                                        <Item  
                                            label="Tiene mascotas" 
                                                    name="is_nuestrasmascota" 
                                                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                                <Radio.Group value={0} onChange={(e)=>{setIsMascotas(e.target.value)}}>
                                                    <Radio value={1}>SI</Radio>
                                                    <Radio value={0}>NO</Radio>
                                                </Radio.Group>
                                            </Item>
                                    {isMascotas === 1  &&
                                    <>
                                        <Item
                                            label="Nuestras mascotas - NM"
                                            name="nuestrasmascotas"
                                            rules={[{ required: true, message: 'Por favor ingrese minimo uno'}]}>
                                                <Select
                                                    mode="multiple"
                                                    style={{
                                                    width: '100%',
                                                    }}
                                                    placeholder=""
                                                    onChange={()=>{}}
                                                    // optionLabelProp="label"
                                                    allowClear>
                                                    {optionsNuestrasMascotas.length > 0 && optionsNuestrasMascotas.map((op,i)=>(<Option key={i} value={(op.riesgo_id)}><div className="demo-option-label-item">{op.riesgo}</div></Option>))}
                                                </Select>
                                        </Item>
                                        <Divider/>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={3}>
                                            <Item label="Perros"  name="is_perros"   rules={[{ required: true, message: 'este campo requerido' }]}>
                                                <Radio.Group 
                                                    onChange={(v)=>{
                                                        if(v.target.value === 1){
                                                            setIsPerros(1)
                                                        }else{
                                                            setIsPerros(0)
                                                        }
                                                    }
                                                    }>
                                                    <Radio value={1}>SI</Radio>
                                                    <Radio value={0}>NO</Radio>
                                                </Radio.Group>
                                            </Item>
                                        </Col>
                                        { is_perros === 1 && <>
                                                        <Col xs={24} lg={3}>
                                                            <Form.Item 
                                                                    label="Cuántos"
                                                                    name="cantperros">
                                                                    <Input style={{width:'100%'}} />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col xs={24} lg={3}>
                                                            <Item 
                                                                label="Vacunados"
                                                                name="perros_vacunados" rules={[{ required: true, message: 'este campo requerido' }]}>
                                                                <Radio.Group  onChange={(v)=>{
                                                        if(v.target.value === 1){
                                                            setIsPerrosVacunados(1)
                                                        }else{
                                                            setIsPerrosVacunados(0)
                                                        }
                                                    }
                                                    }>
                                                    <Radio value={1}>SI</Radio>
                                                    <Radio value={0}>NO</Radio>
                                                </Radio.Group>
                                                            </Item>
                                                        </Col>
                                                    { is_perros_vacunados == 1 &&
                                                        <Col xs={24} lg={3}>
                                                            <Form.Item 
                                                            label="Cuántos"
                                                            name="cant_perros_vacunados" rules={[{ required: true, message: 'este campo requerido' }]}>
                                                            <Input style={{width:'100%'}} />
                                                        </Form.Item>
                                                        </Col>
                                                        }
                                            </>}    
                                    </Row>
                                    <Divider/>
                                <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={3}>
                                            <Item label="Gatos"  name="is_gatos" rules={[{ required: true, message: 'este campo requerido' }]}>
                                                <Radio.Group 
                                                name="is_gatos" onChange={(v)=>{
                                                    if(v.target.value === 1){
                                                        setIsGatos(1)
                                                    }else{
                                                        setIsGatos(0)
                                                    }
                                                }}>
                                                    <Radio value={1}>SI</Radio>
                                                    <Radio value={0}>NO</Radio>
                                                </Radio.Group>
                                            </Item>
                                            </Col>
                                        {is_gatos === 1 && <>
                                            <Col xs={24} lg={3}>
                                                <Form.Item 
                                                label="Cuántos"
                                                name="cant_gatos">
                                                <Input style={{width:'100%'}} />
                                            </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={3}>
                                                <Form.Item 
                                                label="Vacunados"
                                                name="gatos_vacunados" rules={[{ required: true, message: 'este campo requerido' }]}>
                                                <Radio.Group
                                                    name="gatos_vacunados" onChange={(v)=>{
                                                        if(v.target.value === 1){
                                                            setIsGatosVacunados(1)
                                                        }else{
                                                            setIsGatosVacunados(0)
                                                        }
                                                    }}>
                                                        <Radio value={1}>SI</Radio>
                                                        <Radio value={0}>NO</Radio>
                                                    </Radio.Group>
                                            </Form.Item>
                                            </Col>
                                            { is_gatos_vacunados === 1 &&
                                            <Col xs={24} lg={3}>
                                                <Form.Item 
                                                label="Cuántos"
                                                name="cant_gatos_vacunados" rules={[{ required: true, message: 'este campo requerido' }]}>
                                                <Input style={{width:'100%'}} />
                                            </Form.Item>
                                            </Col>
                                        }
                                            </>}  
                                </Row>
                                <Divider/>
                                    <Row gutter={[16, 16]}>
                                    
                                            <Col  xs={24}  lg={3}>

                                                <Item label="Otro" name="is_otro">
                                                    <Radio.Group
                                                    name="otro"  onChange={(v)=>{
                                                        if(v.target.value === 1){
                                                            setIsOtro(1)
                                                        }else{
                                                            setIsOtro(0)
                                                        }
                                                    }}>
                                                        <Radio value={1}>SI</Radio>
                                                        <Radio value={0}>NO</Radio>
                                                    </Radio.Group>
                                                </Item>
                                            </Col>
                                        { is_otro === 1 &&
                                            <Col xs={24} lg={6}>
                                                <Form.Item 
                                                    label="Cual"
                                                    name="cual">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        } 
                                    </Row>
                                </>}
                                </Col>
                            </Row>
                            <Divider/>
                            <Row gutter={[16, 16]}>    
                                <Item  
                                    label="Tenencia de animales" 
                                            name="istenencia_animales" 
                                            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                        <Radio.Group value={0} onChange={(e)=>{setIsAnimales(e.target.value)}}>
                                            <Radio value={1}>SI</Radio>
                                            <Radio value={0}>NO</Radio>
                                        </Radio.Group>
                                </Item>
                            </Row>
                            {istenencia_animales === 1 && <>
                            <Row gutter={[16, 16]}>
                                    <Col lg={3}>
                                        <Item label="Bovinos"  name="is_bovino"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                            <Radio.Group
                                        onChange={(v)=>{
                                                if(v.target.value === 1){
                                                    setBovino(true)
                                                }else{
                                                    setBovino(false)
                                                }
                                            }}>
                                                <Radio value={1}>SI</Radio>
                                                <Radio value={0}>NO</Radio>
                                            </Radio.Group>
                                        </Item>
                                    </Col>
                                { is_bovino === true &&
                                    <Col lg={3}>
                                        <Item 
                                        label="Cuántos"
                                        name="cantbovinos"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                        <Input style={{width:'100%'}} />
                                    </Item>
                                    </Col>
                                }
                    
                                    <Col lg={3}>
                                        <Item 
                                        label="Caprinos"
                                        name="is_caprinos"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                        <Radio.Group  onChange={(v)=>{
                                            if(v.target.value === 1){
                                                setCaprino(true)
                                            }else{
                                                setCaprino(false)
                                            }
                                        }}>
                                                <Radio value={1}>SI</Radio>
                                                <Radio value={0}>NO</Radio>
                                            </Radio.Group>
                                    </Item>
                                    </Col>
                                    { is_caprino === true &&
                                    <Col lg={3}>
                                        <Form.Item 
                                        label="Cuántos"
                                        name="cantcaprinos"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                        <Input style={{width:'100%'}} />
                                    </Form.Item>
                                    </Col>
                                    }
                                    <Col lg={3}>
                                <Item label="Porcinos" 
                                    name="is_porcinos"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Radio.Group 
                                    onChange={(v)=>{
                                            if(v.target.value === 1){
                                                setPorcino(true)
                                            }else{
                                                setPorcino(false)
                                            }
                                        }}>

                                        <Radio value={1}>SI</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                </Item>
                            </Col>
                            {is_porcino === true &&
                            <Col lg={3}>
                                <Form.Item 
                                label="Cuántos"
                                name="cantporcinos"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                <Input style={{width:'100%'}} />
                            </Form.Item>
                            </Col>
                                }

                            <Col lg={3}>
                                <Form.Item 
                                label="Equinos"
                                name="is_equinos"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                <Radio.Group onChange={(v)=>{
                                            if(v.target.value === 1){
                                                setEquino(true)
                                            }else{
                                                setEquino(false)
                                            }
                                        }}>
                                        <Radio value={1}>SI</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                            </Form.Item>
                            </Col>
                        { is_equino === true &&
                            <Col lg={3}>
                                <Form.Item 
                                label="Cuántos"
                                name="cantequinos"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                <Input style={{width:'100%'}} />
                            </Form.Item>
                            </Col>
                        }
                            </Row>
                            <Divider/>
                            <Row gutter={[16, 16]}>
                            <Col lg={3}>
                                <Item label="Ovinos" name="is_ovino"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Radio.Group 
                                    onChange={(v)=>{
                                            if(v.target.value === 1){
                                                setOvino(true)
                                            }else{
                                                setOvino(false)
                                            }
                                        }}>
                                        <Radio value={1}>SI</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                </Item>
                            </Col>
                            { is_ovino === true &&
                            <Col lg={3}>
                                <Form.Item 
                                label="Cuántos"
                                name="cantovinos">
                                <Input style={{width:'100%'}} />
                            </Form.Item>
                            </Col>
                            }
                            <Col lg={3}>
                                <Item label="Especies menores" name="is_especiesmenores"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Radio.Group onChange={(v)=>{
                                            if(v.target.value === 1){
                                                setEspecies(true)
                                            }else{
                                                setEspecies(false)
                                            }
                                        }}>
                                        <Radio value={1}>SI</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                </Item>
                            </Col>
                            { is_especies === true &&
                                <Col lg={3}>
                                    <Form.Item 
                                    label="Cuántos"
                                    name="cantespeciesmenores"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Input style={{width:'100%'}} />
                                </Form.Item>
                                </Col>
                            }
                                    <Col lg={3}>
                                <Item label="Animales exóticos" name="is_animalesexoticos"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Radio.Group onChange={(v)=>{
                                            if(v.target.value === 1){
                                                setExotico(true)
                                            }else{
                                                setExotico(false)
                                            }
                                        }}>
                                        <Radio value={1}>SI</Radio>
                                        <Radio value={0}>NO</Radio>
                                    </Radio.Group>
                                </Item>
                            </Col>

                    { is_exotico === true &&
                                <Col lg={3}>
                                    <Form.Item 
                                    label="Cuántos"
                                    name="cantanimalesexoticos"   rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
                                    <Input style={{width:'100%'}} />
                                </Form.Item>
                                </Col>
                    }
                            </Row>
                            </>} 
                    </Panel>                
                </Collapse>     
                <Divider/>

                <Row>
                    {loading && <Loading/>}
                    
                    <Col xs={24} md ={24} lg={4}>
                        <Item>
                            <Button  htmlType="submit" className='btn-primary' icon={<SendOutlined />} > Enviar</Button>
                        </Item>
                    </Col>
                    
                </Row>
                <Divider/>
                </>
        }

        </Form>  
    </>
    )
}
export default  IdentificacionHogar;
