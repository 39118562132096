import React, {useState, useEffect} from 'react'
import moment from 'moment';
import { Button, Checkbox, Form, Input, Select, DatePicker, Radio, Modal, Skeleton, message } from 'antd';
import { municipios } from '../../Api/municipios';
import { useQueries } from '@tanstack/react-query';
import { getInfouserFromCC } from '../../Api/identificadores';
import { documentotipo, sexo, grupopoblacion, etnia, afiliacion, ocupacion, vinculo, cursodevida, genero, orientacion, regimen, eps, saveAtencion, tipovivienda, familiatipo, tenencia,
    complementos, nomenclatura, saveAtencionIntegrante, encuestaOpciones, encuentaQuestions,

} from '../../Api/identificadores';
import { useParams,useNavigate, json,} from 'react-router-dom';
import { v4 } from "uuid";

const optionsVectores = ['Cucarrachas', 'Zancudos', 'Moscas', 'Mosquitos', 'Piojos', 'Garrapatas', 'Ratas', 'Ratones', 'Ninguno'];
const listaServicosPublicos = ['Energía eléctrica', 'Gas natural o res publica', 'Acueducto', 'recolección de basuras'];
const teneciaDeAnimales = ['Novinos', 'Porcinos', 'Equinos', 'Ovinos', 'Escies menores', 'Animales exóticos', 'Otros', 'Ninguno'];
const bateriasSanitarias = ['Inodoro', 'Lavamanos', 'Ducha'];


function CreatePerson(props) {
  
    const params = useParams();
    const navigate = useNavigate();
    const {type, uuid, edit} = params;

    const [form] = Form.useForm();
    const [formTwo] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [rol, setRoles] = useState([]);
    const [showNacionalidad, setShowNacionalidad] = useState(false);
    const [desriptionComplemento, setdesriptionComplemento] = useState(false);
    const [sgsssIs, setsgsssIs] = useState(false);
    const [integrantesGet, setintegrantesGet] = useState([]);
    const [paredesOtro, setParedesOtro] = useState(false);
    const [pisosOtros, setPisosOtros] = useState(false);
    const [techoOtros, seTechoOtros] = useState(false);
    const [convivenciaAnimalesOtro, setConvivenciaAnimalesOtro] = useState(false);
    const [teneciAnimalesOtros, setTeneciAnimalesOtros] = useState(false);
    const [tipoDeafiliacionShow, setTipoDeafiliacionShow] = useState(false);
    const [totalPonit, setTotalPonit] = useState({});

    const [vinculos, setVinculos] = useState([]);


    const result = useQueries({
        queries: [
          { queryKey: ['documentotipo'], queryFn: documentotipo, staleTime: Infinity},
          { queryKey: ['sexo'], queryFn: sexo, staleTime: Infinity},
          { queryKey: ['grupopoblacion'], queryFn: grupopoblacion, staleTime: Infinity},
          { queryKey: ['etnia'], queryFn: etnia, staleTime: Infinity},
          { queryKey: ['afiliacion'], queryFn: afiliacion, staleTime: Infinity},
          { queryKey: ['ocupacions'], queryFn: ocupacion, staleTime: Infinity},
          { queryKey: ['vinculos'], queryFn: vinculo, staleTime: Infinity},
          { queryKey: ['cursodevida'], queryFn: cursodevida, staleTime: Infinity },
          { queryKey: ['genero'], queryFn: genero, staleTime: Infinity },
          { queryKey: ['orientacion'], queryFn: orientacion, staleTime: Infinity},
          { queryKey: ['regimen'], queryFn: regimen, staleTime: Infinity },
          { queryKey: ['eps'], queryFn: eps, staleTime: Infinity},
          { queryKey: ['tipovivienda'], queryFn: tipovivienda, staleTime: Infinity},
          { queryKey: ['familiatipo'], queryFn: familiatipo, staleTime: Infinity},
          { queryKey: ['tenencia'], queryFn: tenencia, staleTime: Infinity},
          { queryKey: ['complementos'], queryFn: complementos, staleTime: Infinity},
          { queryKey: ['nomenclatura'], queryFn: nomenclatura, staleTime: Infinity},
          
        ]
    })

   
    const userInfo = async (e) => {

        try {
            if(e.target.value.length >= 4){
                setLoading(true)
                const cc = e.target.value;
                const userdata = await getInfouserFromCC(cc);
         
                if(userdata.length){
                    const {
                        vinculo_jefe_id,
                        documento, 
                        ocupacion_id,
                        telefono,
                        orientacion_id, 
                        nacionalidad,
                        is_sgsss,
                        sexo_id,
                        genero_id ,edad, 
                        fecha_nacimiento,
                        nombre_completo,
                        regimen_id,
                        grupo_poblacional_id, 
                        curso_vida_id,
                        grupo_etnico_id,
                        afiliacion_tipo_id,
                        eps_id,
                        tipo_documento_id
                    } = userdata[0];
                  

                    form.setFieldsValue(
                        {
                            nombre: nombre_completo,
                            docNum: documento,
                            edad: edad,
                            sexo: sexo_id,
                            phone: telefono,
                            nacionalidad: nacionalidad,
                            fechaNacimiento: moment(fecha_nacimiento),
                            ocupacion : ocupacion_id,
                            regimen_id: regimen_id,
                            grupoPoblacional: grupo_poblacional_id,
                            pertenciaEtnica: grupo_etnico_id,
                            cursodevida: curso_vida_id,
                            genero: genero_id,
                            orientacion: orientacion_id,
                            is_sgsss: is_sgsss,
                            afiliacion: afiliacion_tipo_id,
                            eps_id: eps_id,
                            tipo_documento_id: tipo_documento_id,
                            vinculo: vinculo_jefe_id
                        }
                    )
                    if(is_sgsss === 1){
                        setsgsssIs(true)
                    }else{
                        setsgsssIs(false)  
                    }
                    
                }else{
                    /*
                    form.setFieldsValue({
                        nombre: null,
                        docNum: null,
                        edad: null,
                        sexo: null,
                        phone: null,
                        nacionalidad: null,
                        fechaNacimiento: null,
                        ocupacion : null,
                        regimen_id: null,
                        grupoPoblacional: null,
                        pertenciaEtnica: null,
                        cursodevida: null,
                        genero: null,
                        orientacion: null,
                        is_sgsss: null,
                        afiliacion: null,
                        eps_id: null,
                        tipo_documento_id: null
                    })
                    */
                }
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onFins = (e) => {
        saveAtencionIntegrante(uuid, JSON.stringify(e))
        .then(res => {
            message.success('Hogar Agregado');
            navigate(`/panel/identificadorRiesgos`)  
        })
        .catch( erro => {
            message.error('Error al crear integrante');
        })
       
    }

    const createVivienda = async (e) => {
        try {
            let uid = v4();
            let newObj = e;
            newObj.uiid = uid;
            newObj.integrantes = [];
           const resAtencion = await saveAtencion(newObj);
     
            message.success('Hogar Agregado');
            navigate(`/panel/identificadorRiesgos`)  
        } catch (error) {
            message.error('No se puedo crear el hogar');
        }
    }

    useEffect( () => {
        if(edit != 'create'){
            if(edit){
                let objUSer = JSON.parse(edit);
              
                delete objUSer.fecha;
                const { is_sgsss, pisos, techo, paredes, ConvivenciaAnimales, TeneciAnimales } = objUSer
                //delete objUSer.fechaNacimiento;
               
                if(type === 'vivienda'){
                 formTwo.setFieldsValue({
                     ...objUSer,
                 })
                 
                }else{
                 form.setFieldsValue({
                     ...objUSer,
                     fechaNacimiento: moment(objUSer.fechaNacimiento)
                    })
                }
                if(is_sgsss === 1){setsgsssIs(true)}
                if(paredes === "otros"){ setParedesOtro(true) }
                if(pisos === "otros"){ setPisosOtros(true) }
                if(techo === "otros"){ seTechoOtros(true) }
                if(ConvivenciaAnimales === "Otro"){ setConvivenciaAnimalesOtro(true) }
                let otroTenecai = TeneciAnimales?.find( i => i === 'Otros' );
                if(otroTenecai){
                    setTeneciAnimalesOtros(true)
                }
             }
        }
        form.setFieldsValue({ regimen_id: 2 })
        
    }, [] )


    if( result.some(res => res.isLoading) ) return <Skeleton active />
        result[6].data?.data.vinculo.sort(function(a, b) {
        if (  b.vinculo_jefe_hogar_id > a.vinculo_jefe_hogar_id) {
            return 1;
          }
          if ( b.vinculo_jefe_hogar_id < a.vinculo_jefe_hogar_id) {
            return -1;
          }
          return 0;
      })

    return (
    <div>
      
        <Modal open={loading} footer={null}>
            <h2>CARGANDO...</h2>
        </Modal>
        { type === 'vivienda' ? <>
            <h2 className='text-xl font-bold mr-3 uppercase mb-6'>Condiciones de la vivienda.</h2>
            <Form
                  layout='vertical'
                  onFinish={createVivienda}
                  disabled={ edit != 'create'? true: false }
                  form={formTwo}
                 
            >
            <div  className={ edit != 'create'? 'noEditForm': 'formNormal' }>

 
                <div className='grid md:grid-cols-5 sm:grid-cols-2 gap-3'>

                    <Form.Item
                        label="Tipo de vivienda"
                        name="tipoVivienda"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            }
                        ]}
                    >
                        <Select 
                            placeholder="Tipo de vivienda"
                            options={result[12].data?.data.tipo.map(i => ( {
                                    value: i.vivienda_tipo_id,
                                    label: i.vivienda_tipo_descripcion,
                                } ) ) 
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        label="Tenecia de la vivienda"
                        name="tenencia"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            }
                        ]}
                    >
                        <Select 
                            placeholder="Tenecia de la vivienda"
                            options={result[14].data?.data.tenencia.map(i => ( {
                                    value: i.tenencia_id,
                                    label: i.tenencia_descripcion,
                                } ) ) 
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        label="Tipo de familia"
                        name="tipoFamilia"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            }
                        ]}
                    >
                        <Select 
                            placeholder="Tipo de familia"
                            options={result[13].data?.data.familiatipo.map(i => ( {
                                    value: i.familia_tipo_id,
                                    label: i.familia_tipo_descripcion,
                                } ) ) 
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        label="Barrio"
                        name="barrio"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            }
                        ]}
                    >
                        <Input placeholder='Barrio' />
                    </Form.Item>

                    <Form.Item
                        label="Dirrecion"
                        name="dirrecion"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            }
                        ]}
                    >
                       <Select 
                            placeholder="Complemento"
                            options={result[16].data?.data.nomenclatura.map(i => ( {
                                    value: i.tipo_direccion_id,
                                    label: i.direccion_tipo,
                                } ) ) 
                            }
                        />
                    </Form.Item>
                   

                </div>
                <div className='grid md:grid-cols-9 sm:grid-cols-3 gap-2'>
                    <Form.Item
                        label="numero"
                        name="numeroDir"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            }
                        ]}
                    >
                          <Input placeholder='Numero' type='number'/>
                    </Form.Item>

                    <Form.Item
                        label="Letra"
                        name="letraDir"
                    >
                          <Input placeholder='Letra'/>
                    </Form.Item>

                    <Form.Item
                        label="Bis"
                        name="bisDir"
                    >
                        <Input placeholder='Bis'/>
                    </Form.Item>

                    <Form.Item
                        label="Letra"
                        name="netraDosDir"
                    >
                        <Input placeholder='Letra'/>
                    </Form.Item>

                    <Form.Item
                        label="Numero"
                        name="numeroDosDir"
                    >
                        <Input placeholder='Numero' type='number'/>
                    </Form.Item>

                    <Form.Item
                        label="Letra"
                        name="netraTresDir"
                    >
                        <Input placeholder='Letra'/>
                    </Form.Item>
                    <Form.Item
                        label="Bis"
                        name="bisDirDos"
                    >
                        <Input placeholder='Bis'/>
                    </Form.Item>

                    <Form.Item
                        label="Letra"
                        name="netraCuatroDir"
                    >
                        <Input placeholder='Letra'/>
                    </Form.Item>

                    <Form.Item
                        label="Complemento"
                        name="complementoDir"
                    >
                       <Select 
                            placeholder="Complemento"
                            options={
                                result[15].data?.data.complemento.map(i => ( {
                                    value: i.complemento_id,
                                    label: i.complemento_descripcion,
                                } ) ) 
                            }
                            onChange={ () => setdesriptionComplemento(true) }
                        />
                    </Form.Item>
                  
                </div>
                {desriptionComplemento &&  <Form.Item
                        label="Descripción"
                        name="descripcionDir"
                    >
                       <Input placeholder='Descripción'/>
                    </Form.Item> 
                }
                <div>
                    <h2 className=' text-lg font-bold mr-3 uppercase mb-6'>Material de vivienda</h2>
                    <div className='grid md:grid-cols-5 sm:grid-cols-2 gap-3'>
                        <Form.Item 
                            label="Paredes"
                            name="paredes"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo requerido',
                                },
                            ]}
                            onChange={ (e) =>  {
                                if( e.target.value === 'otros' ){
                                    setParedesOtro(true)
                                }else{
                                    setParedesOtro(false)
                                }
                               
                            } }
                        >
                            
                            <Radio.Group>
                                <Radio value='concreto'>Concreto</Radio>
                                <Radio value='otros'>Otro</Radio>
                            </Radio.Group>
                        </Form.Item>

                        { paredesOtro  && 
                            <Form.Item 
                                label="OTROS PAREDES"
                                name="otrosParedes"
                               
                            >
                            
                            <Input  placeholder='OTROS PAREDES' />
                        </Form.Item>
                        }
                        <Form.Item 
                            label="Pisos"
                            name="pisos"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo requerido',
                                },
                            ]}
                            onChange={ (e) =>  {
                                if( e.target.value === 'otros' ){
                                    setPisosOtros(true)
                                }else{
                                    setPisosOtros(false)
                                }
                            } }
                        >
                            
                            <Radio.Group>
                                <Radio value='material'>Material</Radio>
                                <Radio value='otros'>Otro</Radio>
                            </Radio.Group>
                        </Form.Item>

                        { pisosOtros  && 
                            <Form.Item 
                                label="Otros Pisos"
                                name="otrosPisos"
                               
                            >
                            
                            <Input  placeholder='Otros Pisos' />
                        </Form.Item>
                        }

                        <Form.Item 
                            label="Techos"
                            name="techo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo requerido',
                                },
                            ]}
                            onChange={ (e) =>  {
                                if( e.target.value === 'otros' ){
                                    seTechoOtros(true)
                                }else{
                                    seTechoOtros(false)
                                }
                            } }
                        >
                            
                            <Radio.Group>
                                <Radio value='zinc'>Zinc</Radio>
                                <Radio value='eternit'>Eternit</Radio>
                                <Radio value='otros'>Otro</Radio>
                            </Radio.Group>
                        </Form.Item>

                        { techoOtros  && 
                            <Form.Item 
                                label="Techo Otros"
                                name="techoOtros"
                               
                            >
                            
                            <Input  placeholder='Techo Otros' />
                        </Form.Item>
                        }

                    </div>
                </div>

                <div className='py-3 grid md:grid-cols-3 sm:grid-cols-1 gap-3'>
                  
                    <div className='bg-gray col-span-2'>
                    <h2 className=' text-lg font-bold mr-3 uppercase mb-6'>Tipo y conexion de servicio sanitario</h2>
                        <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-3'>
                            <Form.Item 
                                label="El servicio sanitaio utilizado es inodoro conectado al alcantarillado?"
                                name="inodoro"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido',
                                    },
                                ]}
                            >
                                
                                <Radio.Group>
                                    <Radio value='si'>Si</Radio>
                                    <Radio value='no'>No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item 
                                label="Tipo de alcantillado"
                                name="tipoAlcantarillado"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido',
                                    },
                                ]}
                            >
                                
                                <Radio.Group>
                                    <Radio value='pozo séptica'>Pozo séptica</Radio>
                                    <Radio value='campo abierto'>Campo abierto</Radio>
                                    <Radio value='letrina'>Letrina</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>

                    <div className=' bg-gray p-3 '>
                    <h2 className=' text-lg font-bold mr-3 uppercase mb-6'>Vectores</h2>
                        <Form.Item 
                            name="vectores"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo requerido',
                                },
                            ]}
                        >
                            <Checkbox.Group options={optionsVectores}  />
                        </Form.Item>
                    </div>
                    
                    <div className='bg-gray p-3'>
                    <h2 className=' text-lg font-bold mr-3 uppercase mb-6'>De que servicios Disponen ?</h2>
                        <Form.Item 
                            name="servicios"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo requerido',
                                },
                            ]}
                        >
                            <Checkbox.Group options={listaServicosPublicos}  />
                        </Form.Item>
                    </div>

                    <div className=' bg-gray p-3 col-span-2'>
                        <h2 className=' text-lg font-bold mr-3 uppercase mb-6'>Condiciones Higienicas de la vivienda</h2>
                        <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-3'>
                            <Form.Item 
                                label="Cuantas Baterias sanitarias"
                                name="bateriasSanitarias"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido',
                                    },
                                ]}
                            >
                                <Checkbox.Group options={bateriasSanitarias}  />
                               
                            </Form.Item>

                            <Form.Item 
                                label="Combustible de cocina"
                                name="combustibelCocina"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido',
                                    },
                                ]}
                            >
                                
                                <Radio.Group>
                                     <Radio value='GAS (NATURAL)'>GAS (NATURAL)</Radio>
                                    <Radio value='campo abierto'>GAS (CILINDRICO)</Radio>
                                    <Radio value='LEÑA'>LEÑA</Radio>
                                    <Radio value='LUZ'>LUZ</Radio>
                                    <Radio value='NINGUNO'>NINGUNO</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item 
                                label="Cocina independiente"
                                name="CocinaIndependiente"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido',
                                    },
                                ]}
                            >
                                
                                <Radio.Group>
                                    <Radio value='si'>Si</Radio>
                                    <Radio value='no'>No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item 
                                label="fuma en la vivienda"
                                name="fumaEnLaVivienda"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido',
                                    },
                                ]}
                            >
                                
                                <Radio.Group>
                                    <Radio value='si'>Si</Radio>
                                    <Radio value='no'>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>

                </div>

                <div className='py-3 grid md:grid-cols-3 sm:grid-cols-1 gap-3'>
                    <div className='bg-gray p-3  col-span-2'>
                        <h2 className=' text-lg font-bold mr-3 uppercase mb-6'>Uso del espacio de la vivienda</h2>
                        <div className='py-3 grid md:grid-cols-4 sm:grid-cols-1 gap-3'>
                    
                            <Form.Item 
                                label='Utilizacion exclusiva de dormitorios'
                                name="utilizacionExclusivaDormitorios"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value='si'>Si</Radio>
                                    <Radio value='no'>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <div>
                                <h3 className='mb-3'>Otro Usos del dormitorio</h3>
                                <Form.Item 
                            
                                    name="DormitorioUso"
                                >
                                <Input  placeholder='Dormitorio'/>
                                </Form.Item>
                                <Form.Item 
                    
                                    name="viviendaUso"
                                
                                >
                                <Input  placeholder='Vivienda'/>
                                </Form.Item>
                    
                            </div>
                        
                            <Form.Item 
                                label='Convivencia con animales'
                                name="ConvivenciaAnimales"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido',
                                    },
                                ]}

                                onChange={ (e) =>  {
                                    if( e.target.value === 'Otro' ){
                                        setConvivenciaAnimalesOtro(true)
                                    }else{
                                        setConvivenciaAnimalesOtro(false)
                                    }
                                } }
                            >
                                <Radio.Group>
                                    <Radio value='perros'>Perros</Radio>
                                    <Radio value='gatos'>Gatos</Radio>
                                    <Radio value='Ninguno'>Ninguno</Radio>
                                    <Radio value='Otro'>Otro</Radio>
                                </Radio.Group>
                            </Form.Item>

                            { convivenciaAnimalesOtro  && 
                                <Form.Item 
                                    label="Convivencia con animales"
                                    name="convivenciaAnimalesOtro"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Campo requerido',
                                        },
                                    ]}
                                >
                                <Input  placeholder='Convivencia con animales' />
                            </Form.Item>
                            }


                            <Form.Item 
                                label='Animales no vacunados contra rabia'
                                name="AnimalesNovacunadosContrRabia"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value='perros'>Perros</Radio>
                                    <Radio value='gatos'>Gatos</Radio>
                                </Radio.Group>
                            </Form.Item>

                        </div>     
                    </div>

                    <div className='bg-gray p-3 '>
                        <h2 className=' text-lg font-bold mr-3 uppercase mb-6'>Tenecia de animales</h2>
                        <div className='py-3 grid md:grid-cols-1 sm:grid-cols-1 gap-3'>
                    
                            <Form.Item 
                                name="TeneciAnimales"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo requerido',
                                    },
                                ]}
                                onChange={ (e) =>  {
                                    if( e.target.value === 'Otros' ){
                                        setTeneciAnimalesOtros(true)
                                    }else{
                                        setTeneciAnimalesOtros(false)
                                    }
                                } }
                            >
                             
                                <Checkbox.Group options={teneciaDeAnimales}  />
                            </Form.Item>
                            { teneciAnimalesOtros  && 
                                <Form.Item 
                                    label="TeneciAnimales Otros"
                                    name="TeneciAnimalesOtros"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Campo requerido',
                                        },
                                    ]}
                                >
                                <Input  placeholder='TeneciAnimales Otros' />
                            </Form.Item>
                            }
                        </div>     
                    </div>
                </div>

                {  edit === 'create' && 
                    <Form.Item className='mt-5 w-full items-center flex justify-center'>
                        <Button  htmlType="submit" className='bg-orange px-4 w-[150px] mt-5'>
                            Agregar
                        </Button>
                    </Form.Item>
                 }
                 </div>   
            </Form>
        </> 
        :
        <>
            <Form 
                onFinish={onFins} 
                layout='vertical'
                form={form}
                disabled={ edit === 'create' ? false : true }
               
            >
            <div className={ edit != 'create'? 'noEditForm': 'formNormal' }>
                <h2 className='text-xl font-bold mr-3 uppercase mb-6'> { edit === 'create' ? 'Crear un integrante de hogar' : ' Integrante del hogar ' }</h2>
                <div className='grid md:grid-cols-5 sm:grid-cols-2 gap-3'>
                    <Form.Item
                            label="Rol en el hogar"
                            name="vinculo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo requerido',
                                }
                            ]}
                    >
                        <Select 
                            placeholder="Rol en el hogar"
                            options={result[6].data?.data.vinculo.map(i => ( {
                                    value: i.vinculo_jefe_hogar_id,
                                    label: i.vinculo_descripcion,
                                } ) ) 
                            }
                        />
                    </Form.Item>

                    <Form.Item 
                        label="Numero de documento"
                        name="docNum"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                        <Input type="number" placeholder='Numero de identificación' onBlur={userInfo} />
                    </Form.Item>

                    <Form.Item
                        label="Tipo de documento"
                        name="tipo_documento_id"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                    <Select 
                        placeholder="selecciona un tipo de documento"
                        options={ result[0].data?.documento_tipo.map(i => ( {
                                value: i.value,
                                label: i.descripcion,
                        } ) ) }
                        onChange={ (e) => {
                            if(e === 3 || e === 10 || e === 11 || e === 12 ){
                                setShowNacionalidad(true);
                            }else{
                                setShowNacionalidad(false);
                            }
                        } }
                    />
                    </Form.Item>

                    <Form.Item 
                        label="Nombre y apellido"
                        name="nombre"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                        <Input type="text" placeholder='Nombre y apellidos' />
                    </Form.Item>

                    { showNacionalidad &&
                        <Form.Item 
                            label="Nacionalidad"
                            name="nacionalidad"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo requerido',
                                },
                            ]}
                        >
                            <Input type="text" placeholder='Nacionalidad' />
                        </Form.Item>
                    }
                    <Form.Item
                        label="Fecha de nacimiento"
                        name="fechaNacimiento"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                        <DatePicker placeholder='fecha de nacimiento' className='w-full'  format="YYYY-MM-DD" 
                        onChange={ (e) => {
                            let year = new Date(e).getFullYear();
                            const moonLanding = new Date();
                            let year_actual = moonLanding.getFullYear();
                            let edad = year_actual - year;
                            if(edad <= 5 ){
                                form.setFieldsValue({cursodevida: 1})
                            }else if(edad >= 6 && edad <= 11 ){
                                form.setFieldsValue({cursodevida: 2})
                            }else if(edad >= 12 && edad <= 17 ){
                                form.setFieldsValue({cursodevida: 3})
                            }else if(edad >= 18 && edad <= 28 ){
                                form.setFieldsValue({cursodevida: 4})
                            }else if(edad >= 29 && edad <= 59 ){
                                form.setFieldsValue({cursodevida: 5})
                            }else if(edad >= 60 ){
                                form.setFieldsValue({cursodevida: 6})
                            }
                            form.setFieldsValue({edad: edad})

                        } }/>
                    </Form.Item>

                    <Form.Item 
                        label="Edad"
                        name="edad"
                    >
                        <Input type="number" placeholder='edad' disabled />
                    </Form.Item>

                    <Form.Item 
                        label="Curso de vida"
                        name="cursodevida"
                        
                    >
                        <Select 
                            disabled
                            placeholder="curso de vida"
                            options={ result[7].data?.data.cursovida.map(i => ( {
                                value: i.curso_vida_id,
                                label: i.descripcion_curso,
                            } ) ) }
                        />
                    </Form.Item>

                    <Form.Item 
                        label="Sexo"
                        name="sexo"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                    
                        <Select 
                            placeholder="Sexo"
                            options={ result[1].data?.map(i => ( {
                                value: i.sexo_id,
                                label: i.sexo_descripcion,
                            } ) ) }
                        />
                        
                    </Form.Item>

                    <Form.Item 
                        label="Genero"
                        name="genero"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                    
                        <Select 
                            placeholder="Genero"
                            options={ result[8].data?.data.genero.map(i => ( {
                                value: i.genero_id,
                                label: i.genero,
                            } ) ) }
                        />
                        
                    </Form.Item>

                    <Form.Item 
                        label="Orientacion"
                        name="orientacion"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                    
                        <Select 
                            placeholder="orientacion"
                            options={ result[9].data?.data.orientacion.map(i => ( {
                                value: i.orientacion_id,
                                label: i.orientacion,
                            } ) ) }
                        />
                        
                    </Form.Item>

                    <Form.Item
                        label="Grupo Poblacional"
                        name="grupoPoblacional"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                        <Select 
                            placeholder="Grupo Poblacional"
                            options={ result[2].data?.grupo.map(i => ( {
                                value: i.grupo_poblacional_id,
                                label: i.descripcion_grupo,
                            } ) ) }
                        />
                    </Form.Item>

                    <Form.Item 
                        label="Grupo etnico"
                        name="pertenciaEtnica"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                        
                        <Select 
                            placeholder="Pertencia Etnica"
                            options={ result[3].data?.etnia.map(i => ( {
                                value: i.grupo_etnico_id,
                                label: i.grupo_etnico_descripcion,
                            } ) ) }
                        />
                    </Form.Item>

                    <Form.Item 
                        label="Ocupación"
                        name="ocupacion"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                        <Select 
                            placeholder="Ocupación"
                            options={ result[5].data?.ocupacion.map(i => ( {
                                value: i.ocupaicon_id,
                                label: i.ocupacion_descripcion,
                            } ) ) }
                        />
                    </Form.Item>

                    <Form.Item 
                        label="Vinculacion al SGSSS"
                        name="is_sgsss"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            }
                        ]}
                        onChange={ (e) => {

                            if(e.target.value === '1'){
                                setsgsssIs(true)
                            }else{
                                setsgsssIs(false)
                            }
                        }}
                    >
                        
                        <Radio.Group>
                            <Radio value={1}>SI</Radio>
                            <Radio value={0}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    { sgsssIs === true && <>
                        <Form.Item 
                        label="Regimen SGSS"
                        name="regimen_id"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                      
                    >
                    <Select 
                            placeholder="Regimen SGSS"
                            options={ result[10].data?.data.regimen.map(i => ( {
                                value: i.regimen_id,
                                label: i.regimen,
                            } ) ) }
                   
                            onChange={ (e) => {
                                if(e === 2){
                                    setTipoDeafiliacionShow(false) 
                                }else{
                                    
                                    setTipoDeafiliacionShow(true)
                                }
                            } }
                        />
                    </Form.Item>

                    <Form.Item 
                        label="EAPB"
                        name="eps_id"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                        <Select 
                            placeholder="EAPB"
                            options={ result[11].data?.data.eps.map(i => ( {
                                value: i.eps_id,
                                label: i.nombre_eps,
                            } ) ) }
                        />
                    </Form.Item>
                    { tipoDeafiliacionShow &&  
                     <Form.Item 
                        label="tipo de afiliación"
                        name="afiliacion"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                    <Select 
                            placeholder="tipo de afiliación"
                            options={ result[4].data?.afiliacion.map(i => ( {
                                value: i.afiliacion_tipo_id,
                                label: i.afiliacion_tipo,
                            } ) ) }
                        />
                    </Form.Item>
                    }
                    
                    </> }
                        
                </div>
                <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-4 mt-3'>
                    <div className='p-4 shadow-lg'>
                        <h2 className='text-lg font-bold mr-3 uppercase mb-6'>DERIVACION Y ATENCION POR PROFESIONAL O EQUIPO COMPLEMENTARIO.</h2>
                        <div className='grid grid-cols-2'>
                            
                            <Form.Item name="Psicologia"  valuePropName="checked" >
                                <Checkbox>Psicología</Checkbox>
                            </Form.Item>
                            <Form.Item name="medico" valuePropName="checked">
                                <Checkbox>Medico</Checkbox>
                            </Form.Item>
                            <Form.Item name="fisioterapia" valuePropName="checked">
                                <Checkbox>fisioterapia</Checkbox>
                            </Form.Item>
                            <Form.Item name="enfermeria" valuePropName="checked">
                                <Checkbox>Enfermeria</Checkbox>
                            </Form.Item>
                            <Form.Item name="IngAmbiental" valuePropName="checked">
                                <Checkbox>Ing ambiental</Checkbox>
                            </Form.Item>
                            <Form.Item name="AuxEnfermeria" valuePropName="checked">
                                <Checkbox>Aux. Enfermeria</Checkbox>
                            </Form.Item>
                            <Form.Item name="VACUNACION_COVID_19_SIN_ESQUEMA" valuePropName="checked">
                                <Checkbox>VACUNACION COVID 19 SIN ESQUEMA</Checkbox>
                            </Form.Item>
                            <Form.Item name="VACUNACION__19_ESQUEMA_INCOMPLETO" valuePropName="checked">
                                <Checkbox>VACUNACION COVID 19 ESQUEMA INCOMPLETO</Checkbox>
                            </Form.Item>
                           
                        </div>
                        <hr />
                        <Form.Item 
                            name="'ACTIVACION_DE_RUTA"
                            label='ACTIVACION DE RUTA'
                        >
                            <Checkbox.Group options={['SALUD', 'INTERSECTORIAL']}/>
                            
                        </Form.Item>
                    </div>
                    
                    <div className='p-4 shadow-lg'>
                        <h2 className='text-lg font-bold mr-3 uppercase mb-6'>EDUCACION EN SALUD.</h2>
                        <div className=''>

                            <Form.Item 
                                name="PomocionMantenimientoSalud"
                                label='Pomocion y mantenimiento de la salud'
                            >
                                <Radio.Group>
                                    <Radio value='SI'>SI</Radio>
                                    <Radio value='NO'>NO</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item 
                                name="estiloVidaSaludable"
                                label='Estilos de vida saludable'
                            >
                                <Radio.Group>
                                    <Radio value='SI'>SI</Radio>
                                    <Radio value='NO'>NO</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item 
                                name="EducacionVacunacionCovid19"
                                label='Educacion sobre vacunacion covid19'
                            >
                                <Radio.Group>
                                    <Radio value='SI'>SI</Radio>
                                    <Radio value='NO'>NO</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item 
                                name="nedidasBioseguridadCovid19"
                                label='Medidas bioseguridad en la pandemia covid 19'
                            >
                                <Radio.Group>
                                    <Radio value='SI'>SI</Radio>
                                    <Radio value='NO'>NO</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className='mt-7'>
                    <Form.Item 
                        label="Resumen de la atencion del riesgo"
                        name="resumen"
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                            
                        <Input.TextArea placeholder='Resumen de la atencion del riesgo'></Input.TextArea>
                    </Form.Item>
                </div> 
                <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-4 mt-3 bg-gray py-3'>
                    { encuentaQuestions.map( (i, key) => {
                        return (
                            <Form.Item
                                key={key.toString()}
                                label={i}
                                name={i.replaceAll(' ', '_')}
                            >
                                <Select 
                                    placeholder="Selecciona una opcion"
                                    options={encuestaOpciones}
                                    onChange={ (e) => {
                                        let obj =  totalPonit;
                                        let newObj = {
                                            ...obj,
                                            [i.replaceAll(' ', '_')] : e
                                        }
                                        setTotalPonit(newObj)
                                       let result = Object.values(newObj).reduce( (n1, n2) =>  n1 + n2  );
                                       form.setFieldsValue({totalPuntos: result})
                                    } }
                                />
                            </Form.Item>
                        )
                    }) }
                   <div className='flex justify-center items-center flex-col'>
                        <Form.Item
                          name="totalPuntos"
                        >
                            <Input disabled className='text-center text-3xl text-white bg-orange h-[50px] w-[50px] rounded-full flex justify-center items-center resultPoint'/>
                        </Form.Item>
                        <p className=' font-bold text-lg'>Puntos</p>
                    
                   </div>
                </div>
                { edit === 'create' && <Form.Item className='mt-5 w-full items-center flex justify-center'>
                    <Button  htmlType="submit" className='bg-orange px-4 w-[150px] mt-5'>
                        Agregar
                    </Button>
                </Form.Item> 
                }
            </div>   
            </Form>	
        </>
        }
        
    </div>
  )
}

export default CreatePerson;