import React, { useState } from 'react'
import './css/Login.css';
import { Col, Row,Input,Form,Button } from 'antd';
import {MailOutlined, SendOutlined } from '@ant-design/icons'; 
import { getUserData, LoginPost,setUserData } from '../helpers/Api';
import Loading from '../helpers/Loading';
import { Link, useNavigate } from 'react-router-dom';
import openNotificationWithIcon from '../helpers/Notification';
import { connect } from 'react-redux';
import {userAction} from '../redux/actions/user.action'

const {Item} = Form;

const Login = ({userAction, setUser}) => {
const [loading,setLoading]= useState(false);
const navigate = useNavigate();

    const onFinish = (values) => {
        setLoading(true)
        LoginPost('login',values)
         .then(res=>{
           setTimeout(() => {
                setLoading(false)
                let response = res['data'];
                if(res['success']){
                    navigate('panel', { replace: true });
                    userAction(response);
                    setUser(response);
                    setUserData(response);
                }else{
                    openNotificationWithIcon('error','Error de inicio de sesion','Usuario o contraseña incorrecto')
                }
            }, 500);

        })
        .catch(err=>{
            setLoading(false)
        })

      };

  return (
    <div className='content-login'>
         <Form layout='vertical' onFinish={onFinish}>
           {loading === true && <Loading/>}
            <Row>
                <img src='img/banner.png' width={280} alt="Logo Entidad"/>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={24}>
                    <Item label="Email" hasFeedback  name="email" 
                       rules={[{ required: true, type:'email', message: 'Por favor ingrese su correo electrónico!'}]}>
                       <Input placeholder="Ingrese su email"  allowClear />
                    </Item>
                </Col>
            </Row>
            <Row>
              <Col xs={24} lg={24}>
                    <Item label="Contraseña" hasFeedback  name="password"
                     rules={[{ required: true, type:'password', message: 'Por favor ingrese su correo electrónico!'}]}>
                        <Input.Password  placeholder="Ingrese su contraseña" allowClear />
                    </Item>
               </Col>
            </Row>
            <Row>
              <Col xs={24} lg={24}>
                    <Item>
                      <Button  htmlType="submit" className='btn-primary' icon={<SendOutlined />}> Continuar</Button>
                    </Item>
               </Col>
            </Row>
            <Row>
              <Col xs={24} lg={24}>
                    <Item>
                    <Link to="/notificar">
                      <MailOutlined/> Recuperar contraseña
                    </Link>
                    </Item>
               </Col>
            </Row>
        </Form>
    </div>
  )
}

const mapStateToProps = state => state;
export default connect(mapStateToProps, {userAction})(Login)