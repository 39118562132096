import React, { useEffect, useState } from 'react';
import { Select } from 'antd';


import {Col,Row } from 'antd';
import { Checkbox,Form,Input,Button} from 'antd';
import { getRequestAll,getRequestById,postRequest } from '../helpers/Api';
import {SendOutlined,ArrowLeftOutlined } from '@ant-design/icons'; 
import Loading from '../helpers/Loading';
import {Link, useNavigate, useParams } from 'react-router-dom';
import HeaderTitle from '../components/HeaderTitle'
import openNotificationWithIcon from '../helpers/Notification';


export const RegistroUsuario = ({isUpdate}) => {
    const {Item} = Form;
    const { Option } = Select;
    const [optionsPerfil,setPerfil] = useState([])
    const [optionsCargo,setCargo] = useState([])
    const [optionsEquipo,setEquipo] = useState([])
    const [optionsTerritorio,setTerritorio] = useState([])
    const [optionsCiudad,setCiudad] = useState([])
    const [optionsCentro,setCentro] = useState([])
    const [optionFuncion,setFuncion] = useState([])
    const [optionDocumento,setDocumento] = useState([])
    const [optionsSubregiones,setSubregiones] = useState([])
    const [dataCentros,setDataCentros] = useState([])
    const [ruralDisperso,setRuralDiperso] = useState([])
    const [redPrestadora,setRed] = useState({})
    const [intialValue] = useState()
    const [infoUsuario,setInfoUsuario] = useState({})

    const [showCentro,setShowCentro] = useState(false)
    const [showFuncion,setshowFuncion] = useState(false)
    const [showPerfilProf,setShowPerfilProf] = useState(false)
    const [showRural,setshowRural] = useState(false)
    const [loading,setLoading] = useState(false)
    const [componentDisabled, setComponentDisabled] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const [form] = Form.useForm();


    const onFinish  = (value) => {
        if(isUpdate){
          const {id} = params;
          value.usuario = id;
           setLoading(true)
          postRequest("users/user_up/",value).then(res=>{
            if(res['success'])
              setTimeout(() => {
              setLoading(false);
              openNotificationWithIcon("success","Actualización exitosa",'El usuario se actualizó correctamente')
              }, 500);
        else  setLoading(false);
          }).catch(err=> setLoading(false))
       }
        if(!isUpdate){
        setLoading(true)
          value.centropoblado = JSON.stringify(value.centropoblado);
          postRequest("users/",value).then(res=>{
            if(res['success'])
              setTimeout(() => {
              setLoading(false);
              openNotificationWithIcon("success","Exito al registrar el usuario!!",'El usuario se registró correctamente')
              form.resetFields();
              }, 500);
            else {
                setLoading(false);
                openNotificationWithIcon("error","Ocurrió un error",res['data']['data']['message'])
            }
          }).catch(err=> {
            setLoading(false)
            openNotificationWithIcon("error","Ocurrió un error",'El usuario no se pudo registrar')
        })

    
       }
      };
    
      
        const padLeadingZeros =(num)=> {
            var s = "000" + num;
            return s.substr(s.length-4);
        }
        const getredPrestadora =(id)=>{
            getRequestById(id,'generics/red').then(res=>{
                const {red} = res['data'];
                if(res['success']) setRed(red);
            });
        }
        const getCentrosByUserId =()=>{
        const {id} = params;
            getRequestById(id,'generics/centrosbyuser').then(res=>{
                const {centros} = res['data'];
                if(res['success']){
                let datacentro =[];
                let ruralDisperso =[];
                centros.forEach(centro => {
                    datacentro.push(padLeadingZeros(centro.centro_poblado_id))
                    ruralDisperso.push(centro.rural_disperso)
                });
                setDataCentros(datacentro);
                setRuralDiperso(ruralDisperso);
                };
            });
        }
        const getUserById=()=>{
            const {id} = params;
            getRequestById(id,"users/userid").then(res=>{
              if(res['success'])
              setInfoUsuario(res['Users'])
            })
          
            getCentrosByUserId();
        }

        useEffect(()=>{

            if(isUpdate){
                getUserById();
                setComponentDisabled(true)
            }
            getRequestAll('generics/perfil').then(res=>{
                const {perfil} = res['data'];
                if(res['success']) setPerfil(perfil);
            });
            getRequestAll('generics/cargo').then(res=>{
                const {cargo} = res['data'];
                if(res['success']) setCargo(cargo);
            });
            getRequestAll('generics/equipo').then(res=>{
                const {equipo} = res['data'];
                if(res['success']) setEquipo(equipo);
            });
            getRequestAll('generics/territorio').then(res=>{
                const {territorio} = res['data'];
                if(res['success']) setTerritorio(territorio);
            });

            getRequestAll('generics/funcion').then(res=>{
                const {funcion} = res['data'];
                if(res['success']) setFuncion(funcion);
            });
        
            getRequestAll('generics/documentotipo').then(res=>{
                const {documento_tipo} = res['data'];
                if(res['success']) setDocumento(documento_tipo);
            });
            getRequestAll('generics/subregiones').then(res=>{
                const {subregion} = res['data'];
                 if(res['success']) setSubregiones(subregion);
            });
    
        },[])
        useEffect(()=>{

            form.setFieldsValue(
              {
                tipdocumento:1,
                numerodocumento:infoUsuario.identificacion,
                nombre:infoUsuario.nombre_completo,
                celular:infoUsuario.celular,
                perfilprof:infoUsuario.profesion_id,
                email:infoUsuario.email,
                cargo:infoUsuario.cargo_id,
                funcion:infoUsuario.funciones_id,
                tipoequipo:infoUsuario.tipo_equipo_id,
                municipio:infoUsuario.ciudad_id,
                subregion:infoUsuario.subregiones_id,
                hospital:redPrestadora.nombre_red,
                hospitalejecutor:redPrestadora.hospital_ejecutor,
                claseterritorio:infoUsuario.territorio_id,
                ruraldisperso:"",
              }
            )
            if(isUpdate && infoUsuario.ciudad_id !==undefined){
              getredPrestadora(infoUsuario.ciudad_id)
              if(infoUsuario.territorio_id === 2){
                setShowCentro(true)
                setshowRural(false)
               }
                else if(infoUsuario.territorio_id ===1){
                setShowCentro(false)
                setshowRural(false)}
                else if(infoUsuario.territorio_id ===3){
                    setshowRural(true)
                    setShowCentro(false)
                }
        
              if(infoUsuario.funciones_id)
              setshowFuncion(true)
              getRequestAll(`generics/ciudad?id=47&subregion_id=${infoUsuario.subregiones_id}`).then(res=>{
                const {ciudades} = res['data'];
                if(res['success']) setCiudad(ciudades);
            });
            };
          
          }, [form, infoUsuario])
         
          useEffect(()=>{
        
            form.setFieldsValue(
              {
             
                hospital:redPrestadora.nombre_red,
                hospitalejecutor:redPrestadora.hospital_ejecutor,
                centropoblado:dataCentros,
                ruraldisperso:ruralDisperso,
              }
            )
          },[redPrestadora])
          
          const onFormLayoutChange = ({ disabled }) => {
            setComponentDisabled(disabled);
          };

  return (
       <>
      
        <HeaderTitle title="Registro de usuario"/>
        { isUpdate && <Checkbox
        checked={componentDisabled}
        onChange={(e) => setComponentDisabled(e.target.checked)}
      >
        Habilitar formulario
      </Checkbox>
  }

      <Form 
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues = {intialValue}
        disabled={componentDisabled} 
        onValuesChange={onFormLayoutChange}
        wrapperCol={{ span: 20 }}>
   {loading && <Loading/>}
  
   <br/>
        <Row>
          
            <Col xs={24} md ={24} lg={8}>

                <Item 
                key={1}
                    label="Tipo de documento" 
                    name="tipdocumento" 
                   
                    rules={[{ required: true, message: 'Por favor seleccione un tipo de documento' }]}>
                     <Select 
                            placeholder="Seleccione..."
                            disabled>
                              {optionDocumento.length > 0 && optionDocumento.map((op,i)=>(<Option   key={`p${i}`} value={op.value}>{op.descripcion}</Option> ))}
                     </Select>
                </Item>

                <Item label="Numero de documento"
                 name="numerodocumento"
                    rules={[{ required: true, message: 'Por favor ingrese su número de documento!' }]}>
                    <Input style={{ width: '100%' }}  maxLength={10} />
                </Item>

                <Item label="Nombre y apellidos"
                    name="nombre"  
                    rules={[{ required: true, message: 'Por favor ingrese su nombre!' }]}>
  
                    <Input  allowClear />
                </Item>

                <Item
                    name="celular"
                    label="Celular"
                    rules={[{ required: true, message: 'Por favor ingrese su número de celular!' }]}>
                    <Input style={{ width: '100%' }}/>
                </Item>
              {  showPerfilProf === true &&
                <Item 
                   label="Perfil profesional"
                   name="perfilprof"
  
                   rules={[{ required: true, message: 'Por favor ingrese un perfil profesional!' }]}>
                       <Select
                            // defaultValue = {isUpdate && infoUsuario.profesion_id}
                            placeholder="Seleccione..."
                            allowClear>
                              {optionsPerfil.length > 0 && optionsPerfil.map((op,i)=>(<Option   key={`p${i}`} value={op.value}>{op.descripcion}</Option> ))}
                         </Select>
                </Item>
               }
                <Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Por favor ingrese un email valido!', type: 'email' }]}>
                       <Input allowClear />
                </Item>


            </Col>   

            <Col xs={24} md ={24} lg={8}>
          
                    <Item
                     label="Cargo"
                     name="cargo"
                     rules={[{ required: true, message: 'Por favor ingrese un cargo!'}]}>
                     <Select
      
                            placeholder="Seleccione..."
                            onChange={(value)=>{
                                if(value === 2 || value === 4 )
                                setshowFuncion(true);
                                else setshowFuncion(false);
                                if(value === 6)
                                setShowPerfilProf(true)
                                else
                                setShowPerfilProf(false)
                           }}
                            allowClear>
                              {optionsCargo.length > 0 && optionsCargo.map((op,i)=>(<Option key={`c${i}`} value={op.value}>{op.descripcion}</Option> ))}
                         </Select>

                    </Item>

                  { showFuncion &&
                   <Item
                     label="Funcion a desempeñar"
                     name="funcion"
                     rules={[{ required: true, message: 'Por favor seleccione una funcion!'}]}>
                         <Select
                            placeholder="Seleccione..."
                            allowClear>
                              {optionFuncion.length > 0 && optionFuncion.map((op,i)=>(<Option key={`p${i}`} value={op.value}>{op.descripcion}</Option> ))}
                         </Select>
                    </Item>
                  }
                    <Item 
                       label="Tipo de equipo"
                       name="tipoequipo"

                       rules={[{ required: true, message: 'Por favor ingrese un tipo de equipo!'}]}>
                          <Select
                            placeholder="Seleccione...">
                              {optionsEquipo.length > 0 && optionsEquipo.map((op,i)=>(<Option key={`p${i}`} value={op.value}>{op.descripcion}</Option> ))}
                         </Select>
                    </Item>

                    <Item 
                     label="Subregión"
                     name="subregion"
                     rules={[{ required: true, message: 'Por favor ingrese un tipo de equipo!'}]}>
                        <Select
                            placeholder="Seleccione..."
                            onChange={(value)=>{
                                getRequestAll(`generics/ciudad?id=47&subregion_id=${value}`).then(res=>{
                                    const {ciudades} = res['data'];
                                    if(res['success']) setCiudad(ciudades);
                                });
                            
                            }}>
                              {optionsSubregiones.length > 0 && optionsSubregiones.map((op,i)=>(<Option key={`ci${i}`} value={op.subregiones_id}>{op.subregion}</Option> ))}
                         </Select>
                    </Item>

                    <Item 
                     label="Municipio"
                     name="municipio"
                     rules={[{ required: true, message: 'Por favor ingrese un tipo de equipo!'}]}>
                        <Select
                            placeholder="Seleccione..."
                            onChange={(value)=>{
                                getredPrestadora(value);
                                getRequestById(value,'generics/centropoblado').then(res=>{
                                    const {centro_poblado} = res['data'];
                                    if(res['success']) setCentro(centro_poblado);
                                });
                            }}>
                              {optionsCiudad.length > 0 && optionsCiudad.map((op,i)=>(<Option key={`ci${i}`} value={op.value}>{op.descripcion}</Option> ))}
                         </Select>
                    </Item>

                    <Item 
                     label="Hospital"
                     name="hospital"
                     >
                     <Input disabled value={redPrestadora.nombre_red}  />
                    </Item>
                  

            </Col>   
            <Col xs={24} md ={24} lg={8}>

                   {/* <Item label="Foto de perfil" valuePropName="fileList">
                       <Upload action="/upload.do" listType="picture-card">
                            <div>
                            <PlusOutlined />
                            <div
                                style={{
                                marginTop: 8,
                                }}
                            >
                                Subir foto
                            </div>
                            </div>
                       </Upload>
                    </Item> */}
                    <Item 
                    label="Hospital ejecutor"
                     name="hospitalejecutor"
                     >
                      <Input disabled value={redPrestadora.hospital_ejecutor}  />
                    </Item>
                   <Item 
                      label="Clase de territorio"
                      name="claseterritorio"
                      rules={[{ required: true, message: 'Por favor seleccione un territorio!'}]}>
                          <Select
                            // defaultValue ={1}
                            placeholder="Seleccione..."
                            onChange={(value)=>{
                                if(value === 2){
                                setShowCentro(true)
                                setshowRural(false)
                               }
                                else if(value ===1){
                                setShowCentro(false)
                                setshowRural(false)}
                                else if(value ===3){
                                    setshowRural(true)
                                    setShowCentro(false)
                                }
                            }}
                            allowClear>
                              {optionsTerritorio.length > 0 && optionsTerritorio.map((op,i)=>(<Option key={`p${i}`} value={op.value}>{op.descripcion}</Option> ))}
                         </Select>
                    </Item>
                    { showCentro && <Item
                      label="Centro poblado"
                      name="centropoblado"
                      rules={[{ required: true, message: 'Por favor ingrese minimo un centro poblado!'}]}>
                        <Select
                            mode="multiple"
                            style={{
                            width: '100%',
                            }}
                            placeholder="seleccione centros poblados"
                            onChange={()=>{}}
                            // optionLabelProp="label"
                            allowClear>
                            {optionsCentro.length > 0 && optionsCentro.map((op,i)=>(<Option key={i} value={padLeadingZeros(op.value)}><div className="demo-option-label-item">{op.descripcion}</div></Option>))}
                        </Select>
                </Item>}

                
                {  showRural &&  <Item 
                       label="Rural Disperso"
                       name="ruraldisperso"
                       >
                     <Input  allowClear/>
                    </Item>
                
                } 
               { !isUpdate &&
                <Item label="Contraseña"
                  name="password"
                  rules={[{ required: true, message: 'Por favor ingrese la contraseña!'}]}>
                  <Input.Password placeholder="Contraseña" allowClear />
                </Item>
               }    

            </Col>
        </Row>
        <Row>
          <Col xs={24} md ={24} lg={4}>
            <Item>
            <Button  htmlType="submit" className='btn-primary' icon={<SendOutlined />}> Enviar</Button>
            </Item>
          </Col>
      </Row>
      </Form>
     
       </>
      )
}

export default RegistroUsuario