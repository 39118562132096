import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, Radio } from 'antd';
import React, { useState } from 'react';
import HeaderTitle from '../components/HeaderTitle';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../helpers/Loading';
import { Post } from '../helpers/Api';
import openNotificationWithIcon from '../helpers/Notification';
const RecuperarPass = () => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('vertical');
  const [loading,setLoading]= useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    setLoading(true)
    Post('users/changepass',values)
     .then(res=>{
       setTimeout(() => {
            setLoading(false)
            let response = res['data'];
            if(res['success']){
                openNotificationWithIcon('success','Exito su contraseña fue restablecida ')
                setTimeout(()=>{
                  navigate('/', { replace: true });
                },2000)
            }else{
                openNotificationWithIcon('error','Error de inicio de sesion','Usuario o contraseña incorrecto')
            }
        }, 500);

    })
    .catch(err=>{
        setLoading(false)
    })

  };
  return (
    <div className='flex justify-center items-center h-screen content-recupera'>
      <div className='div-recuperar md:w-[500px] sm:w-[350px] shadow-lg p-6 rounded-lg bg-white'>
        <div className="mt-3 text-center font-semibold text-xl mb-3 bg-white">
          <h2 className='text-orange'>Recuperar contraseña</h2>
        </div>
          <Form
            layout={formLayout}
            onFinish ={onFinish}
          >
        {loading === true && <Loading/>}
            <Form.Item label="Identificacíon" name="identificacion" 
              rules={[{ required: true, type:'text', message: 'Por favor ingrese su identificación!'}]}>
              <Input placeholder="Numero de documento" required/>
            </Form.Item>
            <Form.Item label="Email" name="email"
              rules={[{ required: true, type:'email', message: 'Por favor ingrese su correo electrónico!'}]}>
              <Input placeholder="Correo electronico" required/>
            </Form.Item>
            <Form.Item label="Contraseña" hasFeedback name="password"   
            rules={[{ required: true, type:'password', message: 'Por favor ingrese su Contraseña!'}]}>
              <Input.Password required placeholder="Contraseña" />
            </Form.Item>
            <Form.Item >
            <Button  htmlType="submit" className='btn-primary' icon={<SendOutlined />}> Enviar</Button>
            </Form.Item>
          </Form>
        </div>
    </div>
  );
};
export default RecuperarPass