import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useParams,useNavigate, Link} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getIntegranteATencion } from '../../Api/identificadores';
import { Skeleton, Table, Space,Button } from 'antd';
import { UsergroupAddOutlined, EyeOutlined} from '@ant-design/icons';
import { atenCionSelectAction } from '../../redux/actions/userSelecAtention';


const ListaIntegrantes = (props) => {
    const params = useParams();
    const {uuid} = params;
    const navigate = useNavigate();

    const [listAtenciones, setListAtenciones] = useState([]);
    const { data, status } = useQuery({ queryKey: [`itegrantes`, uuid],  queryFn: getIntegranteATencion, 
        onSuccess: (info) => {
            let listIbj = []
            info.forEach( (i) => {
                let infoUser = {
                    key: i.idatencion_salud_integrantes,
                    fecha: i.fecha_crea,
                    document:  i.docNum,
                }
                let infoU;
                if(i.JSON_Object_Integrante.length >= 10){
                    infoU = JSON.parse( JSON.parse(i.JSON_Object_Integrante) );
                    listIbj.push({
                        ...infoUser,
                        document:  infoU.docNum,
                        nombre: infoU.nombre,
                        edad: infoU.edad,
                        action: <Space>
                            <Link to={`/panel/identificadorRiesgos/user/integrante/${i.atencion_id}/${ JSON.stringify(infoU) }`} >
                                <Button icon={<EyeOutlined />}/>
                            </Link>
                        
                        </Space>
                    });
                }
                
          })
          setListAtenciones(listIbj)
    } })

    const columns = [
        {
          title: 'Acciones',
          key: 'action',
          render: (_, record) => record.action
        },
        {
          title: 'nombre',
          dataIndex: 'nombre',
          key: 'nombre',
        },
        {
            title: 'edad',
            dataIndex: 'edad',
            key: 'edad',
          },
        {
          title: 'Fecha',
          dataIndex: 'fecha',
          key: 'fecha',
        },
    ];
    

    if(status === "loading") return <Skeleton active />
    if(status === "error")  return <p>Ups erro...</p>

  return (
    <div>
    <Table columns={columns} dataSource={listAtenciones} />
    </div>
  )
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = { atenCionSelectAction }

export default connect(mapStateToProps, mapDispatchToProps)(ListaIntegrantes)