import axios from "axios";
import { settings } from "./setting";

export const getEntorno =  async ({queryKey}) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/entornohogar?id=${queryKey[1]}`, {
        headers: {
            'Authorization': userInfo.token
        }
    })
    return res.data
}

export const geTipoVivienda =  async ({queryKey}) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/EntornoHogarviviendatipo?id=${queryKey[1]}`, {
        headers: {
            'Authorization': userInfo.token
        }
    })
    return res.data
}

export const getTotalViviendas =  async ({queryKey}) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/totalViviendas?id=${queryKey[1]}`, {
        headers: {
            'Authorization': userInfo.token
        }
    })
    return res.data
}

export const getRiesgosPorBarrio = async () => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const res = await axios.get(`${settings.url}/generics/riesgoshogarbybarrio`, {
        headers: {
            'Authorization': userInfo.token
        }
    })
    return res.data.data
}


export const getLocationCity = async (arrayBarrios, city) => {
    let listPromise = arrayBarrios.map( (i) => new Promise( (resolve, reject) => {
        axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${i.barrio} ${city} magdalena colombia.json?access_token=${settings.mpbox}`)
        .then( data => {
            let objResolve = {
                name: i,
                data: data.data
            }
            resolve(objResolve)
        } )
        .catch( error => reject(error) )
    } ) )

    return listPromise;

    //const rest = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${city} magdalena.json?access_token=${settings.mpbox}`);
}