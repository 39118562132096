import React ,{ useEffect, useState }from 'react'
import { ExportOutlined ,HomeOutlined , ArrowLeftOutlined } from '@ant-design/icons';
import { LaptopOutlined, NotificationOutlined, UserOutlined,AlertOutlined , MenuOutlined } from '@ant-design/icons';

import { Layout, Menu, Drawer, Button } from 'antd';
import { useNavigate,Outlet, Link } from 'react-router-dom';
import { getRequestAll } from '../helpers/Api';
import { goToBack } from '../Api/history';
import { connect } from 'react-redux';
import { historyAction } from '../redux/actions/history.action';
const { Header, Sider } = Layout;

const Principal = (props) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [menu,setMenu]= useState([])
  const [Opciones,setOpciones]= useState([])

  useEffect(()=>{
    goToBack();
    getRequestAll('users/menu')
    .then(res=>{
        let objetMenu=[];
        if(res['success'])
            res['Menu'].forEach(option => {
                let item =  objetMenu.find(e=> e.opciones_id === option.opciones_id)
                if(!item){
                    option.subopcion = [{suboption:option.subopcion,url:option.url}];
                    objetMenu.push(option)
                }
            else{
            item.subopcion.push({suboption:option.subopcion,url:option.url})
            }
        });
        let array =[];
        if(objetMenu.length === 3)
        array.push(HomeOutlined , LaptopOutlined, NotificationOutlined)
        if(objetMenu.length === 4)
        array.push(HomeOutlined , LaptopOutlined, NotificationOutlined,AlertOutlined)
        if(objetMenu.length === 5)
        array.push(HomeOutlined , LaptopOutlined, NotificationOutlined,AlertOutlined,AlertOutlined)
        if(objetMenu.length === 6)
        array.push(HomeOutlined , LaptopOutlined, NotificationOutlined,AlertOutlined,AlertOutlined,AlertOutlined)
        
        const newArray = objetMenu.filter(i => {
          if( i.opcion.toLowerCase() != "administración de usuarios" &&  i.opcion.toLowerCase() !="red prestadora" &&  i.opcion.toLowerCase() !="caracterización" ) return i
        } )
        
        setMenu(objetMenu);
        setOpciones(array);

         
    })
  },[]);

  return (
      <div className='w-full '>
        
        <Drawer
          placement="left"
          width={340}
          open={collapsed}
          closable={false}
          bodyStyle={{ padding: 0 }}
          onClose={() => setCollapsed(!collapsed)}
        >
        <div className='content-logo'>
              <div className="logo"></div>
          </div>
            <Menu
              theme="light"
              mode="inline"
            > 
            {
              menu.map((i , key)=> {
                return(
                    <>
                    {i.subopcion.length >= 2 ?  
                    <Menu.SubMenu title={i.opcion}>
                      { 
                        i.subopcion.map( (e, keys) =>  <Menu.Item key={(keys*5).toString()}>
                          <Link to={e.url} onClick={ ()=> setCollapsed(!collapsed)}> {e.suboption}</Link>
                        </Menu.Item> ) 
                      }
                    </Menu.SubMenu> 
                    : 
                    <Menu.Item key={key.toString()}>
                      <Link to={i.url} onClick={ ()=> setCollapsed(!collapsed)}>{i.opcion}</Link>
                    </Menu.Item>
                    }
                    </>
                )
              })
            }
          </Menu>
        </Drawer>
        
        <Header className="site-layout-background w-full pr-2 px-5" >
            <div className='sm:flex md:hidden justify-center items-center' onClick={ () => setCollapsed(!collapsed) }>
              <span className='mr-1 font-normal'>
                Menu
              </span>
              <MenuOutlined  className="text-[19px] font-bold"/> 
            </div>
            <div className='content-logo sm:hidden md:block'>
              <div className="logo"></div>
            </div>
            <div className='flex items-center justify-between'>
                <h3 className='mr-2 text-white'>{ props.user.username }</h3>
                <ExportOutlined onClick={ () => {
                  localStorage.removeItem('user');
                  sessionStorage.removeItem('history');
                  navigate('/', { replace: true })
                  }}
                  className="text-[19px]"
                />
               
              
            </div>
            
        </Header>
        <div className='w-full md:flex sm:block'>
          <div className='basis-1/6 sm:hidden md:block'>
            <Sider trigger={null} theme="light" width="100%"> 
              <Menu
                theme="light"
                mode="inline"
              > 
              { menu.length >= 1 ?
                menu.map((i , key)=> {
                  return(
                      <>
                      {i.subopcion.length >= 2 ?  
                      <Menu.SubMenu title={i.opcion}>
                        { 
                          i.subopcion.map( (e, keys) =>  <Menu.Item key={e.suboption.replaceAll(' ', '-')}>
                            <Link to={e.url}> {e.suboption}</Link>
                         </Menu.Item> ) 
                        }
                      </Menu.SubMenu> 
                      : 
                      <Menu.Item key={key.toString()}>
                        <Link to={i.url}>{i.opcion}</Link>
                      </Menu.Item>
                      }
                      </>
                  )
                })
                :
                <Menu.Item>
                  <Link to="/panel/usuario/registro">Registro</Link>
                </Menu.Item>
              }
            </Menu>
             
            </Sider>
          </div>
          <div className='md:basis-5/6 sm:basis-full'>
            
            <div className='w-100 md:p-6 sm:p-3'>
              <button 
                className='mb-6 w-[130px] bg-orange text-white text-xl p-3 flex justify-center items-center'
                onClick={ () => {
                const histo = sessionStorage.getItem('history');
                if(histo){
                  let array = JSON.parse(histo);
                  if(array.length >= 2){
                    navigate(array[array.length -1]);
                    array.pop();
                    sessionStorage.setItem('history', JSON.stringify(array));
                  }
                }
              } }> <ArrowLeftOutlined /> Atras</button>
              <Outlet/>
            </div>
          </div>
        </div>
      </div>
  )
}

const mapStateToProps = state => state

export default connect(mapStateToProps, { historyAction })(Principal);