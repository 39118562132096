import React, { useEffect, useState } from 'react'
import { getSubregionData, getDepartamentData } from '../../Api/seguimiento'
import { useQuery } from '@tanstack/react-query';
import { Skeleton } from 'antd';
import { connect } from 'react-redux';

const ItemTraking = ({id, name, api}) => {
  const { data, status } = useQuery({ 
    queryKey: [`${name-id}`, id], 
    queryFn: api
  })

  if(status === "loading") return <Skeleton active />
  if(status === "error")  return <p>Ups...</p>

  return(
    <div className='rounded-md shadow-lg p-5  bg-white cursor-pointer hover:scale-105 ease-out duration-200'>
      <p className='font-bold text-2xl p-2 rounded text-orange'>
        {new Intl.NumberFormat('es-MX').format( data.data.visitas[0].total) }
      </p>
      <p className='font-bold'>{data.data.visitas[0].ciudad ? data.data.visitas[0].ciudad : name}</p>
    </div>
  )

}

function CardsTrakingInfo({action, cargo, user}) {
  const [listShow, setLIstShow] = useState([
    {
      name: "RIO: PIVIJAY",
      id: 1
    },
    {
      name: "CENTRO: TENERIFE",
      id: 2
    },
    {
      name: "NORTE 1: ARACATACA",
      id: 3
    },
    {
      name: "NORTE 2: FUNDACIÓN",
      id: 4
    },
    {
      name: "SUR: BANCO",
      id: 5
    }
  ]);

  useEffect( () => {
    const storage = JSON.parse(localStorage.getItem("user"));

    if(cargo === 2 || cargo === 4 ) {
      const {funciones_id, subregion_id } = storage ;
      if(funciones_id != null && funciones_id == 1 ){
        let filtArr = listShow.filter( i => i.id === subregion_id );
        setLIstShow(filtArr);
      }else if(funciones_id != null && funciones_id == 2){
        let filtArr = listShow.filter( i => i.id === subregion_id );
        setLIstShow(filtArr);
      } 
    }
  }, [cargo] );


  return (
    <>
     {user.funciones_id  === 2 ? 
     <>
     <h2 className='text-2xl font-bold mr-3 uppercase'>Total visitas por municipio</h2>
     <div className='grid md:grid-cols-5 sm:grid-cols-2 gap-4'>
     {  
        listShow.map( (i, key) => <div onClick={ () => action(user.ciudad_id) } key={key.toString()}>
            <ItemTraking id={user.ciudad_id} name={i.name} api={getDepartamentData}  />
          </div>
        )
      }
     </div>
     </>
     :
     <>
      <h2 className='text-2xl font-bold mr-3 uppercase'>Total visitas por subregion</h2>
      <div className='grid md:grid-cols-5 sm:grid-cols-2 gap-4'>
      {  
        listShow.map( (i, key) => <div onClick={ () => action(i.id) } key={key.toString()}>
            <ItemTraking id={i.id} name={i.name} api={getSubregionData}  />
          </div>
        )
      }  
      </div>
     </>
     }
    </>
  )
}

const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(CardsTrakingInfo)